import { StatusCodes } from 'http-status-codes';
import { getClaimReasons } from '~/utils/claim-line-items';
import {
  createActionResultHook,
  crewAction,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { ActionResult, ClaimReasonCategoryCode } from '~/utils/types';

export const INPUT_NAME = 'reasonId';

export default crewAction(
  withDraftClaim(({ formData, context, claim }) => {
    const { order } = context;
    const reasonId = Number.parseInt(String(formData.get(INPUT_NAME)), 10);
    if (Number.isNaN(reasonId)) {
      return json<ActionResult<typeof INPUT_NAME>>({
        ok: false,
        form: { [INPUT_NAME]: 'This is required; please choose a reason.' },
      });
    }

    const reason = getClaimReasons(claim).find(({ id }) => id === reasonId);

    if (!reason) {
      return json<ActionResult<typeof INPUT_NAME>>({
        ok: false,
        form: { [INPUT_NAME]: 'Invalid reason.' },
      });
    }

    context.setDraftClaim({
      ...claim,
      reason: {
        id: reason.id,
        name: reason.name,
        category: reason.category.code,
      },
    });

    const hasShippingProtection =
      !!order?.wasShippingProtected &&
      reason.category.code === ClaimReasonCategoryCode.shippingIssue;

    return hasShippingProtection ?
        redirect('../shipping-protection', StatusCodes.SEE_OTHER)
      : redirect(`${reasonId}`, StatusCodes.SEE_OTHER);
  }),
);

export const useReasonActionResult =
  createActionResultHook<ActionResult<typeof INPUT_NAME>>();
