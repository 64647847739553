import { z } from 'zod';
import {
  CrewClaimResolutionMethodEnum,
  CrewIncentiveTypeEnum,
} from '../../enums/crew.js';
import { mapped } from '../../typing.js';
import { enumToZodLiteralUnion, helperSchema } from '../../zodExtensions.js';
import { claimTag } from './claim.js';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Pegasus {
  export type Rule = z.infer<typeof ruleSchema>;
  export type Event = z.infer<typeof eventSchema>;

  export enum Operator {
    equal = 'equal',
    notEqual = 'notEqual',
    lessThan = 'lessThan',
    greaterThan = 'greaterThan',
    in = 'in',
    notIn = 'notIn',
  }

  // ? possibly `concept/integration/data`
  export enum Fact {
    // order lookup
    orderDaysSinceCreated = 'order/daysSinceCreated',

    statusCustomer = 'status/customer',

    // ? the channel the registration was made on (eg, Costco, Amazon, etc)
    registrationChannel = 'registration/channel',
    registrationEstimatedPurchaseDate = 'registration/estimatedPurchaseDate',

    orderDiscountCode = 'order/discountCode',
    orderCreated = 'order/created',
    orderChannel = 'order/channel',
    orderGateway = 'order/gateway',
    orderCountry = 'order/country',
    orderTotal = 'order/total',
    orderTags = 'order/tags',
    orderProtectedByCorso = 'order/protectedByCorso',
    orderNumber = 'order/number',
    customerTags = 'customer/tags',
    customerEmail = 'customer/email',

    exchangeOrderTotal = 'exchangeOrder/total',

    // after reason selection
    productTags = 'product/tags',
    productTypes = 'product/types',
    productCollections = 'product/collections',
    reasonId = 'reason/id',
    reasonCategoryCode = 'reason/categoryCode',
    reasonDetailId = 'reason/detailId',

    claimType = 'claim/type',
    claimLineItemRequestedResolutionMethod = 'claimLineItem/requestedResolutionMethod',
    claimLineItemTotal = 'claimLineItem/total',
    claimLineItemCustomFieldResponse = 'claimLineItem/customFieldResponse',

    // claim created
    rmaStatus = 'rma/status',
    rmaAllItemsReceived = 'rma/allItemsReceived',
    returnShipmentStatus = 'returnShipment/status',
  }

  /** Added as a point of reference. */
  export type UnusedFact = Exclude<Fact, (typeof factsForHook)[Hook][number]>;

  export enum ValueType {
    numberArray = 'numberArray', // TODO this is currently used to represent a primary key, but we should have an explicit value type for that, so that we enforce inputs with a value of the PK, and label with the display value
    stringArray = 'stringArray',
    number = 'number',
    boolean = 'boolean',
    date = 'date',
  }

  export const valueTypeSchema = {
    [ValueType.stringArray]: z.array(z.string()),
    [ValueType.numberArray]: z.array(z.number()),
    [ValueType.number]: z.number(),
    [ValueType.boolean]: z.boolean(),
    [ValueType.date]: z.string().datetime(),
  } satisfies Record<ValueType, z.ZodSchema>;

  export type ValueTypeData = {
    [K in ValueType]: z.infer<(typeof valueTypeSchema)[K]>;
  };

  export const valueOperators = {
    [ValueType.numberArray]: [Operator.in, Operator.notIn],
    [ValueType.stringArray]: [Operator.in, Operator.notIn],
    [ValueType.number]: [Operator.lessThan, Operator.greaterThan],
    [ValueType.boolean]: [Operator.equal, Operator.notEqual],
    [ValueType.date]: [Operator.lessThan, Operator.greaterThan],
  } as const satisfies Record<
    ValueType,
    // * due to constraints with Zod unions for an expression, each fact type MUST have at least two operators
    readonly [Operator, Operator, ...Operator[]]
  >;

  export type ArrayValueType = keyof {
    [K in ValueType as ValueTypeData[K] extends unknown[] ? K : never]: K;
  };

  const arrayValueTypes = Object.values({
    [ValueType.stringArray]: ValueType.stringArray,
    [ValueType.numberArray]: ValueType.numberArray,
  } satisfies {
    [K in ValueType as ValueTypeData[K] extends unknown[] ? K : never]: K;
  });

  export function isArrayValueType(
    valueType: ValueType,
  ): valueType is ArrayValueType {
    const compare: ValueType[] = arrayValueTypes; // widen type for comparison; otherwise, TypeScript already expects they're different
    return compare.includes(valueType);
  }

  export const factValueType = {
    [Fact.orderCreated]: ValueType.date,
    [Fact.orderCountry]: ValueType.stringArray,
    [Fact.orderTotal]: ValueType.number,
    [Fact.orderTags]: ValueType.stringArray,
    [Fact.customerTags]: ValueType.stringArray,
    [Fact.rmaStatus]: ValueType.stringArray,
    [Fact.rmaAllItemsReceived]: ValueType.boolean,
    [Fact.returnShipmentStatus]: ValueType.stringArray,
    [Fact.productTags]: ValueType.stringArray,
    [Fact.productTypes]: ValueType.stringArray,
    [Fact.reasonId]: ValueType.numberArray,
    [Fact.reasonCategoryCode]: ValueType.stringArray,
    [Fact.reasonDetailId]: ValueType.numberArray,
    [Fact.claimType]: ValueType.stringArray,
    [Fact.orderNumber]: ValueType.stringArray,
    [Fact.customerEmail]: ValueType.stringArray,
    [Fact.orderChannel]: ValueType.stringArray,
    [Fact.orderProtectedByCorso]: ValueType.boolean,
    [Fact.claimLineItemRequestedResolutionMethod]: ValueType.stringArray,
    [Fact.productCollections]: ValueType.stringArray,
    [Fact.exchangeOrderTotal]: ValueType.number,
    [Fact.claimLineItemTotal]: ValueType.number,
    [Fact.claimLineItemCustomFieldResponse]: ValueType.stringArray,
    [Fact.orderDaysSinceCreated]: ValueType.number,
    [Fact.statusCustomer]: ValueType.boolean,
    [Fact.orderGateway]: ValueType.stringArray,
    [Fact.registrationChannel]: ValueType.stringArray,
    [Fact.registrationEstimatedPurchaseDate]: ValueType.date,
    [Fact.orderDiscountCode]: ValueType.stringArray,
  } satisfies Record<Fact, ValueType>;

  /** Defines the expression for a fact with the operators, and value of it's associated value type. */
  const expression = <T extends Fact>(fact: T) => {
    const valueType = factValueType[fact];
    return z.object({
      fact: z.literal(`${fact}`),
      operator: z.union(
        mapped(valueOperators[valueType], (operator) =>
          z.literal(`${operator}`),
        ),
      ),
      value: valueTypeSchema[valueType],
    });
  };

  export type FactValueType<T extends Fact> = (typeof factValueType)[T];

  /** For a given fact, the valid datatype it's associated with. */
  export type FactValueData<T extends Fact> = z.infer<
    (typeof valueTypeSchema)[FactValueType<T>]
  >;

  /**
   * For each fact, a the valid datatype or `null`.
   * Represents all possible fact values, while still providing a way to represent a fact as explicitly `null` when it's not present.
   * Allows defining a subset of `Fact`s defined in the object, while ensuring that subset is exhaustive.
   */
  export type FactData<F extends Fact = Fact> = {
    [K in F]: FactValueData<K> | null;
  };

  /** A subset of `Fact`s that have been defined to have an array value type. */
  // such an interesting pattern to use a mapped type with conditionals, then use `keyof` to extract the keys; could use indexing, but this works too
  export type ArrayFact = keyof {
    [K in Fact as FactValueData<K> extends unknown[] ? K : never]: never;
  };

  /** A utility value to use the array facts that are real. */
  export const arrayFacts = Object.values({
    [Fact.orderCountry]: Fact.orderCountry,
    [Fact.orderTags]: Fact.orderTags,
    [Fact.customerTags]: Fact.customerTags,
    [Fact.productTags]: Fact.productTags,
    [Fact.productTypes]: Fact.productTypes,
    [Fact.reasonId]: Fact.reasonId,
    [Fact.reasonCategoryCode]: Fact.reasonCategoryCode,
    [Fact.reasonDetailId]: Fact.reasonDetailId,
    [Fact.claimType]: Fact.claimType,
    [Fact.rmaStatus]: Fact.rmaStatus,
    [Fact.returnShipmentStatus]: Fact.returnShipmentStatus,
    [Fact.orderNumber]: Fact.orderNumber,
    [Fact.customerEmail]: Fact.customerEmail,
    [Fact.orderChannel]: Fact.orderChannel,
    [Fact.productCollections]: Fact.productCollections,
    [Fact.claimLineItemRequestedResolutionMethod]:
      Fact.claimLineItemRequestedResolutionMethod,
    [Fact.orderGateway]: Fact.orderGateway,
    [Fact.claimLineItemCustomFieldResponse]:
      Fact.claimLineItemCustomFieldResponse,
    [Fact.registrationChannel]: Fact.registrationChannel,
    [Fact.orderDiscountCode]: Fact.orderDiscountCode,
  } satisfies { [K in ArrayFact]: K });

  export function isArrayFact(fact: Fact): fact is ArrayFact {
    const compare: Fact[] = arrayFacts; // widen type for comparison; otherwise, TypeScript already expects they're different
    return compare.includes(fact);
  }

  export enum ArrayFactType {
    /**
     * Represents that the underlying value type is represented by any value of that type.
     * Arbitrary values are expected to be self-representing, so they are their own label at runtime.
     */
    arbitrary = 'arbitrary',
    /**
     * Represents that the underlying value type is represented by a strict list of options.
     * This is often useful for facts that are primary keys, or other values that are strictly defined.
     * Any definite value is expected to have a `string` label associated with it at runtime.
     * These are enforced by a separate schema for the value.
     * @see `arrayFactDataSchema`
     */
    definite = 'definite', // labels enforced via a separate schema for the value; @see
  }

  export const arrayFactType = {
    [Fact.orderCountry]: ArrayFactType.definite,
    [Fact.orderTags]: ArrayFactType.arbitrary,
    [Fact.customerTags]: ArrayFactType.arbitrary,
    [Fact.rmaStatus]: ArrayFactType.arbitrary,
    [Fact.returnShipmentStatus]: ArrayFactType.definite,
    [Fact.productTags]: ArrayFactType.arbitrary,
    [Fact.productTypes]: ArrayFactType.arbitrary,
    [Fact.reasonId]: ArrayFactType.definite,
    [Fact.reasonCategoryCode]: ArrayFactType.definite,
    [Fact.reasonDetailId]: ArrayFactType.definite,
    [Fact.claimType]: ArrayFactType.definite,
    [Fact.orderNumber]: ArrayFactType.arbitrary,
    [Fact.customerEmail]: ArrayFactType.arbitrary,
    [Fact.orderChannel]: ArrayFactType.definite,
    [Fact.claimLineItemRequestedResolutionMethod]: ArrayFactType.definite,
    [Fact.productCollections]: ArrayFactType.arbitrary,
    [Fact.claimLineItemCustomFieldResponse]: ArrayFactType.definite,
    [Fact.orderGateway]: ArrayFactType.definite,
    [Fact.registrationChannel]: ArrayFactType.definite,
    [Fact.orderDiscountCode]: ArrayFactType.arbitrary,
  } as const satisfies Record<ArrayFact, ArrayFactType>;

  type ExtractKeyOfArrayFactType<T extends ArrayFactType> = keyof {
    [K in ArrayFact as (typeof arrayFactType)[K] extends T ? K : never]: never;
  };
  export type ArbitraryFact =
    ExtractKeyOfArrayFactType<ArrayFactType.arbitrary>;
  export type DefiniteFact = ExtractKeyOfArrayFactType<ArrayFactType.definite>;

  export function isArbitraryFact(fact: Fact): fact is ArbitraryFact {
    if (!isArrayFact(fact)) return false;
    return arrayFactType[fact] === ArrayFactType.arbitrary;
  }

  export type ArbitraryValueType = FactValueType<ArbitraryFact>;
  const arbitraryValueTypes = Object.values({
    [ValueType.stringArray]: ValueType.stringArray,
  } satisfies { [K in ArbitraryValueType]: K });
  export function isArbitraryValueType(
    valueType: ValueType,
  ): valueType is ArbitraryValueType {
    const compare: ValueType[] = arbitraryValueTypes; // widen type for comparison; otherwise, TypeScript already expects they're different
    return compare.includes(valueType);
  }

  export function isDefiniteFact(fact: Fact): fact is DefiniteFact {
    if (!isArrayFact(fact)) return false;
    return arrayFactType[fact] === ArrayFactType.definite;
  }

  export type DefiniteValueType = FactValueType<DefiniteFact>;
  const definiteValueTypes = Object.values({
    [ValueType.stringArray]: ValueType.stringArray,
    [ValueType.numberArray]: ValueType.numberArray,
  } satisfies { [K in DefiniteValueType]: K });
  export function isDefiniteValueType(
    valueType: ValueType,
  ): valueType is DefiniteValueType {
    const compare: ValueType[] = definiteValueTypes; // widen type for comparison; otherwise, TypeScript already expects they're different
    return compare.includes(valueType);
  }

  export enum EventType {
    modifyResolutionWindow = 'modifyResolutionWindow',
    autoFinalizeClaim = 'autoFinalizeClaim',
    modifyIncentives = 'modifyIncentives',
    applyFee = 'applyFee',
    askAPolicyEnforcingQuestion = 'askAPolicyEnforcingQuestion',
    offerInstantExchange = 'offerInstantExchange',
    allowClaimTypeSelection = 'allowClaimTypeSelection',
    collectCustomFields = 'collectCustomFields',
    applyClaimTags = 'applyClaimTags',
    customizeClaimFinalizationSettings = 'customizeClaimFinalizationSettings',
    chargeForReturnLabel = 'chargeForReturnLabel',
    chargeForExchangeOrderShipping = 'chargeForExchangeOrderShipping',
    allowCustomerToKeepItem = 'allowCustomerToKeepItem',
    productRegistrationSelection = 'productRegistrationSelection',
    returnShippingConfig = 'returnShippingConfig',
  }

  export const eventsAvailableForTestOnly: readonly EventType[] = [
    EventType.productRegistrationSelection,
    EventType.returnShippingConfig,
  ];

  export enum Hook {
    registrationProductSelection = 'Registration_Product_Selection', // at registration product selection
    orderLookup = 'Order_Lookup', // at order lookup
    claimCreated = 'Claim_Created', // at claim created
    afterReasonSelection = 'After_Reason_Selection', // after reason selection
    beforeClaimSubmission = 'Before_Claim_Submission', // before claim submission
    rmaWebhook = 'RMA_Webhook', // at rma webhook
    returnShipmentWebhook = 'Return_Shipment_Webhook', // at return shipment webhook
    claimFinalized = 'Claim_Finalized', // at claim finalize
    returnShipmentCreated = 'Return_Shipment_Created', // at return shipment purchased
    returnShipmentQuote = 'Return_Shipment_Quote', // at return shipment quote
  }

  // ------ fact expressions ---------------------

  export const factExpression = {
    [Fact.orderCreated]: expression(Fact.orderCreated),
    [Fact.orderCountry]: expression(Fact.orderCountry),
    [Fact.orderTotal]: expression(Fact.orderTotal),
    [Fact.orderTags]: expression(Fact.orderTags),
    [Fact.customerTags]: expression(Fact.customerTags),
    [Fact.rmaStatus]: expression(Fact.rmaStatus),
    [Fact.rmaAllItemsReceived]: expression(Fact.rmaAllItemsReceived),
    [Fact.returnShipmentStatus]: expression(Fact.returnShipmentStatus),
    [Fact.productTags]: expression(Fact.productTags),
    [Fact.productTypes]: expression(Fact.productTypes),
    [Fact.reasonId]: expression(Fact.reasonId),
    [Fact.reasonCategoryCode]: expression(Fact.reasonCategoryCode),
    [Fact.reasonDetailId]: expression(Fact.reasonDetailId),
    [Fact.claimType]: expression(Fact.claimType),
    [Fact.orderNumber]: expression(Fact.orderNumber),
    [Fact.customerEmail]: expression(Fact.customerEmail),
    [Fact.orderChannel]: expression(Fact.orderChannel),
    [Fact.orderProtectedByCorso]: expression(Fact.orderProtectedByCorso),
    [Fact.claimLineItemRequestedResolutionMethod]: expression(
      Fact.claimLineItemRequestedResolutionMethod,
    ),
    [Fact.orderDaysSinceCreated]: expression(Fact.orderDaysSinceCreated),
    [Fact.productCollections]: expression(Fact.productCollections),
    [Fact.exchangeOrderTotal]: expression(Fact.exchangeOrderTotal),
    [Fact.claimLineItemTotal]: expression(Fact.claimLineItemTotal),
    [Fact.statusCustomer]: expression(Fact.statusCustomer),
    [Fact.claimLineItemCustomFieldResponse]: expression(
      Fact.claimLineItemCustomFieldResponse,
    ),
    [Fact.orderGateway]: expression(Fact.orderGateway),
    [Fact.registrationChannel]: expression(Fact.registrationChannel),
    [Fact.registrationEstimatedPurchaseDate]: expression(
      Fact.registrationEstimatedPurchaseDate,
    ),
    [Fact.orderDiscountCode]: expression(Fact.orderDiscountCode),
  } as const satisfies Record<Fact, z.ZodSchema>;

  export type Expression<T extends Fact = Fact> = z.infer<
    (typeof factExpression)[T]
  >;

  // !  WHEN ADDING A FACT, REMEMBER TO PUT IT HERE FOR GOD'S SAKE
  // ! FUCKING KILL ME
  // TODO find a way to enforce that all fact expressions are represented here at compile time; then the related test can be removed
  export const expressionSchema = z.discriminatedUnion('fact', [
    factExpression[Fact.orderCreated],
    factExpression[Fact.orderCountry],
    factExpression[Fact.orderTotal],
    factExpression[Fact.orderTags],
    factExpression[Fact.customerTags],
    factExpression[Fact.rmaStatus],
    factExpression[Fact.rmaAllItemsReceived],
    factExpression[Fact.returnShipmentStatus],
    factExpression[Fact.productTags],
    factExpression[Fact.productTypes],
    factExpression[Fact.reasonId],
    factExpression[Fact.reasonCategoryCode],
    factExpression[Fact.reasonDetailId],
    factExpression[Fact.claimType],
    factExpression[Fact.orderNumber],
    factExpression[Fact.customerEmail],
    factExpression[Fact.orderChannel],
    factExpression[Fact.orderProtectedByCorso],
    factExpression[Fact.claimLineItemRequestedResolutionMethod],
    factExpression[Fact.exchangeOrderTotal],
    factExpression[Fact.claimLineItemTotal],
    factExpression[Fact.productCollections],
    factExpression[Fact.claimLineItemCustomFieldResponse],
    factExpression[Fact.orderDaysSinceCreated],
    factExpression[Fact.statusCustomer],
    factExpression[Fact.orderGateway],
    factExpression[Fact.registrationEstimatedPurchaseDate],
    factExpression[Fact.registrationChannel],
    factExpression[Fact.orderDiscountCode],
  ]);

  const allConditionSchema = z.object({
    all: z.array(expressionSchema),
  });

  const anyConditionSchema = z.object({
    any: z.array(expressionSchema),
  });

  // --------- event schemas ------------

  export type EventCustomizeClaimFinalizationSettings = z.infer<
    typeof eventClaimFinalizationSettings
  >;
  export const eventClaimFinalizationSettings = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.customizeClaimFinalizationSettings}`),
    params: z.object({
      failOnOutOfStockScenarios: z.boolean(),
      failureToFinalizeNotificationEmails: helperSchema.emailList.optional(),
    }),
  });

  // ------------ allow customer to keep item ------------
  export type EventAllowCustomerToKeepItem = z.infer<
    typeof eventAllowCustomerToKeepItem
  >;
  export const eventAllowCustomerToKeepItem = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.allowCustomerToKeepItem}`),
    params: z.object({
      // ? we might need to add a message to display after a yes selection
    }),
  });

  // ------------ policy enforcement question ------------
  const markAsIneligible = z.literal('markAsIneligible');
  export type EventPolicyEnforcementQuestion = z.infer<
    typeof eventPolicyEnforcement
  >;

  export const eventPolicyEnforcement = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.askAPolicyEnforcingQuestion}`),
    params: z.object({
      question: helperSchema.nonEmptyString,
      // ? optional message to display after a yes selection
      onYesMessage: z.string().optional(),
      yes: markAsIneligible,
      no: z.null(), // ! do nothing
    }),
  });

  export type EventAllowClaimTypeSelection = z.infer<
    typeof eventAllowClaimTypeSelection
  >;
  export const eventAllowClaimTypeSelection = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.allowClaimTypeSelection}`),
    params: z.object({
      claimTypeSelectionDetailText: helperSchema.nonEmptyString,
    }),
  });

  export type EventApplyClaimTags = z.infer<typeof eventApplyClaimTags>;
  export const eventApplyClaimTags = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.applyClaimTags}`),
    params: z.object({
      claimTags: z.array(claimTag),
    }),
  });

  // auto finalize claim
  const allowedResolutionMethodOptions = z.array(
    z.union([
      z.literal(`${CrewClaimResolutionMethodEnum.refund}`),
      z.literal(`${CrewClaimResolutionMethodEnum.giftCard}`),
      z.literal(`${CrewClaimResolutionMethodEnum.variantExchange}`),
    ]),
  );
  export type AllowedResolutionMethodOptions = z.infer<
    typeof allowedResolutionMethodOptions
  >;

  export type EventAutoFinalizeClaim = z.infer<typeof eventAutoFinalizeClaim>;
  export const eventAutoFinalizeClaim = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.autoFinalizeClaim}`),
    params: z.object({
      // todo: remove this after fully moving off it in favor of conditions
      allowedResolutionMethods: allowedResolutionMethodOptions.optional(),
    }),
  });

  // modify resolution window
  export type EventModifyResolutionWindow = z.infer<
    typeof eventModifyResolutionWindow
  >;
  export const eventModifyResolutionWindow = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.modifyResolutionWindow}`),
    // might need to XOR dates so that at least one is required; otherwise, this wouldn't do anything if nothing was specified
    params: z.discriminatedUnion('kind', [
      z.object({
        kind: z.literal('returnableUntil'),
        refund: helperSchema.optionalizedDatetime,
        giftCard: helperSchema.optionalizedDatetime,
        exchange: helperSchema.optionalizedDatetime, // ? should this be `variantExchange`
        warrantyReview: helperSchema.optionalizedDatetime,
      }),
      z.object({
        kind: z.literal('eligibilityDays'),
        refund: z.number().nonnegative().nullish(),
        giftCard: z.number().nonnegative().nullish(),
        exchange: z.number().nonnegative().nullish(), // ? should this be `variantExchange`
        warrantyReview: z.number().nonnegative().nullish(),
      }),
    ]),
  });

  // ------------- collect custom fields ---------------------
  export type EventCollectCustomFields = z.infer<
    typeof eventCollectCustomFields
  >;

  export const eventCollectCustomFieldsParams = z.object({
    customFieldIds: z.array(z.number()),
    showDefaultCommentField: z.boolean(),
    isDefaultCommentFieldRequired: z.boolean().optional(),
    isMediaUploadRequired: z.boolean(),
    mediaUploadInstructions: z.string().optional(),
    minimumMediaUploadAmount: z.number().optional(),
  });

  export const eventCollectCustomFields = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.collectCustomFields}`),
    params: eventCollectCustomFieldsParams,
  });

  export type EventModifyIncentives = z.infer<typeof eventModifyIncentives>;

  // ? possibly elevate into zod extensions
  export function shouldModify<T extends z.ZodRawShape>(
    schema: z.ZodObject<T>,
  ) {
    return z.discriminatedUnion('shouldModify', [
      z.object({ shouldModify: z.literal(false) }),
      schema.merge(z.object({ shouldModify: z.literal(true) })),
    ]);
  }

  export const eventModifyIncentives = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.modifyIncentives}`),
    params: z.object({
      giftCardIncentive: shouldModify(
        z.object({
          amount: helperSchema.nonNegativeNumber,
          type: enumToZodLiteralUnion(CrewIncentiveTypeEnum),
        }),
      ),
    }),
  });

  // ------------- charge for return label ---------------------
  export type EventChargeForReturnLabel = z.infer<
    typeof eventChargeForReturnLabel
  >;
  export const eventChargeForReturnLabel = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.chargeForReturnLabel}`),
    params: z.object({
      applyLabelMarkup: shouldModify(
        z.object({
          amount: helperSchema.nonNegativeNumber,
          type: enumToZodLiteralUnion(CrewIncentiveTypeEnum),
        }),
      ),
    }),
  });

  // ------------- charge for return label ---------------------
  export type EventProductRegistrationSelection = z.infer<
    typeof eventProductRegistrationSelection
  >;

  export const eventProductRegistrationSelection = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.productRegistrationSelection}`),
    params: z.object({
      productRegistrationProductGroupId: z.coerce
        .number()
        .gt(0, 'Please select a Product Group'),
    }),
  });

  // ------------- return shipment quote ---------------------
  export type EventReturnShippingConfig = z.infer<
    typeof eventReturnShippingConfig
  >;
  export const eventReturnShippingConfig = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.returnShippingConfig}`),
    params: z.object({
      returnMethods: z
        .array(z.union([z.literal('Label'), z.literal('Packing_Slip')]))
        .min(1, 'Please select at least one Return Method'),
      returnLocationId: z.coerce
        .number()
        .gt(0, 'Please select a Return Location'),
    }),
  });

  // ------------- charge for exchange order shipping ---------------------
  export type EventChargeForExchangeOrderShipping = z.infer<
    typeof eventChargeForExchangeOrderShipping
  >;
  export const eventChargeForExchangeOrderShipping = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.chargeForExchangeOrderShipping}`),
    params: z.object({
      applyExchangeOrderShippingMarkup: shouldModify(
        z.object({
          amount: helperSchema.nonNegativeNumber,
          type: enumToZodLiteralUnion(CrewIncentiveTypeEnum),
        }),
      ),
    }),
  });

  export type EventApplyFee = z.infer<typeof eventApplyFee>;
  export const eventApplyFee = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.applyFee}`),
    params: z.object({
      feeDisplayName: z.string(),
      feeAmount: z.number().nonnegative(),
    }),
  });

  // offer instant exchange
  export type EventOfferInstantExchange = z.infer<
    typeof eventOfferInstantExchange
  >;
  export const eventOfferInstantExchangeParams = z.object({
    timeToReturnBeforeCharging: z.literal(7), // ! this can be altered once we determine how Stripe interacts with us for pre-authorizations
  });

  export const eventOfferInstantExchange = z.object({
    storeRuleId: z.number().optional(),
    type: z.literal(`${EventType.offerInstantExchange}`),
    params: eventOfferInstantExchangeParams,
  });

  // ----------- type relationship enforcement --------------

  export const hooksForEvent = {
    [EventType.returnShippingConfig]: [Hook.returnShipmentQuote],
    [EventType.modifyResolutionWindow]: [Hook.orderLookup],
    [EventType.offerInstantExchange]: [Hook.orderLookup],
    [EventType.allowClaimTypeSelection]: [Hook.orderLookup],
    [EventType.askAPolicyEnforcingQuestion]: [Hook.afterReasonSelection],
    [EventType.collectCustomFields]: [Hook.afterReasonSelection],
    [EventType.applyClaimTags]: [
      Hook.claimCreated,
      Hook.rmaWebhook,
      Hook.returnShipmentWebhook,
    ],
    [EventType.chargeForReturnLabel]: [Hook.beforeClaimSubmission],
    [EventType.chargeForExchangeOrderShipping]: [Hook.beforeClaimSubmission],
    [EventType.modifyIncentives]: [Hook.beforeClaimSubmission],
    [EventType.applyFee]: [Hook.beforeClaimSubmission],
    [EventType.autoFinalizeClaim]: [
      Hook.rmaWebhook,
      Hook.returnShipmentWebhook,
    ],
    [EventType.customizeClaimFinalizationSettings]: [Hook.claimFinalized],
    [EventType.allowCustomerToKeepItem]: [Hook.returnShipmentCreated],
    [EventType.productRegistrationSelection]: [
      Hook.registrationProductSelection,
    ],
  } as const satisfies Record<EventType, readonly Pegasus.Hook[]>;

  export const registrationFacts = [
    Fact.registrationEstimatedPurchaseDate,
    Fact.registrationChannel,
  ] as const;

  export const orderFacts = [
    Fact.orderDiscountCode,
    Fact.orderCreated,
    Fact.orderCountry,
    Fact.orderGateway,
    Fact.orderTotal,
    Fact.orderTags,
    Fact.customerTags,
    Fact.orderNumber,
    Fact.customerEmail,
    Fact.orderChannel,
    Fact.orderProtectedByCorso,
    Fact.orderDaysSinceCreated,
    Fact.statusCustomer,
    ...registrationFacts,
  ] as const;

  export const lineItemFacts = [
    Pegasus.Fact.productTags,
    Pegasus.Fact.productTypes,
    Pegasus.Fact.productCollections,
  ] as const;

  export const claimLineItemFacts = [
    Pegasus.Fact.reasonId,
    Pegasus.Fact.reasonCategoryCode,
    Pegasus.Fact.reasonDetailId,
    Pegasus.Fact.claimLineItemRequestedResolutionMethod,
    ...lineItemFacts,
  ] as const;

  export const factsForHook = {
    [Hook.returnShipmentQuote]: orderFacts,
    [Hook.orderLookup]: [...orderFacts, ...lineItemFacts],
    [Hook.afterReasonSelection]: [
      ...orderFacts,
      ...claimLineItemFacts,
      Pegasus.Fact.claimType,
    ],
    [Hook.beforeClaimSubmission]: [
      ...orderFacts,
      ...claimLineItemFacts,
      Pegasus.Fact.claimType,
      Pegasus.Fact.exchangeOrderTotal,
    ],
    [Hook.returnShipmentCreated]: [
      ...orderFacts,
      ...claimLineItemFacts,
      Pegasus.Fact.claimLineItemTotal,
      Pegasus.Fact.claimLineItemCustomFieldResponse,
    ],
    [Hook.claimCreated]: [
      ...orderFacts,
      ...claimLineItemFacts,
      Pegasus.Fact.claimType,
      Pegasus.Fact.claimLineItemCustomFieldResponse,
    ],
    [Hook.returnShipmentWebhook]: [
      ...orderFacts,
      ...claimLineItemFacts,
      Pegasus.Fact.claimType,
      Pegasus.Fact.returnShipmentStatus,
      Pegasus.Fact.claimLineItemCustomFieldResponse,
    ],

    [Hook.rmaWebhook]: [
      ...orderFacts,
      ...claimLineItemFacts,
      Pegasus.Fact.rmaStatus,
      Pegasus.Fact.rmaAllItemsReceived,
      Pegasus.Fact.claimType,
      Pegasus.Fact.claimLineItemCustomFieldResponse,
    ],
    [Hook.claimFinalized]: [
      ...orderFacts,
      ...claimLineItemFacts,
      Pegasus.Fact.claimType,
    ],
    [Hook.registrationProductSelection]: registrationFacts,
  } as const satisfies Record<Hook, readonly Pegasus.Fact[]>;

  // TODO rename as `FactsForHook`
  export type FactDataForHook<T extends Hook> = FactData<
    (typeof factsForHook)[T][number]
  >;

  /** Derived from Zod schema for fact expressions. */
  export function operatorForFact(fact: `${Fact}` | Fact) {
    const valueType = factValueType[fact];
    return valueOperators[valueType];
  }
  // factExpression[fact].shape.operator.options.map((option) => option.value);

  /**
   * Derived from Zod schema for fact expressions.
   * Given a fact, it's definition should handle the relationship between the fact, operator, and value.
   * As some point these might need to become independent, where a fact defines shape of it's value, and the operators available are enforced by the resulting expressions.
   */
  export function valueSchemaForFact<T extends Fact>(fact: T) {
    const valueType = factValueType[fact];
    return valueTypeSchema[valueType];
  }

  // ----------- event + rule schema ------------

  // todo: enforce all event schemas
  export const eventSchema = z.union([
    eventModifyResolutionWindow,
    eventOfferInstantExchange,
    eventApplyClaimTags,
    eventAllowClaimTypeSelection,
    eventCollectCustomFields,
    eventAutoFinalizeClaim,
    eventPolicyEnforcement,
    eventClaimFinalizationSettings,
    eventApplyFee,
    eventModifyIncentives,
    eventChargeForReturnLabel,
    eventChargeForExchangeOrderShipping,
    eventAllowCustomerToKeepItem,
    eventProductRegistrationSelection,
    eventReturnShippingConfig,
  ]);

  export type EventParams<T extends EventType> = Extract<
    Event,
    { type: `${T}` }
  >['params'];

  export const eventsSchemaArray = z.array(eventSchema);

  export const ruleSchema = z.object({
    priority: z
      .number({ invalid_type_error: 'Must be a number' })
      .positive({ message: 'Must be greater than zero' })
      .int({ message: 'Must be a whole number' }), // ! larger number = higher priority
    conditions: z.union([allConditionSchema, anyConditionSchema]),
    event: eventSchema,
    storeRuleId: z.number().optional(),
  });
}
