import { z } from 'zod';
import { claimAddress } from './address.js';
import { registrationProductVariant } from './storeProduct.js';

export const registration = z.object({
  externalId: z.string(),
  createdOn: z.string(),
  registrationChannelName: z.string(),
  storeOrder: z.object({
    idFromPlatform: z.string(),
    email: z.string(),
  }),
});

export const registrationCreate = registration
  .omit({
    externalId: true,
    storeOrder: true,
    createdOn: true,
    registrationChannelName: true,
  })
  .merge(
    z.object({
      firstName: z.string(),
      lastName: z.string(),
      email: z.string(),
      storeProductVariants: z.array(
        registrationProductVariant
          .pick({
            id: true,
          })
          .merge(z.object({ quantity: z.number() })),
      ),
      storeSalesChannelId: z.number(),
      address: claimAddress.optional(),
      estimatedPurchaseDate: z.string(),
      proofOfPurchaseFilenames: z.array(z.string()).optional(),
    }),
  );
