import { RouteObject } from 'react-router-dom';
import Claim from './claim';
import NewClaim from './new';
import NotProtected from './not-protected';
import Component from './order-overview-component';
import loader, { ID } from './order-overview-loader';

export default {
  // The ID is used for the useRouteLoaderData hook in loader file, need to match up.
  id: ID,
  path: ':idFromPlatform',
  loader,
  children: [
    {
      index: true,
      Component,
    },
    Claim,
    NewClaim,
    NotProtected,
  ],
} satisfies RouteObject;
