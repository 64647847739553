// ! UTILITY ENUMS TO ASSIST WITH DB
// ! DO NOT CHANGE THESE ENUMS, UNLESS YOU KNOW WHAT THE IMPACT WILL BE
// ! DO NOT RENAME OR MOVE THIS FILE WITHOUT UPDATING ./github/workflows/deploy-stage.yml (the DB should be deployed if these enums change to upsert them correctly)

//* old claim_status.. only utilized by GSP *//
export enum ClaimStatusEnum {
  closedDenied = 'Closed_Denied',
  closedRefunded = 'Closed_Refunded',
  closedReordered = 'Closed_Reordered',
  closedResolved = 'Closed_Resolved',
  inProgress = 'InProgress',
  open = 'Open',
}

export enum ClaimReasonEnum {
  damaged = 'Damaged',
  lost = 'Lost',
  stolen = 'Stolen',
  wrongItem = 'Wrong_Item',
  missingItem = 'Missing_Item',
  qualityIssue = 'Quality_Issue',
  incorrectAddress = 'Incorrect_Address',
}

// labels used for merchant facing UI
export const shippingClaimReasonName = {
  [ClaimReasonEnum.damaged]: 'Damaged',
  [ClaimReasonEnum.stolen]: 'Stolen',
  [ClaimReasonEnum.lost]: 'Lost',
  [ClaimReasonEnum.wrongItem]: 'Wrong Item',
  [ClaimReasonEnum.missingItem]: 'Missing Item',
  [ClaimReasonEnum.qualityIssue]: 'Quality Issue',
  [ClaimReasonEnum.incorrectAddress]: 'Incorrect Address',
} as const satisfies Record<ClaimReasonEnum, string>;

// labels used for merchant facing UI
export const gspClaimReasonLabels = {
  [ClaimReasonEnum.damaged]: 'It was damaged',
  [ClaimReasonEnum.stolen]: 'It was marked delivered but was never received',
  [ClaimReasonEnum.lost]: 'It was lost in transit',
  [ClaimReasonEnum.wrongItem]: 'I received the wrong item(s)',
  [ClaimReasonEnum.missingItem]: 'An item is missing from the order',
  [ClaimReasonEnum.qualityIssue]: 'There is a quality issue with the item(s)',
  [ClaimReasonEnum.incorrectAddress]:
    'The item(s) were sent to the wrong address',
} as const satisfies Record<ClaimReasonEnum, string>;

export enum ClaimResolutionMethodEnum {
  refund = 'Refund',
  reorder = 'Reorder',
}

export enum FulfillmentStrategyEnum {
  onOrderCreate = 'On_Order_Create',
  onFirstFulfillment = 'On_First_Fulfillment',
  none = 'None',
}
/** @deprecated use Source */
export enum ClaimSourceEnum {
  /** @deprecated use Retool */
  corsoSupport = 'Corso_Support',
  /** @deprecated use reorderRequest */
  customer = 'Customer',
  /** @deprecated not in use */
  merchant = 'Merchant',

  retool = 'Retool',
  reorderRequest = 'Reorder_Request',
  customerUi = 'Customer_UI',
  reorderRequestMerchant = 'Reorder_Request_Merchant',
  gorgiasTicketWidget = 'Gorgias_Ticket_Widget',
  zowieInboxIntegration = 'Zowie_Inbox_Integration',
}

// ! note to future us, we aren't actually using the shopify status, we are using our own status,
// ! this should be changed to an enum that matches what shopify responds with the shopify values
/**
 * @see https://shopify.dev/docs/api/admin-graphql/latest/enums/AppSubscriptionStatus
 */
// TODO move this to a shared file
export enum ShopifyAppSubscriptionStatusEnum {
  pending = 'PENDING',
  active = 'ACTIVE',
  expired = 'EXPIRED',
  uninstalled = 'UNINSTALLED',
  declined = 'DECLINED',
  frozen = 'FROZEN', // TODO when this is received, we need to deactivate the app temporarily
  cancelled = 'CANCELLED',
  /** @deprecated Shopify has deprecated this as of `2021-01`; however, it currently still exists. */
  accepted = 'ACCEPTED',
}
