import { LoaderFunctionArgs } from 'react-router-dom';
import { CrewStore } from '~/stores/crew';
import { createLoaderDataHook, json } from '~/utils/routing';

export type AppShellOrderData = {
  orderNo: string;
  idFromPlatform: string;
  orderDate: string;
};
export type AppShellData = {
  footerText?: string;
  store?: string;
  isEmbedded: boolean;
};

/**
 * App shell doesn't know if it's a crew route or not,
 * so need to determine what what Store to use.
 * Prefer to not generally import stores in loaders,
 * but this seems like a reasonable exception that simplifies the code
 */
export default async ({ params: { store } }: LoaderFunctionArgs) => {
  const isCrewRoute = !!store;
  const isEmbedded = window.parent !== window;

  if (isCrewRoute) await CrewStore.initAsync(store);

  return json<AppShellData>({
    store,
    isEmbedded,
    footerText: isCrewRoute ? CrewStore.settings.theme.footerText : undefined,
  });
};

export const useAppShellData = createLoaderDataHook<AppShellData>();
