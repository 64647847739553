import { XCircleIcon } from '@heroicons/react/24/outline';
import { MouseEventHandler, PropsWithChildren, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from './button';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  className?: string;
}>;

function Modal({ open, onClose, children, className }: ModalProps) {
  const ref = useRef<HTMLDialogElement>(null);

  const handleBackdropClick: MouseEventHandler = (event) => {
    if (event.target === ref.current) {
      onClose();
    }
  };

  useEffect(() => {
    if (!ref.current) return;

    if (open) {
      ref.current.showModal();
    } else {
      ref.current.close();
    }
  }, [open]);

  return (
    // dialog will be added as an interactive element (https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/932#issuecomment-1581874258)
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <dialog
      className="relative max-w-lg rounded-md border-0 border-none bg-transparent p-0 backdrop:bg-gray-400 backdrop:bg-opacity-50 backdrop:blur-md"
      ref={ref}
      // makes sure the onClose is called when the escape key is pressed
      onClose={onClose}
      onMouseDown={handleBackdropClick}
    >
      <Button
        autoFocus
        variant="text"
        className="absolute right-1 top-1 p-2"
        onClick={onClose}
      >
        <span className="sr-only">Close</span>
        <XCircleIcon className="size-6 text-gray-500" title="Close" />
      </Button>
      <section
        className={twMerge(
          'flex flex-col gap-6 rounded-md bg-white p-4',
          className,
        )}
      >
        {children}
      </section>
    </dialog>
  );
}

function ModalHeader({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <header className={twMerge('flex justify-between', className)}>
      {children}
    </header>
  );
}

function ModalTitle({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <h3
      className={twMerge(
        'text-base font-semibold leading-6 text-gray-900',
        className,
      )}
    >
      {children}
    </h3>
  );
}

function ModalContent({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) {
  return <div className={className}>{children}</div>;
}

function ModalFooter({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <div className={twMerge('flex justify-end gap-4', className)}>
      {children}
    </div>
  );
}

export { Modal, ModalContent, ModalFooter, ModalHeader, ModalTitle };
