import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import {
  createActionResultHook,
  crewAction,
  error,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { exchangeVariantSchema, lineItemClaimSchema } from '~/utils/schemas';
import { ActionResult, ResolutionMethod } from '~/utils/types';

export enum FormKey {
  resolution = 'resolutionMethod',
  variant = 'variant',
}

const formSchema = zfd.formData(
  z.discriminatedUnion(FormKey.resolution, [
    z.object({
      [FormKey.resolution]: zfd.text(
        z.literal(ResolutionMethod.variantExchange),
      ),
      [FormKey.variant]: zfd.json(exchangeVariantSchema),
    }),
    z.object({
      [FormKey.resolution]: zfd.text(
        z.enum([ResolutionMethod.refund, ResolutionMethod.giftCard]),
      ),
      [FormKey.variant]: zfd.text(z.never().optional()),
    }),
  ]),
);

export default crewAction(
  withDraftClaim(({ context, formData, claim, params: { store } }) => {
    if (!context.order) {
      return error(new Error('Order not found on context'));
    }

    const form = formSchema.safeParse(formData);

    if (!form.success) {
      return error(
        new Error('Malformed form data', {
          cause: {
            error: form.error,
            entries: Array.from(formData.entries()),
          },
        }),
      );
    }

    const parsedClaim = lineItemClaimSchema.parse({
      ...claim,
      requestedResolutionMethodEnum: form.data[FormKey.resolution],
      ...(form.data[FormKey.resolution] === ResolutionMethod.variantExchange ?
        {
          variant: form.data[FormKey.variant],
          grams: form.data[FormKey.variant].grams,
        }
        // cover the case where user changes resolution method
      : { variant: undefined, grams: claim.lineItem.grams }),
    });

    // draft complete add to line item claims
    context.upsertLineItemClaim(parsedClaim);
    context.clearDraftClaim();

    return redirect(
      `/${store}/order/${context.order.idFromPlatform}/${claim.claimType}`,
      StatusCodes.SEE_OTHER,
    );
  }),
);

export const useResolutionActionResult =
  createActionResultHook<ActionResult<FormKey>>();
