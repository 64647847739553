import { z } from 'zod';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';

enum TimelineEventTypeEnum {
  email = 'email',
  shipment = 'shipment',
  processing = 'processing',
  simple = 'simple',
  money = 'money',
  comment = 'comment',
}

const timelineEvent = z.object({
  id: z.string().uuid(),
  datetime: z.string(),
  message: z.string(),
  type: enumToZodLiteralUnion(TimelineEventTypeEnum),
  subject: z.string().optional(),
});
export const claimTimelineEvent = timelineEvent;
export const claimTimeline = z.array(timelineEvent);
