import { z } from 'zod';

export const storeOrderLineItem = z.object({
  id: z.number(),
  // the id of the line item on the platform
  idFromPlatform: z.string(),

  isCorsoProduct: z.boolean(),

  // the name on the storeOrderLineItem (typically refers to the product name including the variant options)
  productIdFromPlatform: z.string(),
  name: z.string().nullable(),
  optionsFromPlatform: z.array(
    z.object({ name: z.string(), value: z.string() }),
  ),

  // the quantity of the line item
  currentQuantity: z.number(),
  quantity: z.number(),

  // the ACTUAL price paid for a single quantity of the line item, inclusive of discounts
  // essentially represents the "unit" price of the line item for a single quantity
  unitPrice: z.number(),
  unitListPrice: z.number().optional(), // the price of the line item before any discounts were applied

  unitTax: z.number(), // the amount of tax paid for a single quantity of the line item

  imgUrl: z.string().nullable(),
  fulfillmentServiceName: z.string().optional(),
  sku: z.string().optional(),

  grams: z.number(),
});
