import { z } from 'zod';
import {
  CrewClaimReasonCategoryCode,
  CrewClaimTypeEnum,
} from '../../enums/crew.js';
import { enumToZodLiteralUnion, helperSchema } from '../../zodExtensions.js';
import { storeProductGroup } from './storeProduct.js';

export const claimReasonCategoryCode = enumToZodLiteralUnion(
  CrewClaimReasonCategoryCode,
);

export const claimReasonDetail = z.object({
  id: z.number(),
  name: z.string(),
  isDeleted: z.boolean(), // soft delete, will not show in UI but retained for historical purposes
});

export const claimReasonDetailUpdate = claimReasonDetail
  .omit({ id: true })
  .merge(z.object({ id: z.number().optional() }));

export const claimReasonDetailCreate = claimReasonDetail.omit({
  id: true,
  isDeleted: true,
});

export const claimReason = z.object({
  id: z.number(),
  name: helperSchema.nonEmptyString,
  isDeleted: z.boolean(), // soft delete, will not show in UI but retained for historical purposes
  claimReasonDetails: z.array(claimReasonDetail),
  category: z.object({
    code: claimReasonCategoryCode,
    name: z.string(),
  }),
});

// need to gracefully handle updating when we add a new claim reason detail

export const claimReasonUpdate = claimReason
  .omit({
    category: true,
    claimReasonDetails: true,
  })
  .merge(z.object({ claimReasonDetails: z.array(claimReasonDetailUpdate) }));

export const claimReasonCreate = claimReason
  .omit({
    id: true,
    claimReasonDetails: true,
    isDeleted: true,
  })
  .merge(z.object({ claimReasonDetails: z.array(claimReasonDetailCreate) }));

// claim reason groups
export const claimReasonGroup = z.object({
  id: z.number(),
  claimType: enumToZodLiteralUnion(CrewClaimTypeEnum),
  name: helperSchema.nonEmptyString,
  isDefault: z.boolean(),
  storeProductGroup: storeProductGroup.optional(),
  claimReasons: z.array(claimReason).min(1),
});

export const claimReasonGroupUpdate = claimReasonGroup.pick({
  id: true,
  claimReasons: true,
  isDefault: true,
  storeProductGroup: true,
  name: true,
  claimType: true,
});

export const claimReasonGroupCreate = claimReasonGroup
  .omit({
    id: true,
    storeProductGroup: true,
  })
  .merge(z.object({ storeProductGroup }));
