export enum TrackingStatusCode {
  unknown = 'unknown',
  preTransit = 'pre_transit',
  inTransit = 'in_transit',
  outForDelivery = 'out_for_delivery',
  delivered = 'delivered',
  availableForPickup = 'available_for_pickup',
  returnToSender = 'return_to_sender',
  failure = 'failure',
  cancelled = 'cancelled',
  error = 'error',
}

export enum TrackingStatusDetailCode {
  unknown = 'unknown',
  statusUpdate = 'status_update',
  departedFacility = 'departed_facility',
  arrivedAtFacility = 'arrived_at_facility',
  outForDelivery = 'out_for_delivery',
  arrivedAtDestination = 'arrived_at_destination',
  label_created = 'label_created',
}
