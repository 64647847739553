import { getAddressFields } from '~/utils/address';
import {
  createLoaderDataHook,
  json,
  registrationLoader,
} from '~/utils/routing';
import { DraftRegistration, SalesChannels } from '~/utils/types';

type AddressFields = ReturnType<typeof getAddressFields>;
type Field = AddressFields[keyof AddressFields];
export type RegisterLoaderData = {
  shouldCollectProofOfPurchase: boolean;
  storeSalesChannels: SalesChannels;
  email: DraftRegistration['email'];
  proofOfPurchaseAssets: NonNullable<
    DraftRegistration['proofOfPurchaseAssets']
  >;
  estimatedPurchaseDate?: DraftRegistration['estimatedPurchaseDate'];
  storeSalesChannel?: DraftRegistration['storeSalesChannel'];
  fields: Field[];
};

export default registrationLoader(({ context }) => {
  const {
    shouldCollectProofOfPurchase,
    storeSalesChannels,
    shouldCollectAddress,
  } = context.settings.registrations;

  const {
    address,
    email = '',
    proofOfPurchaseAssets,
    estimatedPurchaseDate,
    storeSalesChannel,
  } = context.draftRegistration ?? {};

  const addressFields = Object.values(getAddressFields(address));

  const fields =
    shouldCollectAddress ? addressFields
      // we are always going to collect first and last name
    : (
      addressFields.filter(
        (field) => field.name === 'firstName' || field.name === 'lastName',
      )
    );

  return json<RegisterLoaderData>({
    shouldCollectProofOfPurchase,
    storeSalesChannels,
    fields,
    email,
    proofOfPurchaseAssets: proofOfPurchaseAssets ?? [],
    estimatedPurchaseDate,
    storeSalesChannel,
  });
});

export const useRegisterData = createLoaderDataHook<RegisterLoaderData>();
