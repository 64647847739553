import { getClaimReasons } from '~/utils/claim-line-items';
import {
  createLoaderDataHook,
  crewLoader,
  json,
  withDraftClaim,
} from '~/utils/routing';
import { DraftLineItemClaim } from '~/utils/types';

export type ClaimReasonData = {
  reasons: { id: number; name: string }[];
  claim: DraftLineItemClaim;
};

export default crewLoader(
  withDraftClaim(({ claim }) => {
    const reasons = getClaimReasons(claim).map(({ id, name }) => ({
      name,
      id,
    }));

    return json<ClaimReasonData>({ reasons, claim });
  }),
);

export const useReasonData = createLoaderDataHook<ClaimReasonData>();
