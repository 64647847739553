/* eslint-disable @typescript-eslint/no-namespace */

import { z } from 'zod';
import { apiClientResp } from '../merchant/error.js';
import {
  customerUiEventCustomField,
  customUiCustomField,
  customUiCustomFieldSelect,
} from '../merchant/schema/customField.js';
import {
  claimAddress,
  locationAddress,
  orderAddress,
} from '../schema/address.js';
import { claim, claimCreate, claimTag } from '../schema/claim.js';
import {
  claimLineItem,
  claimLineItemCreate,
  claimLineItemInspection,
  variantExchangeLineItem,
} from '../schema/claimLineItem.js';
import { claimReason } from '../schema/claimReasons.js';
import {
  afterReasonSelectionQuery,
  beforeClaimSubmissionBody,
} from '../schema/claimSubmissionEvent.js';
import { storeOrderLineItem } from '../schema/lineItem.js';
import { crewOrder, crewOrderLineItem, storeOrder } from '../schema/order.js';
import { registration, registrationCreate } from '../schema/registration.js';
import {
  shipment,
  shipmentCreate,
  shipmentQuoteBody,
} from '../schema/shipment.js';
import { rate, returnShipmentMethod } from '../schema/shipmentRate.js';
import {
  shippingClaimCreateCustomer,
  shippingClaimCreateMerchant,
  shippingClaimCustomerUi,
} from '../schema/shippingClaim.js';
import { signedUrl } from '../schema/signedUrl.js';
import { registrationProduct } from '../schema/storeProduct.js';
import { orderTracker, orderTrackerDetail } from '../schema/tracking.js';
import { paymentIntent, paymentIntentCreate } from './schema/payment.js';
import {
  monetarySettings,
  monetarySettingsBeforeClaimSubmissionReturn,
  monetarySettingsBeforeClaimSubmissionWarranty,
  monetarySettingsEstimate,
  settings,
} from './schema/settings.js';
import {
  collection,
  productOptions,
  variantExchange,
  variantExchangeOptions,
} from './schema/variantExchange.js';

/**
 * CrewUi types for the crew-customer-ui
 * Utility types to be used in conjunction with the crewCustomerUiApi
 */
export namespace CrewUi {
  // ? urls in use by the backend API for direct linking  to the crew-customer-ui
  export const frontEndUrlPaths = [
    // SLUG branded URLS
    ':slug/claim/overview/:claimExternalId', // CREW claim overview
    ':slug/order/:idFromPlatform', // order hub
    ':slug/reorder/:idFromPlatform', // branded start a gsp reorder
    ':slug/reorder/:idFromPlatform/claim/:shippingClaimId', // shipping claim overview
    'reorder/:idFromPlatform', // start a reorder without a slug
  ] as const;

  // ---------- Variant Exchange ----------

  export type VariantExchange = z.infer<typeof variantExchange>;
  export const variantExchangeSchema = variantExchange;

  export type VariantExchangeOptions = z.infer<typeof variantExchangeOptions>;
  export const variantExchangeOptionsSchema = variantExchangeOptions;

  export type VariantExchangeProductOptions = z.infer<typeof productOptions>;
  export const variantExchangeProductOptionsSchema = productOptions;

  // ---------- Claims ----------
  export type Collection = z.infer<typeof collection>;
  export const collectionSchema = collection;

  // ---------- Payment Intent ----------
  export type PaymentIntent = z.infer<typeof paymentIntent>;
  export const paymentIntentSchema = paymentIntent;

  export type PaymentIntentCreate = z.infer<typeof paymentIntentCreate>;
  export const paymentIntentCreateSchema = paymentIntentCreate;

  // ---------- CustomFields ----------
  export type CustomFieldSelect = z.infer<typeof customUiCustomFieldSelect>;
  export type CustomFieldGeneral = z.infer<typeof customUiCustomField>;

  export type CollectCustomFieldEvent = z.infer<
    typeof customerUiEventCustomField
  >;
  export const collectCustomFieldEventSchema = customerUiEventCustomField;

  // ---------- Claims ----------
  export type Claim = z.infer<typeof claim>;
  export const claimSchema = claim;

  export type ClaimTag = z.infer<typeof claimTag>;
  export const claimTagSchema = claimTag;

  export type ClaimLineItem = z.infer<typeof claimLineItem>;
  export type ClaimLineItemInspection = z.infer<typeof claimLineItemInspection>;

  export type VariantExchangeLineItem = z.infer<typeof variantExchangeLineItem>;
  export const variantExchangeLineItemSchema = variantExchangeLineItem;

  export type ClaimAddress = z.infer<typeof claimAddress>;
  export const claimAddressSchema = claimAddress;

  export type ClaimCreate = z.infer<typeof claimCreate>;
  export const claimCreateSchema = claimCreate;

  export type ClaimLineItemCreate = z.infer<typeof claimLineItemCreate>;
  export const claimLineItemCreateSchema = claimLineItemCreate;

  // ---------- Registration ----------
  export type Registration = z.infer<typeof registration>;
  export const registrationSchema = registration;

  export type RegistrationCreate = z.infer<typeof registrationCreate>;
  export const registrationCreateSchema = registrationCreate;

  export type RegistrationProduct = z.infer<typeof registrationProduct>;
  export const registrationProductSchema = registrationProduct;

  // ---------- Orders (CREW) ----------

  export type CrewOrder = z.infer<typeof crewOrder>;
  export const orderSchema = crewOrder;

  export type OrderLineItem = z.infer<typeof crewOrderLineItem>;
  export const crewOrderLineItemSchema = crewOrderLineItem;
  export type OrderLineItemMetadata = z.infer<typeof storeOrderLineItem>;
  export type OrderAddress = z.infer<typeof orderAddress>;

  export type OrderTracker = z.infer<typeof orderTracker>;
  export type OrderTrackerDetail = z.infer<typeof orderTrackerDetail>;

  // ---------- Signed URL ----------
  export type SignedUrl = z.infer<typeof signedUrl>;
  export const signedUrlSchema = signedUrl;

  // ---------- Settings ----------
  export type Settings = z.infer<typeof settings>;
  export const settingsSchema = settings;

  export type MonetarySettings = z.infer<typeof monetarySettings>;
  export const monetarySettingsSchema = monetarySettings;
  export type MonetarySettingsEstimate = z.infer<
    typeof monetarySettingsEstimate
  >;
  export type MonetarySettingsBeforeClaimSubmissionReturn = z.infer<
    typeof monetarySettingsBeforeClaimSubmissionReturn
  >;
  export type MonetarySettingsBeforeClaimSubmissionWarranty = z.infer<
    typeof monetarySettingsBeforeClaimSubmissionWarranty
  >;

  // ---------- Claim Reasons ----------
  export type ClaimReasons = z.infer<typeof claimReason>;

  // ---------- Shipments ----------
  export type ShipmentQuote = z.infer<typeof shipmentQuoteBody>;
  export const shipmentQuoteSchema = shipmentQuoteBody;

  export type ShipmentRate = z.infer<typeof rate>;
  export const shipmentRateSchema = rate;

  export type ShipmentMethod = z.infer<typeof returnShipmentMethod>;
  export const shipmentMethodSchema = returnShipmentMethod;

  export type ShipmentCreate = z.infer<typeof shipmentCreate>;
  export const shipmentCreateSchema = shipmentCreate;

  export type Shipment = z.infer<typeof shipment>;
  export const shipmentSchema = shipment;

  // ---------- Location Address ----------
  export type ReturnLocationAddress = z.infer<typeof locationAddress>;
  export const locationAddressSchema = locationAddress;

  export type ApiClientResp = z.infer<typeof apiClientResp>;
  export const apiClientRespSchema = apiClientResp;

  // ---------- Orders + Claims (GSP) ----------
  export type ShippingClaim = z.infer<typeof shippingClaimCustomerUi>;
  export const shippingClaimSchema = shippingClaimCustomerUi;

  export type ShippingClaimCreateCustomer = z.infer<
    typeof shippingClaimCreateCustomer
  >;
  export const shippingClaimCreateCustomerSchema = shippingClaimCreateCustomer;

  export type ShippingClaimCreateMerchant = z.infer<
    typeof shippingClaimCreateMerchant
  >;
  export const shippingClaimCreateMerchantSchema = shippingClaimCreateMerchant;

  export type GspOrder = z.infer<typeof storeOrder>;
  export const gspOrderSchema = storeOrder;

  export type AfterReasonSelectionQuery = z.infer<
    typeof afterReasonSelectionQuery
  >;
  export const afterReasonSelectionQuerySchema = afterReasonSelectionQuery;

  export type BeforeClaimSubmissionBody = z.infer<
    typeof beforeClaimSubmissionBodySchema
  >;
  export const beforeClaimSubmissionBodySchema = beforeClaimSubmissionBody;
}
