import { StatusCodes } from 'http-status-codes';
import {
  createActionResultHook,
  crewAction,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { ActionResult } from '~/utils/types';

export const INPUT_KEY = 'quantity';
const validate = (
  value: FormDataEntryValue | null,
  remainingReturnableQuantity: number,
) => {
  const quantity = Number.parseInt(String(value), 10);

  if (Number.isNaN(quantity)) {
    return false;
  }

  if (quantity < 1 || quantity > remainingReturnableQuantity) {
    return false;
  }

  return true;
};

export default crewAction(
  withDraftClaim(({ formData, context, claim }) => {
    const value = formData.get(INPUT_KEY);

    if (!validate(value, claim.lineItem.remainingReturnableQuantity)) {
      return json<ActionResult<typeof INPUT_KEY>>({
        ok: false,
        form: {
          [INPUT_KEY]: `Quantity is required. Please enter a valid number. Received value ${String(
            value,
          )}.`,
        },
      });
    }

    const quantity = Number(value);

    context.setDraftClaim({
      ...claim,
      quantity,
    });

    return redirect('../reason', StatusCodes.SEE_OTHER);
  }),
);

export const useQuantityActionResult =
  createActionResultHook<ActionResult<typeof INPUT_KEY>>();
