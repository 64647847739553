import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { Primitive } from 'type-fest';
import env from '~/env';

type ExtraData<Severity extends Sentry.SeverityLevel> = {
  extra?: Record<string, unknown>;
  tags?: Record<string, Primitive>;
  severity?: Severity;
};

export function logError(
  error: unknown,
  extraData: ExtraData<
    Exclude<Sentry.SeverityLevel, 'log' | 'info' | 'debug'>
  > = {},
) {
  Sentry.captureException(error, extraData);
}
export function logMessage(
  message: string,
  extraData?: ExtraData<Exclude<Sentry.SeverityLevel, 'fatal' | 'error'>>,
) {
  Sentry.captureMessage(message, extraData);
}

type IdentifyUserParams = {
  email: string;
  storeName: string;
  storeId: number;
  orderNo: string;
  orderIdFromPlatform: string;
  appType: 'GSP' | 'CREW';
};

export function init() {
  const nodeEnv = env.VITE_NODE_ENV;
  if (nodeEnv === 'prod') {
    Sentry.init({
      // crew-customer-ui sentry DSN in the Corso Project :) :)
      dsn: env.VITE_SENTRY_DSN,
      environment: nodeEnv,
      normalizeDepth: Infinity,
      attachStacktrace: true,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        // capture react errors
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
          minReplayDuration: 15000,
          networkDetailAllowUrls: [
            window.location.origin,
            'api.corso.com',
            'api.stg.corso.com',
          ],
          networkCaptureBodies: true,
        }),
      ],
      tracePropagationTargets: [env.VITE_CREW_API_URL],
      ignoreErrors: [
        'Object Not Found Matching Id', // https://github.com/getsentry/sentry-javascript/issues/3440
      ],
      release: env.VITE_BUILD_SHA,
      // replay integration configuration
      replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: 0.5,
      tracesSampleRate: 0.2,
    });
  }
}

export function identifyUser(params: IdentifyUserParams) {
  try {
    if (env.VITE_NODE_ENV === 'prod') {
      const {
        email,
        storeId,
        storeName,
        appType,
        orderIdFromPlatform,
        orderNo,
      } = params;

      Sentry.setUser({
        email,
        username: email,
      });

      const isEmbedded = window.parent !== window ? 'true' : 'false';

      Sentry.setTag('storeName', storeName);
      Sentry.setTag('storeId', storeId.toString());
      Sentry.setTag('appType', appType);
      Sentry.setTag('orderIdFromPlatform', orderIdFromPlatform);
      Sentry.setTag('orderNo', orderNo);
      Sentry.setTag(`isEmbedded`, isEmbedded);
    }
  } catch (error) {
    logError(error);
  }
}
