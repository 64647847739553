import { StatusCodes } from 'http-status-codes';
import { getClaimReasons } from '~/utils/claim-line-items';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { ActionResult } from '~/utils/types';

export const INPUT_NAME = 'claimReasonDetailId';

export default crewAction(
  withDraftClaim(({ context, claim, formData }) => {
    const claimReasonDetailId = Number.parseInt(
      String(formData.get(INPUT_NAME)),
      10,
    );

    if (!claim.reason) {
      return error(new Error('Missing claim reason.'));
    }

    const reasonDetail = getClaimReasons(claim)
      .find(({ id }) => id === claim.reason?.id)
      ?.claimReasonDetails.find(({ id }) => id === claimReasonDetailId);

    if (!reasonDetail) {
      return json<ActionResult<typeof INPUT_NAME>>({
        ok: false,
        form: { [INPUT_NAME]: 'Select an option above' },
      });
    }

    context.setDraftClaim({
      ...claim,
      reason: {
        ...claim.reason,
        detail: { id: reasonDetail.id, name: reasonDetail.name },
      },
    });

    return redirect('../follow-up', StatusCodes.SEE_OTHER);
  }),
);

export const useReasonDetailActionResult =
  createActionResultHook<ActionResult<typeof INPUT_NAME>>();
