import { useState } from 'react';
import { Form } from 'react-router-dom';
import Alert from '~/components/alert';
import Button from '~/components/button';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Field, FieldLabel, FieldMessage } from '~/components/field';
import Input from '~/components/input';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { useOrderLookupActionResult } from './gsp-lookup-action';

type LookupParam = 'email' | 'postalCode';

export default function OrderLookup() {
  const isLoading = useIsLoading();
  const actionResult = useOrderLookupActionResult();
  const [lookupParam, setLookupParam] = useState<LookupParam>('email');
  const placeHolderText = '123456, RS-98765, etc.';

  return (
    <Page headline="Let's find your order">
      <Card>
        <Form method="post" className="flex flex-col gap-6">
          {lookupParam === 'email' && (
            <Field>
              <FieldLabel htmlFor="email">
                Email Address <span aria-hidden>*</span>
              </FieldLabel>
              <Input
                aria-describedby="email-field-message"
                className="peer/email"
                id="email"
                name="email"
                placeholder="carl@corso.com"
                required
                type="email"
              />
              <FieldMessage
                id="email-field-message"
                aria-live="assertive"
                className="peer-valid/email:hidden peer-invalid/email:visible peer-invalid/email:text-error peer-placeholder-shown/email:peer-invalid/email:hidden"
              >
                Invalid email address
              </FieldMessage>
              <FieldMessage className="text-xs">
                Look up by{' '}
                <Button
                  variant="text"
                  className="p-0 text-xs"
                  onClick={() => setLookupParam('postalCode')}
                >
                  postal code
                </Button>
              </FieldMessage>
            </Field>
          )}
          {lookupParam === 'postalCode' && (
            <Field>
              <FieldLabel htmlFor="postalCode">
                Postal Code <span aria-hidden>*</span>
              </FieldLabel>
              <Input
                id="postalCode"
                name="postalCode"
                placeholder="92675"
                required
                autoComplete="postal-code"
              />
              <FieldMessage className="text-xs">
                Look up by{' '}
                <Button
                  variant="text"
                  className="p-0 text-xs"
                  onClick={() => setLookupParam('email')}
                >
                  email address
                </Button>
              </FieldMessage>
            </Field>
          )}
          <Field>
            <FieldLabel htmlFor="order">
              Order Number <span aria-hidden>*</span>
            </FieldLabel>
            <Input
              id="order"
              name="orderNo"
              placeholder={placeHolderText}
              required
            />
          </Field>

          {actionResult?.ok === false && (
            <Alert variant="error" title="Something isn't quite right">
              <p>{actionResult.message}</p>
            </Alert>
          )}

          <Submit loading={isLoading}>Look It Up</Submit>
        </Form>
      </Card>
    </Page>
  );
}
