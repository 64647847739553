import { CrewReturnShipmentTypeEnum } from 'corso-types/enums/crew';
import { StatusCodes } from 'http-status-codes';
import Payments from '~/stores/payments';
import api from '~/utils/api';
import {
  isExchangeClaim,
  isGiftCardClaim,
  isRefundClaim,
  sumPrice,
  sumTax,
} from '~/utils/compute';
import { messagingInDays, toCurrency } from '~/utils/formatters';
import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import {
  CrewContext,
  CrewOrder,
  ExchangeMethod,
  FormattedValue,
  KindOfMonetarySettings,
  LineItemClaim,
  ResolutionMethod,
  Settings,
} from '~/utils/types';

type ReviewSection = {
  items: LineItemClaim[];
  tax: FormattedValue;
  subtotal: FormattedValue;
  shipping: FormattedValue;
  total: FormattedValue;
};

type ExchangeMethodOption = {
  type: ExchangeMethod;
  label: string;
  description: string;
};

export type ReviewData = {
  fromCustomer: ReviewSection & {
    incentive?: FormattedValue;
    fee?: FormattedValue;
    feeLabel?: string;
  };
  toCustomer: ReviewSection | null;
  balance: FormattedValue;
  refundMethod: ResolutionMethod.giftCard | ResolutionMethod.refund | null;
  paymentIntentSecret?: string;
  canInstantExchange: boolean;
  exchangeMethods: ExchangeMethodOption[];
  authorizedAmount: FormattedValue;
};

type SectionDataComputeParams = {
  settings: Settings;
  monetarySettings: KindOfMonetarySettings<'beforeClaimSubmissionReturn'>;
  items: LineItemClaim[];
  formatCurrency: (value: number) => FormattedValue;
};

export const toCustomerSectionData = (params: SectionDataComputeParams) => {
  const {
    monetarySettings: { exchangeOrderShippingCharge },
    items,
    formatCurrency,
  } = params;
  const subtotal = sumPrice(items);
  const tax = sumTax(items);

  // ts is complaining about assigning a default value in the destructure
  const shipping = exchangeOrderShippingCharge ?? 0;

  const total = subtotal + tax + shipping;

  return {
    items,
    tax: formatCurrency(tax),
    subtotal: formatCurrency(subtotal),
    shipping: formatCurrency(shipping),
    total: formatCurrency(total),
  };
};

export const fromCustomerSectionData = (params: SectionDataComputeParams) => {
  const {
    settings: {
      return: { isGiftCardEnabled },
    },
    monetarySettings,
    items,

    formatCurrency,
  } = params;
  const {
    fee: feeSettings,
    returnLabelCharge,
    giftCardIncentiveAmount,
  } = monetarySettings;

  const subtotal = sumPrice(items);
  const tax = sumTax(items);

  const incentive =
    isGiftCardEnabled && giftCardIncentiveAmount ? giftCardIncentiveAmount : 0;

  const shipping = returnLabelCharge ? -1 * returnLabelCharge : 0;
  const fee = feeSettings ? -1 * feeSettings.amount : 0;
  const total = subtotal + tax + incentive + shipping + fee;
  return {
    items,
    tax: formatCurrency(tax),
    subtotal: formatCurrency(subtotal),
    incentive: formatCurrency(incentive),
    shipping: formatCurrency(shipping),
    fee: formatCurrency(fee),
    feeLabel: feeSettings?.displayName,
    total: formatCurrency(total),
  };
};

const getRefundMethod = (items: LineItemClaim[]) => {
  if (items.some(isRefundClaim)) return ResolutionMethod.refund;
  if (items.some(isGiftCardClaim)) return ResolutionMethod.giftCard;

  return null;
};

const checkInstantExchangeAvailable = (context: CrewContext) => {
  const { settings, lineItemClaims, order, returnMethod } = context;

  const stripeConfigured = Boolean(settings.stripeConfig);
  const instantExchangeOffered = Boolean(order?.offerInstantExchange);
  const allExchangeClaims = lineItemClaims.every(isExchangeClaim);
  const hasReturnLabel =
    returnMethod?.type === CrewReturnShipmentTypeEnum.label;

  return (
    stripeConfigured &&
    instantExchangeOffered &&
    allExchangeClaims &&
    hasReturnLabel
  );
};

const getExchangeMethods = (
  offerInstantExchange: CrewOrder['offerInstantExchange'],
) => {
  const timeFrame = offerInstantExchange?.timeToReturnBeforeCharging ?? 0;

  return [
    {
      type: ExchangeMethod.instant,
      label: 'Instant Exchange',
      description: `Use a credit card to get your items sent immediately, You will only be charged if you do not return your items within ${messagingInDays(
        timeFrame,
      )}.`,
    },
    {
      type: ExchangeMethod.standard,
      label: 'Exchange',
      description: 'Send your items back first, and get your new items later.',
    },
  ] satisfies ExchangeMethodOption[];
};

export default crewLoader(async ({ params: { store }, context }) => {
  //*  The action resets the state of the app when the claim is created. This prevents the user from navigating back.
  if (
    context.lineItemClaims.length === 0 ||
    !context.returnMethod ||
    !context.order
  ) {
    return redirect(`/${store}`, StatusCodes.MOVED_TEMPORARILY);
  }

  const { settings } = context;
  const { order } = context;
  const toCustomerItems = context.lineItemClaims.filter(isExchangeClaim);
  const fromCustomerItems = [...context.lineItemClaims];

  const refundMethod = getRefundMethod(fromCustomerItems);
  const formatValueToCurrency = toCurrency(order.currencyCode);
  const formatCurrency = (value: number) => ({
    value,
    display: formatValueToCurrency(value),
  });

  const lineItems = context.lineItemClaims.map((claim) => ({
    claimType: claim.claimType,
    lineItemId: claim.lineItem.id,
    reasonId: claim.reason.id,
    reasonCategoryCode: claim.reason.category,
    reasonDetailId: claim.reason.detail?.id,
    requestedResolutionMethod: claim.requestedResolutionMethodEnum,
    lineItemTotal: (claim.unitPrice + claim.unitTax) * claim.quantity,
    customFields: Object.entries(claim.customFields).map(([key, value]) => ({
      id: Number(key),
      value,
    })),
  }));

  const monetarySettings = await api.checkBeforeClaimSubmission({
    params: { storefrontId: settings.storefrontId },
    body: {
      kind: 'beforeClaimSubmissionReturn' as const,
      lineItems,
      fromCustomer: context.returnMethod,
      toCustomer: context.toCustomerRate,
    },
  });

  const fromCustomer = fromCustomerSectionData({
    settings,
    monetarySettings,
    items: fromCustomerItems,
    formatCurrency,
  });

  const toCustomer =
    toCustomerItems.length > 0 ?
      toCustomerSectionData({
        settings,
        monetarySettings,
        items: toCustomerItems,
        formatCurrency,
      })
    : null;

  const balance = (toCustomer?.total.value ?? 0) - fromCustomer.total.value;
  const canInstantExchange = checkInstantExchangeAvailable(context);
  const authorizedAmount =
    canInstantExchange ?
      (toCustomer?.total.value ?? 0) +
      fromCustomer.shipping.value +
      fromCustomer.fee.value
    : balance;

  const paymentIntentSecret =
    authorizedAmount > 0 && settings.stripeConfig ?
      await Payments.createPaymentIntent({
        storefrontId: settings.storefrontId,
        amount: authorizedAmount,
        currencyCode: order.currencyCode,
        stripeConfig: settings.stripeConfig,
        customerEmail: context.order.email,
      })
    : undefined;

  return json<ReviewData>({
    fromCustomer,
    toCustomer,
    refundMethod,
    balance: {
      value: balance,
      display: toCurrency(order.currencyCode)(Math.abs(balance)),
    },
    paymentIntentSecret,
    canInstantExchange,
    exchangeMethods: getExchangeMethods(context.order.offerInstantExchange),
    authorizedAmount: formatCurrency(authorizedAmount),
  });
});

export const useReviewLoaderData = createLoaderDataHook<ReviewData>();
