import { MediaAsset } from './types';

type FileType = MediaAsset['type'];
export const determineFileType = (file: File): FileType => {
  const type = file.type.split('/')[0];
  if (type === 'image' || type === 'video' || type === 'audio') {
    return type satisfies FileType;
  }
  if (type === 'application') {
    return 'document';
  }
  return 'other';
};
