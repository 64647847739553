import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export type Variant = 'filled' | 'outlined' | 'text';
type Props = ComponentProps<'button'> & {
  variant?: Variant;
};

export const baseStyles =
  'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 font-medium shadow-sm hover:brightness-90 focus:outline-none focus:ring-primary focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed';

export const variantStyles: Record<Variant, string> = {
  filled: 'bg-primary text-on-primary',
  outlined:
    'border-primary text-primary-on-container bg-transparent hover:bg-surface',
  text: 'text-primary-on-container hover:underline shadow-none',
} as const;

export default function Button({
  children,
  className,
  variant = 'filled',
  type = 'button',
  ...rest
}: Props) {
  return (
    <button
      {...rest}
      // eslint-disable-next-line react/button-has-type -- ensuring with the default prop that type will be assigned
      type={type}
      className={twMerge([baseStyles, variantStyles[variant], className])}
    >
      {children}
    </button>
  );
}
