import { useSearchParams } from 'react-router-dom';
import { CORSO_URL } from '~/utils/types';
import Card from '~/components/card';
import LinkButton from '~/components/link-button';

export default function InactiveMerchant() {
  const [searchParams] = useSearchParams();
  const redirectUrl = decodeURI(searchParams.get('redirect') ?? CORSO_URL);
  const buttonText =
    redirectUrl === CORSO_URL ? 'Visit Corso' : 'Return to Merchant';

  return (
    <section className="px-4 pb-4">
      <Card>
        <p>
          Sorry about that, it looks like this merchant is not currently using
          Corso.
        </p>
        <LinkButton
          to={redirectUrl}
          variant="filled"
          className="mt-6 w-full sm:w-1/2"
        >
          {buttonText}
        </LinkButton>
      </Card>
    </section>
  );
}
