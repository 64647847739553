import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { validateAddressData } from '~/utils/address';
import api from '~/utils/api';
import { toConjunctiveList } from '~/utils/formatters';
import {
  createActionResultHook,
  error,
  json,
  redirect,
  registrationAction,
} from '~/utils/routing';
import { mediaAssetSchema, stringToJSONSchema } from '~/utils/schemas';
import { ActionResult, Address } from '~/utils/types';

export enum FormKeys {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  StoreSalesChannel = 'storeSalesChannel',
  EstimatedPurchaseDate = 'estimatedPurchaseDate',
  Assets = 'assets',
  RequiredAssetCount = 'requiredAssetCount',
}

const schema = zfd.formData({
  [FormKeys.FirstName]: zfd.text(z.string()),
  [FormKeys.LastName]: zfd.text(z.string()),
  [FormKeys.Email]: zfd.text(z.string()),
  [FormKeys.StoreSalesChannel]: zfd.numeric(),
  [FormKeys.EstimatedPurchaseDate]: zfd.text(z.string()),
  [FormKeys.RequiredAssetCount]: zfd.numeric(z.number().int().nonnegative()),
  [FormKeys.Assets]: zfd.repeatableOfType(
    stringToJSONSchema.pipe(mediaAssetSchema),
  ),
  files: zfd
    .repeatable(z.array(z.instanceof(File)).optional())
    .transform((files) => files?.filter((file) => file.size > 0) ?? []),
});

export default registrationAction(({ formData, context }) => {
  const {
    storefrontId,
    registrations: { shouldCollectAddress, storeSalesChannels },
  } = context.settings;

  // verify the address only if it's required by the merchant
  let address: Address | null = null;
  if (shouldCollectAddress) {
    const result = validateAddressData(formData);
    if (!result.ok) {
      return json<ActionResult<FormKeys>>({
        ok: false,
        form: result.errors,
      });
    }
    address = result.data;
  }

  // validate the rest of the form
  const results = schema.safeParse(formData);
  if (!results.success) {
    return error(
      new Error('Malformed form data', {
        cause: {
          error: results.error,
          entries: Array.from(formData.entries()),
        },
      }),
    );
  }

  const {
    [FormKeys.FirstName]: firstName,
    [FormKeys.LastName]: lastName,
    [FormKeys.Email]: email,
    [FormKeys.StoreSalesChannel]: storeSalesChannelId,
    [FormKeys.EstimatedPurchaseDate]: estimatedPurchaseDate,
    [FormKeys.RequiredAssetCount]: requiredAssetCount,
    [FormKeys.Assets]: assets,
    files,
  } = results.data;

  const storeSalesChannel = storeSalesChannels.find(
    (channel) => channel.id === storeSalesChannelId,
  );

  if (!storeSalesChannel) {
    return error(new Error('Invalid store sales channel'));
  }

  const assetsRequired = requiredAssetCount > 0;
  const existingMediaCount = assets.length + files.length;

  if (assetsRequired && existingMediaCount < requiredAssetCount) {
    return json<ActionResult<FormKeys>>({
      ok: false,
      form: {
        [FormKeys.Assets]: `A minimum of ${requiredAssetCount} files are required`,
      },
    });
  }

  const uploads =
    files.length ?
      api.uploadFiles({
        params: { storefrontId },
        query: { destination: 'crewProofOfPurchase' },
        body: files,
      })
    : Promise.resolve({ assets: [], errors: [] });

  return uploads.then(({ assets: newAssets, errors }) => {
    context.setDraftRegistration({
      email,
      firstName,
      lastName,
      storeSalesChannel,
      estimatedPurchaseDate,
      address,
      proofOfPurchaseAssets: [...assets, ...newAssets],
    });

    return errors.length ?
        json<ActionResult<FormKeys>>({
          ok: false,
          message: 'Problem uploading files',
          form: {
            [FormKeys.Assets]: `${toConjunctiveList(errors)} failed to upload.`,
          },
        })
      : redirect('./product');
  });
});

export const useRegisterActionResult =
  createActionResultHook<ActionResult<FormKeys>>();
