import { z } from 'zod';
import {
  CrewClaimResolutionMethodEnum,
  CrewClaimTypeEnum,
} from '../../enums/crew.js';
import { rate, returnShipmentMethod } from './shipmentRate.js';

export const afterReasonSelectionQuery = z.object({
  lineItemId: z.coerce.number(),
  claimType: z.union([
    z.literal(`${CrewClaimTypeEnum.return}`),
    z.literal(`${CrewClaimTypeEnum.warranty}`),
  ]),
  reasonId: z.coerce.number(),
  reasonCategoryCode: z.coerce.string(),
  reasonDetailId: z.coerce.number().optional(),
});

export const beforeClaimSubmissionQuery = z.array(
  afterReasonSelectionQuery.merge(
    z.object({
      // ! make these required following merge
      requestedResolutionMethod: z.union([
        z.literal(`${CrewClaimResolutionMethodEnum.refund}`),
        z.literal(`${CrewClaimResolutionMethodEnum.giftCard}`),
        z.literal(`${CrewClaimResolutionMethodEnum.variantExchange}`),
        z.literal(`${CrewClaimResolutionMethodEnum.warrantyReview}`),
      ]),
      lineItemTotal: z.coerce.number(),
      customFields: z
        .array(
          z.object({
            id: z.coerce.number(),
            value: z.string(),
          }),
        )
        .optional(),
    }),
  ),
);

const beforeClaimSubmissionRefundOptions = z.object({
  kind: z.literal('estimateIncentive'),
  lineItems: beforeClaimSubmissionQuery,
}); // ? to be filled in later

const beforeClaimSubmissionReturn = z.object({
  kind: z.literal('beforeClaimSubmissionReturn'),
  fromCustomer: returnShipmentMethod,
  toCustomer: rate.nullable(),
  lineItems: beforeClaimSubmissionQuery,
});

const beforeClaimSubmissionWarranty = z.object({
  kind: z.literal('beforeClaimSubmissionWarranty'),
  lineItems: beforeClaimSubmissionQuery,
});

export const beforeClaimSubmissionBody = z.discriminatedUnion('kind', [
  beforeClaimSubmissionRefundOptions,
  beforeClaimSubmissionReturn,
  beforeClaimSubmissionWarranty,
]);
