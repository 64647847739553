import { TextareaHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  emptyIsInvalid?: boolean;
};

const baseStyles =
  'w-full rounded-md border-gray-300 pl-4 sm:text-sm focus:border-primary focus:ring-primary';
const disabledStyles =
  'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200';
const emptyInvalidStyles =
  'empty:invalid:border-gray-300 empty:invalid:focus:border-primary empty:invalid:focus:ring-primary empty:invalid:placeholder:text-gray-500';
const invalidStyles =
  'invalid:border-error invalid:focus:border-error invalid:focus:ring-error invalid:placeholder:text-error';

export default function TextArea({
  className,
  rows = 4,
  emptyIsInvalid = false,
  ...rest
}: Props) {
  return (
    <textarea
      {...rest}
      rows={rows}
      className={twMerge(
        baseStyles,
        disabledStyles,
        invalidStyles,
        !emptyIsInvalid && emptyInvalidStyles,
        className,
      )}
    />
  );
}
