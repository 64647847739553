import { z } from 'zod';

export type FrontClaimConversation = z.infer<typeof frontClaimConversation>;
export const frontClaimConversation = z.object({
  customerEmail: z.string(),
  customerName: z.string().nullable(),
  subject: z.string(),
  messages: z.array(
    z.object({
      from: z.string(),
      preview: z.string(), // ! a preview of the message, called a "blurb" in the Front API
      text: z.string(), // ! the full text of the message, (not useful, filled with junk)
      htmlBody: z.string(), // ! the full body of the message, generally HTML
      sanitizedHtmlBody: z.string(), //! the sanitized HTML body of the message
      createdOn: z.string(), // ISO date string with timestamp
    }),
  ),
});
