import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';
import { GspClaimReason, GspState } from '~/utils/types';

export type LoaderData = {
  reasons: { value: GspClaimReason; label: string }[];
  selectedReason: GspState['reason'] | null;
};

export const reasonLabels = {
  [GspClaimReason.damaged]: 'It was damaged',
  [GspClaimReason.stolen]: 'It was marked delivered but was never received',
  [GspClaimReason.lost]: 'It was lost in transit',
  [GspClaimReason.wrongItem]: 'I received the wrong item',
  [GspClaimReason.missingItem]: 'An item is missing from my order',
  [GspClaimReason.qualityIssue]: 'There is a quality issue with my item',
  [GspClaimReason.incorrectAddress]: 'The wrong address was used',
} as const satisfies Record<GspClaimReason, string>;

export default gspLoader(({ params: { store = '' }, context }) =>
  !context.order ?
    redirect(`/${store}`)
  : json<LoaderData>({
      reasons: context.order.shippingClaimReasons.map((reason) => ({
        value: reason,
        label: reasonLabels[reason],
      })),
      selectedReason: context.reason,
    }),
);

export const useReasonLoaderData = createLoaderDataHook<LoaderData>();
