import { XMarkIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Command } from 'cmdk';
import debounce from 'lodash.debounce';
import { useCallback, useMemo, useState } from 'react';
import { Form, useFetcher } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Button from '~/components/button';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Field, FieldLabel } from '~/components/field';
import Input from '~/components/input';

import LineItem from '~/components/line-item';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import Spinner from '~/components/spinner';
import { useIsLoading } from '~/utils/routing';
import { RegistrationProduct } from '~/utils/types';
import { FORM_KEY } from './register-product-action';
import {
  ProductRegisterLoaderData,
  SEARCH_PARAM,
  useRegisterProductData,
} from './register-product-loader';

type Variant = RegistrationProduct['variants'][number];
function SelectVariantActions({
  variant,
  onRemove,
  index,
}: {
  variant: Variant;
  onRemove: (v: Variant) => void;
  index: number;
}) {
  return (
    <div className="flex items-end justify-end gap-6 sm:items-stretch">
      <input
        type="hidden"
        name={`${FORM_KEY}[${index}][id]`}
        value={variant.id}
        key={variant.id}
      />
      <Field className="flex flex-1 flex-col gap-2 sm:flex-row sm:items-center sm:justify-end">
        <FieldLabel htmlFor={`${variant.id}-quantity`}>
          <XMarkIcon className="hidden size-5 sm:block" title="Quantity" />
          <span className="sm:sr-only">Quantity</span>
        </FieldLabel>
        <Input
          type="number"
          id={`${variant.id}-quantity`}
          name={`${FORM_KEY}[${index}][quantity]`}
          className="w-14 px-2"
          defaultValue={1}
          min={1}
          required
          aria-describedby={`${variant.id}-quantity-help`}
        />
        <span id={`${variant.id}-quantity-help`} className="sr-only">
          Quantity of {variant.productTitle}{' '}
          {variant.options.map(({ value }) => value).join(', ')}
        </span>
      </Field>
      <Button
        variant="outlined"
        className="sm:w-14 sm:flex-none"
        onClick={() => onRemove(variant)}
      >
        <TrashIcon
          className="size-5"
          title={`Remove ${variant.productTitle}`}
        />
        <span className="ml-1 sm:sr-only">Remove</span>
      </Button>
    </div>
  );
}

export default function RegisterProducts() {
  const {
    identifyProductFormPlaceholder,
    identifyProductUrlButtonText,
    identifyProductDetailText,
    identifyProductUrl,
  } = useRegisterProductData();

  const [selectedVariants, setSelectedVariants] = useState<Variant[]>([]);

  const [searchTerm, setSearchTerm] = useState('');

  const isPageSubmitted = useIsLoading();

  const fetcher = useFetcher<ProductRegisterLoaderData>();

  const submitSearch = useMemo(
    () =>
      debounce(
        (search: string) =>
          fetcher.submit({ [SEARCH_PARAM]: search }, { method: 'GET' }),
        200,
      ),
    [fetcher],
  );

  const handleSearch = useCallback(
    (search: string) => {
      setSearchTerm(search);
      submitSearch(search);
    },
    [setSearchTerm, submitSearch],
  );

  const removeVariant = (variant: RegistrationProduct['variants'][number]) =>
    setSelectedVariants((prevSelectedVariants) =>
      prevSelectedVariants.filter((v) => v.id !== variant.id),
    );

  const selectVariant = (variant: RegistrationProduct['variants'][number]) =>
    setSelectedVariants((prevSelectedVariants) =>
      prevSelectedVariants.some((v) => v.id === variant.id) ?
        prevSelectedVariants
      : [...prevSelectedVariants, variant],
    );

  return (
    <Page headline="Select Your Products">
      <Card headline="Getting started">
        <span className="flex items-center gap-2 text-sm font-medium text-gray-800">
          {identifyProductDetailText}
        </span>
        {identifyProductUrl && (
          <LinkButton
            variant="outlined"
            to={identifyProductUrl}
            target="_blank"
            className="w-full md:w-1/2"
          >
            {identifyProductUrlButtonText}
          </LinkButton>
        )}
      </Card>

      <Card headline="Search">
        <Command shouldFilter={false}>
          <div className="relative">
            <Command.Input
              asChild
              placeholder={identifyProductFormPlaceholder}
              value={searchTerm}
              onValueChange={handleSearch}
            >
              <Input className="pr-12" />
            </Command.Input>

            {fetcher.state === 'loading' && (
              <Spinner className="absolute inset-y-2 right-2 size-6" />
            )}
          </div>

          <Command.List
            className={twMerge(
              'mt-4 max-h-96 overflow-auto',
              '[&>[cmdk-list-sizer]]:flex [&>[cmdk-list-sizer]]:flex-col [&>[cmdk-list-sizer]]:gap-4',
            )}
          >
            {/* better nothing found state */}
            {fetcher.state === 'idle' &&
              fetcher.data?.products.length === 0 && (
                <Command.Empty>
                  No products found, please try your search again.
                </Command.Empty>
              )}

            {fetcher.data?.products?.map((product) => (
              <Command.Group
                key={product.id}
                heading={product.title}
                value={`${product.id}`}
                className={twMerge(
                  '[&>[cmdk-group-heading]]:mb-2 [&>[cmdk-group-heading]]:text-lg [&>[cmdk-group-heading]]:text-gray-600',
                  '[&>[cmdk-group-items]]:flex [&>[cmdk-group-items]]:flex-col [&>[cmdk-group-items]]:gap-1 [&>[cmdk-group-items]]:px-2',
                )}
              >
                {product.variants?.map((variant) => (
                  <Command.Item
                    key={variant.id}
                    value={`${variant.id}`}
                    onSelect={() => selectVariant(variant)}
                    className="cursor-pointer rounded-sm p-1 aria-selected:bg-gray-50 aria-selected:ring-1 aria-selected:ring-outline"
                  >
                    <LineItem
                      name={`${variant.productTitle}`}
                      variant="small"
                      imgSrc={variant?.imageUrl ?? product.imgUrl}
                      options={variant.options}
                      key={variant.id}
                    />
                  </Command.Item>
                ))}
              </Command.Group>
            ))}
          </Command.List>
        </Command>

        {fetcher.state === 'idle' && !!fetcher.data?.products.length && (
          <p>{fetcher.data?.products.length} products found.</p>
        )}
      </Card>

      {!!selectedVariants.length && (
        <Card testId="selection-review" headline="Review">
          <Form method="post" className="flex flex-col gap-6">
            <ul className="flex flex-col divide-y divide-outline">
              {selectedVariants.map((variant, index) => (
                <li key={variant.id} className="py-3">
                  <LineItem
                    name={variant.productTitle}
                    variant="small"
                    imgSrc={variant?.imageUrl}
                    options={variant.options}
                  >
                    <SelectVariantActions
                      variant={variant}
                      index={index}
                      onRemove={removeVariant}
                    />
                  </LineItem>
                </li>
              ))}
            </ul>

            <Submit loading={isPageSubmitted}>Submit</Submit>
          </Form>
        </Card>
      )}
    </Page>
  );
}
