/* eslint-disable @typescript-eslint/no-namespace */ //! this file contains namespaced schemas for the dbJson fields

import { z } from 'zod';
import { helperSchema } from './zodExtensions.js';

export namespace DbJsonSchema {
  //! namespace relates a specific table in the DB
  export namespace StoreUiConfig {
    export const customerUiConfigSchema = z.object({
      merchantPrimaryColor: z.string(),
      merchantLogoUrl: z.string(),
      merchantBackgroundUrl: z.string().nullable(),
      merchantFaviconUrl: z.string(),
      footerText: z.string(),
      //! any additional properties should be added as optional
      orderLookupDetailText: helperSchema.optionalizedString,
      merchantBackgroundColor: helperSchema.optionalizedString,
      merchantPageTitle: helperSchema.optionalizedString,
      noReturnRequiredItemsDetailText: helperSchema.optionalizedString,
      warrantyItemsDetailText: helperSchema.optionalizedString,
      identifyProductUrl: helperSchema.optionalizedString,
      identifyProductUrlButtonText: helperSchema.optionalizedString,
      identifyProductDetailText: helperSchema.optionalizedString,
      identifyProductFormPlaceholder: helperSchema.optionalizedString,
    });

    export type CustomerUi = z.infer<typeof customerUiConfigSchema>;

    // * potentially more at home in lib, than types
    export const defaultStoreUiTheme: CustomerUi = {
      merchantPrimaryColor: '#000000',
      merchantLogoUrl: 'https://cdn.corso.com/img/crew-ui-corso-logo.svg',
      merchantBackgroundUrl: null,
      merchantFaviconUrl: 'https://cdn.corso.com/img/crew-ui-corso-logo.svg',
      footerText: '',
    };
  }

  export namespace StoreSettings {
    export const dashboards = z.object({
      dashboards: z.array(z.object({ id: z.string(), name: z.string() })),
    });

    export type DisplayDashboards = z.infer<typeof dashboards>;
  }

  export namespace StoreOrderLineItem {
    export const selectedOptionsSchema = z.array(
      z.object({ name: z.string(), value: z.string() }),
    );

    export type SelectedOptions = z.infer<typeof selectedOptionsSchema>;
  }

  export namespace StoreProduct {
    export const options = z.record(z.string());
  }

  export namespace StoreProductVariant {
    /** @deprecated we have migrated fully to selected options in the name value format */
    export const selectedOptionsAsRecord = z.record(z.string());

    export const selectedOptionsAsNameValue = z.array(
      z.object({ name: z.string(), value: z.string() }),
    );
  }
}
