import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Field, FieldLabel, FieldMessage } from '~/components/field';
import Input from '~/components/input';
import LineItem from '~/components/line-item';
import Page from '~/components/page';
import { useItemsActionData } from './items-action';
import { useItemsLoaderData } from './items-loader';

export default function AffectedItems() {
  const { items, selectedItemIds } = useItemsLoaderData();
  const actionResult = useItemsActionData();

  return (
    <Page headline="Which items were affected?">
      <Card>
        <Form method="POST" className="flex flex-col gap-6">
          <fieldset
            className="divide-y divide-outline *:py-4 [&>div:first-of-type]:!border-t-0 [&>div:first-of-type]:pt-0 [&>div:last-of-type]:pb-0"
            aria-describedby="field-message"
          >
            <legend className="sr-only">Order Items</legend>
            {items.map((item) => (
              <Field className="flex flex-row items-center gap-4" key={item.id}>
                <FieldLabel
                  className="flex w-full items-center justify-between gap-4"
                  htmlFor={`${item.id}`}
                >
                  <span className="sr-only">{item.name}</span>
                  <div aria-hidden className="flex-1">
                    <LineItem
                      variant="small"
                      name={item.name ?? 'N/A'}
                      imgSrc={item.imgUrl}
                      quantity={item.quantity}
                    />
                  </div>
                  <Input
                    type="checkbox"
                    className="size-5 rounded-md border-primary p-0 text-primary accent-primary focus-within:ring-2 focus-within:ring-primary"
                    id={`${item.id}`}
                    name="items"
                    value={item.id}
                    defaultChecked={selectedItemIds.includes(item.id)}
                    aria-invalid={actionResult?.ok === false}
                  />
                </FieldLabel>
              </Field>
            ))}
          </fieldset>
          {actionResult?.ok === false && (
            <FieldMessage error>{actionResult.message}</FieldMessage>
          )}
          <Submit>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
