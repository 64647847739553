import { z } from 'zod';
import { helperSchema } from '../../zodExtensions.js';

// * variant exchange
export const storeProductVariant = z.object({
  id: z.number(),
  label: z.string(),
  productName: z.string(),
  imageUrl: z.string().nullable(),
  idFromPlatform: z.string(),
  sku: z.string().nullable(),
  quantity: z.number().nullable(),
  price: z.string().nullable(),
});

// * variant exchange
export const storeProduct = z.object({
  id: z.number(),
  title: z.string(),
  idFromPlatform: z.string(),
  imgUrl: z.string().nullable(),
  variants: z.array(storeProductVariant),
});

export const registrationProductVariant = storeProductVariant
  .pick({
    id: true,
    imageUrl: true,
  })
  .merge(
    z.object({
      productTitle: z.string(),
      // Title and options are the same thing
      title: z.string(),
      options: z.array(
        z.object({
          name: z.string(),
          value: z.string(),
        }),
      ),
    }),
  );

export const registrationProduct = storeProduct
  .pick({
    id: true,
    title: true,
    imgUrl: true,
  })
  .merge(
    z.object({
      variants: z.array(registrationProductVariant),
    }),
  );

export const productType = z.object({
  id: z.number(),
  name: z.string(),
});

export const productTag = z.object({
  id: z.number(),
  name: z.string(),
});

export const productCollection = z.object({
  id: z.number(),
  name: z.string(),
});

export const storeProductGroup = z.object({
  id: z.number(),
  isExchangeGroup: z.boolean(), // ? determines whether products within this group can
  name: helperSchema.nonEmptyString,
  types: z.array(productType),
  tags: z.array(productTag),
  collections: z.array(productCollection),
});

export const storeProductMinimum = storeProduct.pick({
  id: true,
  title: true,
  idFromPlatform: true,
  imgUrl: true,
});

export const storeProductGroupCreate = storeProductGroup.omit({ id: true });
