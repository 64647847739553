import { z } from 'zod';

const option = z.object({ name: z.string(), position: z.number() });

export const productOptions = z.array(option);

// * variant exchange
export const variantExchangeOptions = z.object({
  label: z.string(),
  imageUrl: z.string().nullable(),
  idFromPlatform: z.string(),
  grams: z.number(),
  price: z.number(),
  options: z.array(z.object({ name: z.string(), value: z.string() })),
  sku: z.string().optional(),
  name: z.string().nullable(),
});

const product = z.object({
  idFromPlatform: z.string(),
  name: z.string(),
  imageUrl: z.string().nullable(),
  hasOnlyDefaultVariant: z.boolean(),
});

export const productWithOptions = product.extend({
  options: z.array(option.merge(z.object({ values: z.array(z.string()) }))),
  variants: z.array(variantExchangeOptions),
});

// * variant exchange
export const variantExchange = z.array(productWithOptions);

export const collection = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string().optional(),
});
