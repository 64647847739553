// ------------------ CREW Order Tracking ------------------ //

import { z } from 'zod';
import {
  TrackingStatusCode,
  TrackingStatusDetailCode,
} from '../../enums/tracking.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';

export const orderTrackerDetail = z.object({
  date: z.string(),
  message: z.string(),
  status: enumToZodLiteralUnion(TrackingStatusCode),
  // EASYPOST does not provide statusDetail, VESYL will.
  // statusDetail: enumToZodLiteralUnion(TrackingStatusDetailCode),
  location: z.object({
    city: z.string().nullable(),
    state: z.string().nullable(),
    country: z.string().nullable(),
    zip: z.string().nullable(),
  }),
});

export const carrier = z.object({
  iconImgUrl: z.string(),
  name: z.string(),
  trackingCode: z.string(),
  trackingUrl: z.string().nullable(),
});

export const orderTracker = z.object({
  status: enumToZodLiteralUnion(TrackingStatusCode),
  statusDetail: enumToZodLiteralUnion(TrackingStatusDetailCode),
  idFromPlatform: z.string(),
  carrier,
  estimatedDeliveryDate: z.string().nullable(),
  details: z.array(orderTrackerDetail),
});
