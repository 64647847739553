import { CrewCustomerApi } from 'corso-types';
import pRetry, { AbortError } from 'p-retry';
import { generatePath } from 'react-router-dom';
import env from '~/env';
import { determineFileType } from './file';
import { toCurrency } from './formatters';
import { identifyUser, logError } from './observability';
import { isApiError, isErrorMessage } from './schemas';
import {
  AppType,
  KindOfMonetarySettings,
  type MediaAsset,
  type Order,
  type Prettify,
} from './types';

const baseUrl = env.VITE_CREW_API_URL;

type RequestConfig<
  Path extends CrewCustomerApi.ApiPath,
  Method extends CrewCustomerApi.MethodOfPath<Path>,
> = {
  path: Path;
  method: Method;
} & (CrewCustomerApi.RequestParams<Path, Method> extends never ?
  { params?: never }
: { params: CrewCustomerApi.RequestParams<Path, Method> }) &
  (CrewCustomerApi.RequestBody<Path, Method> extends never ? { body?: never }
  : { body: CrewCustomerApi.RequestBody<Path, Method> }) &
  (CrewCustomerApi.RequestQuery<Path, Method> extends never ? { query?: never }
  : { query: CrewCustomerApi.RequestQuery<Path, Method> });

type RequestCreate<
  Path extends CrewCustomerApi.ApiPath,
  Method extends CrewCustomerApi.MethodOfPath<Path>,
> = Prettify<Pick<RequestConfig<Path, Method>, 'path' | 'method'>>;

type RequestParams<
  Path extends CrewCustomerApi.ApiPath,
  Method extends CrewCustomerApi.MethodOfPath<Path>,
> = Prettify<Pick<RequestConfig<Path, Method>, 'params' | 'query' | 'body'>>;

//! use AbortError to cancel retries. If we get a response from the server we want to abort the retry
const defaultErrorMessage =
  'Something went wrong, please try again. If problem persists, please contact us.';

const parseBody =
  <Data>(response: Response) =>
  (body: unknown) =>
    response.ok ?
      (body as Data)
    : Promise.reject(
        new AbortError(isApiError(body) ? body.message : defaultErrorMessage),
      );

export const parseResponse = <Data>(response: Response) =>
  response.status === 204 ?
    (Promise.resolve(null) as Promise<Data>) // ! Assuming API contracts for Data to be nullable when 204
  : response
      .json()
      .then(parseBody<Data>(response))
      .catch((error) =>
        // SyntaxError is thrown when the response is not valid JSON; i.e. when nothing is sent back
        Promise.reject(
          error instanceof SyntaxError ?
            new AbortError(defaultErrorMessage)
          : error,
        ),
      );
export const request =
  <
    Path extends CrewCustomerApi.ApiPath,
    Method extends CrewCustomerApi.MethodOfPath<Path>,
  >(
    { path, method }: RequestCreate<Path, Method>,
    fetchInit?: Prettify<Omit<RequestInit, 'method' | 'body'>>,
  ) =>
  ({ params, query, body }: RequestParams<Path, Method>) => {
    const { headers, ...init } = fetchInit ?? {};

    const defaultHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const url = new URL(`${baseUrl}${generatePath(path, params)}`);

    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        if (value) {
          // ! this coercion is not safe, but query params should must always be interchanged as string values
          url.searchParams.append(key, `${String(value).trim()}`);
        }
      });
    }

    return pRetry(
      () =>
        fetch(url.toString(), {
          method,
          body: JSON.stringify(body),
          headers: { ...defaultHeaders, ...headers },
          ...init,
        }).then(parseResponse<CrewCustomerApi.ResponseBody<Path, Method>>),
      {
        retries: 2,
      },
    );
  };

const fetchCrewOrder = request({
  path: '/:storefrontId/order',
  method: 'get',
});

// TODO combine with fetchProtectionOrder -- the types were no happy when trying it before downstream consumers couldn't infer the type
const processCrewOrder = (
  order: Awaited<ReturnType<typeof fetchCrewOrder>>,
) => {
  const { email, orderNo, idFromPlatform, currencyCode, storeId, storeName } =
    order;

  identifyUser({
    email,
    storeName,
    orderNo,
    orderIdFromPlatform: idFromPlatform,
    storeId,
    appType: AppType.crew,
  });

  const formatPrice = toCurrency(currencyCode);

  return {
    ...order,
    lineItems: order.lineItems.map((item) => ({
      ...item,
      unitPriceDisplay: formatPrice(item.unitPrice),
      remainingReturnableQuantity: item.currentQuantity,
    })),
  };
};

const fetchProtectionOrder = request({
  path: '/protection/order',
  method: 'get',
});

const processProtectionOrder = (
  order: Awaited<ReturnType<typeof fetchProtectionOrder>>,
) => {
  const { email, orderNo, idFromPlatform, storeId, storeName, currencyCode } =
    order;

  identifyUser({
    email,
    orderNo,
    orderIdFromPlatform: idFromPlatform,
    storeId,
    storeName,
    appType: AppType.gsp,
  });

  const formatPrice = toCurrency(currencyCode);

  return {
    ...order,
    lineItems: order.lineItems.map((item) => ({
      ...item,
      unitPriceDisplay: formatPrice(item.unitPrice),
    })),
  } satisfies Order;
};

const fetchSignedUrl = request({
  path: '/:storefrontId/signed-url',
  method: 'get',
});

const uploadToCDN =
  (file: File) => (signedUrl: Awaited<ReturnType<typeof fetchSignedUrl>>) =>
    pRetry(
      () =>
        fetch(signedUrl.signedUploadUrl, {
          method: 'PUT',
          body: file,
          // Presigned URLs must have this header set when using AWS S3 client on DigitalOcean @see https://stackoverflow.com/questions/66555915/cannot-upload-files-with-acl-public-read-to-digital-ocean-spaces
          headers: { 'x-amz-acl': 'public-read', 'Content-Type': file.type },
        }).then((resp) => {
          if (resp.ok) {
            return {
              name: signedUrl.uploadFilename,
              src: signedUrl.url,
              type: determineFileType(file),
            } satisfies MediaAsset;
          }

          logError(new Error('CDN file upload error'), {
            extra: {
              fileName: signedUrl.uploadFilename,
              status: resp.status,
              statusText: resp.statusText,
            },
          });

          return Promise.reject(new AbortError(file.name));
        }),
      { retries: 1 },
    );

const uploadFiles = ({
  params,
  query,
  body,
}: {
  params: Parameters<typeof fetchSignedUrl>[number]['params'];
  query: Prettify<
    Pick<Parameters<typeof fetchSignedUrl>[number]['query'], 'destination'>
  >;
  body: File[];
}) =>
  Promise.allSettled(
    body.map((file) =>
      fetchSignedUrl({
        params,
        query: {
          ...query,
          filename: file.name,
          contentType: file.type,
        },
      })
        .then(uploadToCDN(file))
        .catch(() => Promise.reject(new Error(file.name))),
    ),
  ).then((uploads) =>
    uploads.reduce(
      (results, upload) => {
        if (upload.status === 'rejected') {
          const error =
            isErrorMessage(upload.reason) ?
              upload.reason.message
            : 'File upload failed.';

          results.errors.push(error);
        } else {
          results.assets.push(upload.value);
        }

        return results;
      },
      {
        errors: [] as string[],
        assets: [] as MediaAsset[],
      },
    ),
  );

const checkBeforeClaimSubmissionRequest = request({
  path: '/:storefrontId/automations/before-claim-submission',
  method: 'post',
});

export default {
  fetchSettings: request({
    path: '/settings/:slug',
    method: 'get',
  }),

  fetchRegistrationProducts: request({
    path: '/:storefrontId/registration/product',
    method: 'get',
  }),

  fetchCrewOrder: (
    params: Prettify<Parameters<typeof fetchCrewOrder>[number]>,
  ) => fetchCrewOrder(params).then(processCrewOrder),

  fetchProductVariantInfo: request({
    path: '/:storefrontId/product/:idFromPlatform',
    method: 'get',
  }),

  fetchCrewClaim: request({
    path: '/:storefrontId/claim/:claimExternalId',
    method: 'get',
  }),

  createCrewClaim: request({
    path: '/:storefrontId/claim',
    method: 'post',
  }),

  createRegistration: request({
    path: '/:storefrontId/registration',
    method: 'post',
  }),

  createShipmentMethodsQuote: request({
    path: '/:storefrontId/return-shipment/quote',
    method: 'post',
  }),

  createShipment: request({
    path: '/:storefrontId/return-shipment',
    method: 'post',
  }),

  createPaymentIntent: request({
    path: '/:storefrontId/create-payment-intent',
    method: 'post',
  }),

  fetchProtectionOrder: (
    params: Prettify<Parameters<typeof fetchProtectionOrder>[number]>,
  ) => fetchProtectionOrder(params).then(processProtectionOrder),

  createProtectionClaim: request({
    path: '/:storefrontId/shipping-protection/claim',
    method: 'post',
  }),

  uploadFiles,

  // * business hooks

  checkBeforeClaimSubmission: <
    Args extends Parameters<typeof checkBeforeClaimSubmissionRequest>[number],
    Kind extends Args['body']['kind'],
  >(
    args: Args,
  ) =>
    checkBeforeClaimSubmissionRequest(args) as Promise<
      KindOfMonetarySettings<Kind>
    >,

  checkAfterReasonSelection: request({
    path: '/:storefrontId/automations/after-reason-selection',
    method: 'get',
  }),

  confirmAfterReasonSelection: request({
    path: '/:storefrontId/automations/after-reason-selection',
    method: 'post',
  }),
};
