import { Form } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Field, FieldLabel, FieldMessage } from '~/components/field';

import FileUploader from '~/components/file-uploader';
import Input from '~/components/input';

import Page from '~/components/page';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/select';
import { useIsLoading } from '~/utils/routing';
import { FormKeys, useRegisterActionResult } from './register-action';
import { useRegisterData } from './register-loader';

export default function Register() {
  const {
    storeSalesChannels,
    shouldCollectProofOfPurchase,
    fields,
    email,
    proofOfPurchaseAssets,
    estimatedPurchaseDate,
    storeSalesChannel,
  } = useRegisterData();

  const isLoading = useIsLoading();

  const requiredMediaCount = Number(shouldCollectProofOfPurchase);

  const actionResult = useRegisterActionResult();
  const hasErrors = actionResult && !actionResult.ok;

  const showAlert = hasErrors && actionResult.message;
  const showFieldMessage = hasErrors && !actionResult.message;

  return (
    <Page headline={`Let's grab some quick info`}>
      <Card>
        <Form
          method="post"
          className="flex flex-col gap-6"
          encType="multipart/form-data"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Field className="sm:col-span-2">
              <FieldLabel htmlFor="email" className="sm:col-span-2">
                Email Address <span aria-hidden>*</span>
              </FieldLabel>
              <Input
                aria-describedby="email-field-message"
                className="peer/email"
                id="email"
                name={FormKeys.Email}
                placeholder="carl@corso.com"
                required
                type="email"
                defaultValue={email}
              />
              <FieldMessage
                id="email-field-message"
                aria-live="assertive"
                className="peer-valid/email:hidden peer-invalid/email:visible peer-invalid/email:text-error peer-placeholder-shown/email:peer-invalid/email:hidden"
              >
                Invalid email address
              </FieldMessage>
            </Field>

            {fields.map((field) => (
              <Field
                key={field.name}
                className={twMerge(field.name === 'line1' && 'sm:col-span-2')}
              >
                <FieldLabel htmlFor={field.name}>
                  {field.label}
                  {field.required && <span aria-hidden>*</span>}
                </FieldLabel>
                <Input
                  type={field.type}
                  required={field.required}
                  id={field.name}
                  name={field.name}
                  placeholder={field.placeholder ?? field.label}
                  autoComplete={field.autoComplete}
                  defaultValue={field.defaultValue}
                  pattern={field.pattern}
                  onInvalid={({ currentTarget }) =>
                    field.invalidMessage &&
                    currentTarget.setCustomValidity(field.invalidMessage)
                  }
                  onInput={({ currentTarget }) =>
                    currentTarget.setCustomValidity('')
                  }
                  title={field.invalidMessage}
                />
              </Field>
            ))}

            <Field>
              <FieldLabel htmlFor="estimated-purchase-date">
                Estimated Purchase Date <span aria-hidden>*</span>
              </FieldLabel>
              <Input
                type="date"
                name={FormKeys.EstimatedPurchaseDate}
                required
                id="estimated-purchase-date"
                defaultValue={estimatedPurchaseDate}
              />
            </Field>

            <Field>
              <FieldLabel htmlFor="purchase-location">
                Original Purchase Location <span aria-hidden>*</span>
              </FieldLabel>
              <Select
                name={FormKeys.StoreSalesChannel}
                required
                defaultValue={
                  storeSalesChannel ? `${storeSalesChannel.id}` : undefined
                }
              >
                <SelectTrigger id="purchase-location">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {storeSalesChannels.map((option) => (
                    <SelectItem key={option.id} value={`${option.id}`}>
                      {option.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </Field>

            <Field className="sm:col-span-2">
              <FieldLabel>
                Proof of Purchase{' '}
                {shouldCollectProofOfPurchase && <span aria-hidden>*</span>}
              </FieldLabel>
              <FileUploader
                name={FormKeys.Assets}
                requiredCount={requiredMediaCount}
                description="Upload a receipt, invoice, or other proof of purchase."
                value={proofOfPurchaseAssets}
              />
              {showFieldMessage && (
                <FieldMessage error>{actionResult.form?.assets}</FieldMessage>
              )}
              <input
                type="hidden"
                data-testid="required-asset-count"
                name={FormKeys.RequiredAssetCount}
                value={requiredMediaCount}
              />
            </Field>
          </div>

          {showAlert && (
            <Alert variant="error" title={actionResult.message ?? ''}>
              <p>{actionResult.form?.assets}</p>
            </Alert>
          )}

          <Submit loading={isLoading}>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
