export type StringEnum<T extends string> = Record<string, T>;

export type NumberEnum<T extends number> =
  | Record<string, T | number>
  | Record<number, string>;

export type Enum<T extends string | number> =
  | (T extends string ? StringEnum<T> : never)
  | (T extends number ? NumberEnum<T> : never);

export function isEnum<T extends Enum<string | number>>(
  value: unknown,
  check: T,
): value is T[keyof T] {
  return (
    (typeof value === 'string' || typeof value === 'number') &&
    Object.values(check).includes(value)
  );
}

export const toEnumError = `cannot be converted to enum.`;

/**
 * Converts a value to a key of an enum if found within the enum; otherwise throws an error.
 * @example
 * enum Foo {
 *   bar = 'bar',
 *   baz = 'baz',
 * }
 *
 * const someValue = 'bar';
 * const asEnum = toEnum(someValue, Foo); // typeof asEnum is Foo
 * const asFallback = toEnum('quux', Foo, Foo.bar); // typeof asEnum is Foo, and will be Foo.bar
 * const asError = toEnum('quux', Foo); // throws an error
 */
// TODO find a way to prevent `convertTo` from being a `string` literal or single member of the enum
export function toEnum<T extends Enum<string | number>>(
  value: unknown,
  convertTo: T,
) {
  if (isEnum(value, convertTo)) return value;
  throw new Error(`${String(value)}: ${toEnumError}`);
}

export type MaybePromise<T> = Promise<T> | T;

type Primitive = string | number | boolean | null | undefined | symbol;

// Arrays, Sets and Maps and their readonly counterparts have their items made deeply partial, but their own instances are left untouched

type DeepPartialArray<T> =
  T extends (infer ArrayType)[] ? DeepPartial<ArrayType>[]
  : T extends readonly (infer ArrayType)[] ? readonly ArrayType[]
  : never;

type DeepPartialSet<T> =
  T extends Set<infer SetType> ? Set<DeepPartial<SetType>>
  : T extends ReadonlySet<infer SetType> ? ReadonlySet<SetType>
  : never;

type DeepPartialMap<T> =
  T extends Map<infer KeyType, infer ValueType> ?
    Map<DeepPartial<KeyType>, DeepPartial<ValueType>>
  : T extends ReadonlyMap<infer KeyType, infer ValueType> ?
    ReadonlyMap<DeepPartial<KeyType>, DeepPartial<ValueType>>
  : never;

/**
 * Derived from: https://stackoverflow.com/a/68699273
 */
export type DeepPartial<T> =
  T extends Primitive | Date ? T | undefined
  : T extends DeepPartialArray<T> ? DeepPartialArray<T>
  : T extends DeepPartialSet<T> ? DeepPartialSet<T>
  : T extends DeepPartialMap<T> ? DeepPartialMap<T>
  : // ...and finally, all other objects.
    {
      [K in keyof T]?: DeepPartial<T[K]>;
    };

/**
 * When given a a specific literal type, it unwraps it to the base type.
 * Falls back to provided type, `T`, if no unwrapped variant defined.
 * @example
 * UnwrapLiteral<true> // boolean
 * UnwrapLiteral<42> // number
 * UnwrapLiteral<'hello world'> // string
 */
export type UnwrapLiteral<T> =
  T extends number ? number
  : T extends string ? string
  : T extends boolean ? boolean
  : T;

export const isNonNullable = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined;

export type NonNullableRecord<T extends Record<string, unknown>> = {
  [K in keyof T]: NonNullable<T[K]>;
};

/**
 * For a `thing` ensures that values at the first level all exist; i.e. are not `undefined` or `null`.
 */
export function isNonNullableRecord<T extends Record<string, unknown>>(
  thing: T,
): thing is NonNullableRecord<T> {
  return Object.values(thing).every(isNonNullable);
}

/** Falsy values in condition from: https://developer.mozilla.org/en-US/docs/Glossary/Falsy */
export type Truthy<T> =
  T extends false | '' | 0 | 0n | null | undefined ? never : T;

/**
 * Truthy predicate to help TypeScript assure truthy values.
 * Often used with `filter`, but must be used directly, since the predicate return type won't propagate.
 * @example
 * const stuff = ['foo', 'bar', null]; // typeof stuff => (string | null)[]
 * // * used directly; satisfies TypeScript
 * const goodStuff = stuff.filter(isTruthy); // typeof goodStuff => string[]
 * // * used indirectly; TypeScript stays pessimistic
 * const indirectStuff = stuff.filter((thing) => isTruthy(thing)); // typeof indirectStuff => (string | null)[]
 */
export const isTruthy = <T>(value: T): value is Truthy<T> => !!value;

// eslint-disable-next-line @typescript-eslint/ban-types
export type Prettify<T> = { [K in keyof T]: T[K] } & {};

export type PutRequest<T> = Required<Omit<T, `${string}On` | 'id'>>; // this needs much better name, as it removes any `id` and date-related properties with the `On` suffix

/**
 * Given an string enum, creates a union of all of it's values.
 * @example
 * enum Foo {
 *   Bar = 'bar',
 *   Baz = 'baz',
 * }
 * type FooUnion = Unionize<Foo>; // typeof FooUnion is 'bar' | 'baz'
 */
export type Unionize<T extends string> = `${T}`;

/** Represents a tuple of `T` items that has a minimum length of `N`. */
export type Tuple<T, N extends number, Current extends T[] = []> =
  N extends 0 ? []
  : Current['length'] extends N ? [...Current, ...T[]]
  : Tuple<T, N, [...Current, T]>;

/** Verifies a given array has `length` equal to `minLength`, meaning it's a tuple of that minimum size. */
export function isTuple<T, N extends number>(
  value: readonly T[],
  minLength: N,
): value is Tuple<T, N> {
  return value.length >= minLength;
}

/**
 * An abstraction of the built-in `Array.prototype.map` method, but acts more narrowly on `readonly` arrays; i.e. helping to assure they're still tuples.
 * Useful for proving a one-to-one mapping of a known tuple to another known tuple without needing to re-assert the tuple type from a programmatic `map` call.
 */
// ? might better be suited to `corso-utils/array.ts`, but would result in a cyclical dependency
export function mapped<T extends readonly unknown[], R>(
  items: T,
  callback: (item: T[number], index: number, array: readonly T[number][]) => R,
) {
  return items.map(callback) as Tuple<R, (typeof items)['length']>;
}
