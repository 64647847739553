import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Field, FieldLabel, FieldMessage } from '~/components/field';
import Input from '~/components/input';
import Page from '~/components/page';
import { useEditAddressActionResult } from './edit-action';
import { useEditAddressData } from './edit-loader';

// TODO: Move to 2 column layout like register page
export default function EditAddress() {
  const { fields, hasAddress } = useEditAddressData();
  const actionResult = useEditAddressActionResult();

  return (
    <Page headline="Update Address">
      <Card>
        <Form method="post" className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            {Object.entries(fields).map(([key, field]) => (
              <Field key={key}>
                <FieldLabel htmlFor={key}>
                  {field.label}
                  {field.required && <span aria-hidden>*</span>}
                </FieldLabel>
                <Input
                  type={field.type}
                  required={field.required}
                  id={key}
                  name={field.name}
                  emptyIsInvalid={hasAddress}
                  placeholder={field.placeholder ?? field.label}
                  autoComplete={field.autoComplete}
                  defaultValue={field.defaultValue}
                  pattern={field.pattern}
                  onInvalid={({ currentTarget }) =>
                    field.invalidMessage &&
                    currentTarget.setCustomValidity(field.invalidMessage)
                  }
                  onInput={({ currentTarget }) =>
                    currentTarget.setCustomValidity('')
                  }
                  title={field.invalidMessage}
                />
                {actionResult?.ok === false &&
                  actionResult.form?.[field.name] && (
                    <FieldMessage error>
                      {actionResult.form[field.name]}
                    </FieldMessage>
                  )}
              </Field>
            ))}
          </div>

          <Submit>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
