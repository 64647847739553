import { getAddressFields } from '~/utils/address';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';

export type LoaderData = {
  hasAddress: boolean;
  fields: ReturnType<typeof getAddressFields>;
};

export default gspLoader(({ params: { store = '' }, context: { order } }) =>
  order ?
    json<LoaderData>({
      hasAddress: Boolean(order.shippingAddress),
      fields: getAddressFields(order.shippingAddress),
    })
  : redirect(`/${store}`),
);

export const useEditAddressData = createLoaderDataHook<LoaderData>();
