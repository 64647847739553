import { ResolutionMethod } from './types';

export const resolutionMethodLabels: Record<ResolutionMethod, string> = {
  [ResolutionMethod.refund]: 'Refund',
  [ResolutionMethod.variantExchange]: 'Exchange',
  [ResolutionMethod.giftCard]: 'Gift Card',
  [ResolutionMethod.repair]: 'Repair',
  [ResolutionMethod.replacementOrder]: 'Replacement Order',
  [ResolutionMethod.warrantyReview]: 'Warranty',
};

export const defaultProtectedIconImgUrl =
  'https://cdn.corso.com/img/corso-gsp-prod-img-sm.png';

export const defaultUnprotectedIconImgUrl =
  'https://cdn.corso.com/img/shipping-truck-icon-img.png';

export const productNotFoundImgUrl =
  'https://cdn.corso.com/img/image-not-available.jpeg';
