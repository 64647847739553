import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Field, FieldLabel, FieldMessage } from '~/components/field';
import Input from '~/components/input';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { useAddressActionResult } from './edit-address-action';
import { useAddressData } from './edit-address-loader';

export default function Address() {
  const { fields, hasAddress } = useAddressData();
  const actionResult = useAddressActionResult();
  const isLoading = useIsLoading();

  return (
    <Page headline="Update Address">
      <Card>
        <Form method="post">
          {/* // TODO Move to 2 colum layout like register page */}
          <div className="flex flex-col gap-4">
            {Object.entries(fields).map(([key, field]) => (
              <Field key={key}>
                <FieldLabel htmlFor={key}>
                  {field.label}
                  {field.required && <span aria-hidden>*</span>}
                </FieldLabel>
                <Input
                  type={field.type}
                  required={field.required}
                  id={key}
                  name={field.name}
                  className="peer"
                  emptyIsInvalid={hasAddress}
                  placeholder={field.placeholder ?? field.label}
                  autoComplete={field.autoComplete}
                  defaultValue={field.defaultValue}
                  pattern={field.pattern}
                  onInvalid={({ currentTarget }) =>
                    field.invalidMessage &&
                    currentTarget.setCustomValidity(field.invalidMessage)
                  }
                  onInput={({ currentTarget }) =>
                    currentTarget.setCustomValidity('')
                  }
                  title={field.invalidMessage}
                />
                {actionResult?.ok === false &&
                  actionResult.form?.[field.name] && (
                    <FieldMessage className="text-error">
                      {actionResult.form[field.name]}
                    </FieldMessage>
                  )}
              </Field>
            ))}
          </div>

          <Submit className="mt-6" loading={isLoading}>
            Continue
          </Submit>
        </Form>
      </Card>
    </Page>
  );
}
