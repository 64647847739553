import { z } from 'zod';
import { stripeConfig } from './settings.js';

export const paymentIntent = z.object({
  clientSecret: z.string(),
});

export const paymentIntentCreate = z.object({
  amount: z.number(), // amount in dollars // API will handle conversion to cents
  currencyCode: z.string(),
  stripeConfig,
  customerEmail: z.string(),
});
