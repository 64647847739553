import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import env from '~/env';
import api from '~/utils/api';
import { PaymentIntentCreate } from '~/utils/types';

const listeners: VoidFunction[] = [];
const notifyChange = () => listeners.forEach((listener) => listener());

const createPaymentUI = (elements: StripeElements) => ({
  mount: (domId: string) => {
    const cardElement = elements.create('card');
    cardElement.mount(domId);

    return () => {
      cardElement.unmount();
      cardElement.destroy();
    };
  },
});

const Payments = {
  // don't like exposing these, but not sure how to get around it for testing
  // would be nice to use a class singleton, then TS could enforce the private state while still allowing access for testing
  stripe: null,
  clientSecret: null,
  elements: null,
  paymentUI: null,

  async createPaymentIntent({
    storefrontId,
    ...body
  }: PaymentIntentCreate & { storefrontId: string }) {
    if (!window.Stripe) {
      throw new Error('Stripe has not been loaded');
    }

    if (!Payments.stripe) {
      Payments.stripe = window.Stripe(String(env.VITE_STRIPE_PUBLISHABLE_KEY), {
        stripeAccount: body.stripeConfig.idFromPlatform,
      });
    }

    const res = await api.createPaymentIntent({
      params: { storefrontId },
      body,
    });

    Payments.elements = Payments.stripe.elements({
      clientSecret: res.clientSecret,
    });
    Payments.clientSecret = res.clientSecret;
    Payments.paymentUI = createPaymentUI(Payments.elements);

    notifyChange();

    return Payments.clientSecret;
  },

  async confirmPaymentIntent() {
    if (!Payments.stripe) {
      throw new Error('Stripe has not been initialized');
    }

    if (!Payments.clientSecret) {
      throw new Error('Payment intent not created');
    }

    const card = Payments.elements?.getElement('card');
    if (!card) {
      throw new Error('Payment card element not initialized');
    }

    const result = await Payments.stripe.confirmCardPayment(
      Payments.clientSecret,
      {
        payment_method: {
          card,
        },
      },
    );
    if (result.error) {
      throw new Error(result.error.message);
    }

    // clean up state after successful payment
    Payments.elements = null;
    Payments.clientSecret = null;
    Payments.paymentUI = null;

    notifyChange();

    return result.paymentIntent.id;
  },

  subscribe(listener: VoidFunction) {
    listeners.push(listener);
    return () => {
      const index = listeners.indexOf(listener);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  },

  getSnapshot() {
    return Payments.paymentUI;
  },
} as {
  stripe: Stripe | null;
  elements: StripeElements | null;
  clientSecret: string | null;
  paymentUI: ReturnType<typeof createPaymentUI> | null;
  createPaymentIntent(
    args: PaymentIntentCreate & { storefrontId: string },
  ): Promise<string>;
  createCardElement(): StripeCardElement;
  confirmPaymentIntent(): Promise<string>;
  mountCardElement(elementId: string): VoidFunction;
  subscribe(listener: VoidFunction): VoidFunction;
  getSnapshot(): ReturnType<typeof createPaymentUI> | null;
};

export default Payments;
