import { Form, useNavigation } from 'react-router-dom';
import Alert from '~/components/alert';
import Badge from '~/components/badge';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { ResolutionMethod } from '~/utils/types';
import {
  FIELD_KEY,
  useRefundOptionsActionResult,
} from './refund-options-action';
import { useRefundOptionsData } from './refund-options-loader';

export default function RefundOptions() {
  const {
    isGiftCardEnabled,
    isRefundEnabled,
    hasIncentive,
    incentive,
    refund,
    total,
  } = useRefundOptionsData();
  const actionResult = useRefundOptionsActionResult();
  const isLoading = useIsLoading();
  const navigation = useNavigation();

  const giftCardContent = `Get a gift card for ${refund}${
    hasIncentive ? ` plus ${incentive} extra!` : '.'
  } A redeemable code will be sent via email once your return has been approved.`;

  return (
    <Page headline="How would you like your money back?">
      {isGiftCardEnabled && (
        <Card
          headline={`Gift card ${hasIncentive ? 'with bonus' : ''}`}
          headerContent={<Badge variant="success">Best value</Badge>}
        >
          <p className="text-gray-500">{giftCardContent}</p>
          <Form method="put">
            <input
              type="hidden"
              hidden
              name={FIELD_KEY}
              value={ResolutionMethod.giftCard}
            />
            <Submit
              loading={
                isLoading &&
                navigation.formData?.get(FIELD_KEY) ===
                  ResolutionMethod.giftCard
              }
            >
              Gift Card ({total})
            </Submit>
          </Form>
        </Card>
      )}
      {isRefundEnabled && (
        <Card
          headline="Original payment method"
          headerContent={<Badge variant="danger">Fees may apply</Badge>}
        >
          <p className="text-gray-500">
            Disclaimer: Receive a refund of {refund} to your original payment
            method once your return has been approved. If you choose this
            method, the final amount may be subject to a return handling fee.
            The handling fee amount will be calculated for your review before
            you submit your request.
          </p>
          <Form method="put">
            <input
              type="hidden"
              hidden
              name={FIELD_KEY}
              value={ResolutionMethod.refund}
            />
            <Submit
              variant="outlined"
              loading={
                isLoading &&
                navigation.formData?.get(FIELD_KEY) === ResolutionMethod.refund
              }
            >
              Original Payment Method ({refund})
            </Submit>
          </Form>
        </Card>
      )}

      {actionResult && !actionResult.ok && (
        <Alert variant="error" title="Problem submitting refund option">
          <p>{actionResult.form?.resolution}</p>
        </Alert>
      )}
    </Page>
  );
}
