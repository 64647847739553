//* a claim address is what the customer actually inputs on the crew-ui during claim creation

import { z } from 'zod';
import { helperSchema } from '../../zodExtensions.js';

// todo: make this 2 or 3
const stateOrProvinceCode = z
  .string()
  .min(2, { message: 'State or Province code should be at least 2 letters' })
  .max(3, {
    message: 'State or Province code should be no more than 3 letters',
  })
  .nullish(); // two letter state code // field in Address is expected to be of format ISO 3166

// * order address
export const orderAddress = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  line1: z.string().optional(),
  line2: z.string().optional(),
  city: z.string().optional(),
  countryCode: z
    .string()
    .length(2, { message: 'Country code should a be two letters' })
    .optional(), // two letter country code ISO 3166
  // ? renamed from provinceCode
  stateOrProvinceCode,
  // ? renamed from zip
  postalCode: z.string().optional(),
  phone: z.string().optional(),
});

//* may be different from the original order address // especially if the customer has moved since the order was placed
export const claimAddress = orderAddress.required().merge(
  z.object({
    line2: z.string().nullish(),
    phone: z.string().nullish(),
    stateOrProvinceCode,
  }),
);

export const locationAddress = claimAddress
  .omit({
    firstName: true,
    lastName: true,
  })
  .merge(z.object({ name: helperSchema.nonEmptyString }));
