import { crewClaimResolutionMethodEnumName } from 'corso-types/enums/crew';
import { useMemo } from 'react';
import {
  InspectionMedia,
  ResolutionMethod,
  RollupStatusCode,
} from '~/utils/types';
import Chip from './chip';
import MediaAsset from './media-asset';
import ProductImage from './product-image';

type Props = {
  price: string;
  quantity: number;
  status?: string;
  statusDetail?: string;
  name?: string | null;
  imgUrl?: string | null;
  className?: string;
  rollupStatus?: `${RollupStatusCode}`;
  requestedResolution: `${ResolutionMethod}`;
  claimLineItemNoteToCustomer?: string;
  inspectionMedia: InspectionMedia[];
};
export default function ClaimLineItem({
  name,
  price,
  imgUrl,
  quantity,
  status,
  statusDetail,
  className,
  requestedResolution,
  rollupStatus,
  claimLineItemNoteToCustomer,
  inspectionMedia,
}: Props) {
  const mediaAssets = useMemo(
    () => inspectionMedia.filter((asset) => asset.isVisibleToCustomer),
    [inspectionMedia],
  );

  return (
    <article className={className}>
      <div className="flex gap-4">
        <ProductImage
          variant="small"
          name={name}
          src={imgUrl}
          quantity={quantity}
        />
        <div className="flex w-full flex-col gap-1 text-sm font-medium text-gray-500">
          <div className="flex w-full justify-between text-gray-800">
            <span>{name}</span>
            <span className={`${status === 'Denied' ? 'line-through' : ''}`}>
              {price}
            </span>
          </div>
          <div className="flex gap-1">
            <Chip>
              Request: {crewClaimResolutionMethodEnumName[requestedResolution]}
            </Chip>
            {status && rollupStatus === 'Completed' && (
              <Chip>Status: {status}</Chip>
            )}
            {statusDetail && <Chip>{statusDetail}</Chip>}
          </div>

          {claimLineItemNoteToCustomer && (
            <p className="my-3 text-xs">
              <span className="font-bold">Note: </span>
              <span>
                {claimLineItemNoteToCustomer}
                {mediaAssets.length > 0 && ' See media below.'}
              </span>
            </p>
          )}
          {mediaAssets.length > 0 && (
            <div className="grid grid-cols-2 items-center gap-2 sm:grid-cols-3">
              {mediaAssets.map(({ url, id }) => (
                <MediaAsset key={id} src={url} />
              ))}
            </div>
          )}
        </div>
      </div>
    </article>
  );
}
