import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Field, FieldLabel, FieldMessage } from '~/components/field';

import FileUploader from '~/components/file-uploader';
import Page from '~/components/page';
import TextArea from '~/components/textarea';
import { useIsLoading } from '~/utils/routing';
import { useMetaActionResult } from './meta-action';
import { useMetaLoaderData } from './meta-loader';

export default function ReorderMeta() {
  const { uploadImage, ...values } = useMetaLoaderData();
  const actionResult = useMetaActionResult();
  const isLoading = useIsLoading();

  return (
    <Page headline="Anything else you can tell us?">
      <Card>
        <Form
          method="POST"
          encType="multipart/form-data"
          className="flex flex-col gap-6"
        >
          <Field>
            <FieldLabel htmlFor="noteFromCustomer" className="sr-only">
              Additional comments
            </FieldLabel>
            <TextArea
              id="noteFromCustomer"
              name="noteFromCustomer"
              defaultValue={values.noteFromCustomer}
            />
          </Field>
          {uploadImage && (
            <FileUploader
              accept={['image/jpeg', 'image/png', 'image/webp']}
              name="assets"
              value={values.assets ?? []}
              description="Please upload clear images indicating damaged items."
            />
          )}

          {actionResult?.ok === false && (
            <FieldMessage error>{actionResult.message}</FieldMessage>
          )}
          <Submit loading={isLoading}>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
