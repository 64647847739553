import {
  AppTypeEnum as AppType,
  CrewClaimReasonCategoryCode as ClaimReasonCategoryCode,
  CrewClaimTypeEnum as ClaimType,
  CrewCustomerApi,
  CrewUi,
  ClaimReasonEnum as GspClaimReason,
  CrewIncentiveTypeEnum as IncentiveType,
  Pegasus,
  Prettify,
  CrewClaimResolutionMethodEnum as ResolutionMethod,
  CrewReturnShipmentTypeEnum as ReturnShipmentType,
  CrewClaimRollupStatusCode as RollupStatusCode,
  ShipmentQuoteError,
  CrewShippingDirectionEnum as ShippingDirection,
  isEnum,
  isTruthy,
  toEnum,
} from 'corso-types';
import { z } from 'zod';
import {
  crewOrderLineItemSchema,
  crewOrderSchema,
  draftLineItemClaimSchema,
  draftRegistrationSchema,
  giftCardClaimSchema,
  lineItemClaimSchema,
  mediaAssetSchema,
  refundClaimSchema,
  storeSalesChannelSchema,
  variantExchangeClaimSchema,
  warrantyClaimSchema,
} from './schemas';

export const CORSO_URL = 'https://corso.com';
const BusinessHooks = Pegasus.Hook;

export {
  AppType,
  BusinessHooks,
  ClaimReasonCategoryCode,
  ClaimType,
  GspClaimReason,
  IncentiveType,
  ResolutionMethod,
  ReturnShipmentType,
  RollupStatusCode,
  ShipmentQuoteError,
  ShippingDirection,
  isEnum,
  isTruthy,
  toEnum,
};
export type { Prettify };
export type SalesChannels =
  CrewUi.Settings['registrations']['storeSalesChannels'];
export type PaymentIntent = Record<string, unknown> & { id: string };
export type PaymentIntentResponse = CrewUi.PaymentIntent;
export type PaymentIntentCreate = CrewUi.PaymentIntentCreate;
export type Fulfillment = CrewUi.CrewOrder['fulfillments'][number];
export type OrderTracker = CrewUi.OrderTracker;
export type OrderTrackerDetail = CrewUi.OrderTrackerDetail;
export type Settings = CrewUi.Settings;
export type MonetarySettings = CrewUi.MonetarySettings;
export type MonetarySettingsBody = CrewCustomerApi.RequestBody<
  '/:storefrontId/automations/before-claim-submission',
  'post'
>;
export type KindOfMonetarySettings<Kind extends MonetarySettings['kind']> = {
  estimateIncentive: CrewUi.MonetarySettingsEstimate;
  beforeClaimSubmissionReturn: CrewUi.MonetarySettingsBeforeClaimSubmissionReturn;
  beforeClaimSubmissionWarranty: CrewUi.MonetarySettingsBeforeClaimSubmissionWarranty;
}[Kind];

export type OrderQuery = CrewCustomerApi.StoreIdOrderQuery;
export type CrewOrderLineItem = z.infer<typeof crewOrderLineItemSchema>;
export type CrewOrder = z.infer<typeof crewOrderSchema>;
export type CrewOrderResponse = CrewUi.CrewOrder;
export type InspectionMedia = CrewUi.ClaimLineItemInspection['images'][number];
export type Address = CrewUi.ClaimAddress;
export type OrderAddress = CrewUi.OrderAddress;
export type Claim = CrewUi.Claim;

export type ClaimLineItem = CrewUi.ClaimLineItem;
export type ClaimReason = CrewUi.ClaimReasons;
export type ClaimReasonDetails = ClaimReason['claimReasonDetails'][number];
export type VariantExchange = CrewUi.VariantExchange;
export type Variant = CrewUi.VariantExchangeOptions;
export type ApiPath = CrewCustomerApi.ApiPath;

export type AfterReasonSelectionEvent = CrewCustomerApi.ResponseBody<
  '/:storefrontId/automations/after-reason-selection',
  'get'
>;
export type SelectCustomField = CrewUi.CustomFieldSelect;
export type CustomField = CrewUi.CustomFieldGeneral;
export type MediaAsset = z.infer<typeof mediaAssetSchema>;

export type DraftLineItemClaim = z.infer<typeof draftLineItemClaimSchema>;
export type LineItemClaim = z.infer<typeof lineItemClaimSchema>;
export type GiftCardClaim = z.infer<typeof giftCardClaimSchema>;
export type RefundClaim = z.infer<typeof refundClaimSchema>;
export type VariantExchangeClaim = z.infer<typeof variantExchangeClaimSchema>;
export type WarrantyClaim = z.infer<typeof warrantyClaimSchema>;

export type ClaimCreate = CrewUi.ClaimCreate;
export type ClaimLineItemCreate = CrewUi.ClaimLineItemCreate;
export type SignedUrl = CrewUi.SignedUrl;
export type SignedUrlQuery = CrewCustomerApi.SignedUrlQuery;

export const toIncentiveType = (type: string) => toEnum(type, IncentiveType);

export type ShipmentQuote = CrewUi.ShipmentQuote;
export type ShipmentMethod = CrewUi.ShipmentMethod;
export type ShipmentRate = CrewUi.ShipmentRate;
export type ShipmentQuoteBody = CrewCustomerApi.ShipmentQuoteBody;
export type ShipmentCreate = CrewUi.ShipmentCreate;
export type Shipment = CrewUi.Shipment;
export type Registration = CrewUi.Registration;
export type RegistrationCreate = CrewUi.RegistrationCreate;
export type RegistrationProduct = z.infer<
  typeof CrewUi.registrationProductSchema
>;

export type StoreSalesChannel = z.infer<typeof storeSalesChannelSchema>;

export type DraftRegistration = z.infer<typeof draftRegistrationSchema>;

export type RegistrationState = {
  settings: Settings;
  draftRegistration: DraftRegistration | null;
  registration: Registration | null;
};

export type RegistrationContext = Readonly<
  Prettify<
    RegistrationState & {
      setDraftRegistration: (
        registration: DraftRegistration,
      ) => DraftRegistration;
      clearDraftRegistration: () => void;
      setRegistration: (registration: Registration) => Registration;

      init: (settings: Settings) => void;
    }
  >
>;

export type CrewState = {
  settings: Settings;
  claim: Claim | null;
  draftClaim: DraftLineItemClaim | null;
  lineItemClaims: LineItemClaim[];
  order: CrewOrder | null;
  address: Address | null;
  toCustomerRate: ShipmentRate | null;
  returnMethod: ShipmentMethod | null;
};

export type CrewContext = Readonly<
  Prettify<
    Omit<CrewState, 'draftClaims'> & {
      lineItemClaims: LineItemClaim[];
      setOrder: (order: CrewOrder | null) => CrewOrder | null;
      setAddress: (address: Address) => Address;
      setClaim: (claim: Claim) => Claim;
      setDraftClaim: (claim: DraftLineItemClaim) => DraftLineItemClaim;
      clearDraftClaim: () => void;
      upsertLineItemClaim: (claim: LineItemClaim) => LineItemClaim;
      getLineItemClaim: (id: LineItemClaim['id']) => LineItemClaim | undefined;
      removeLineItemClaim: (id: LineItemClaim['id']) => boolean;
      setReturnMethod: (shipmentMethod: ShipmentMethod) => ShipmentMethod;
      setToCustomerRate: (rate: ShipmentRate) => ShipmentRate;
      init: (settings: Settings) => void;
      initAsync: (store: string) => Promise<void>;
      reset: () => void;
      dangerouslyReset: () => void; //! for testing only
      clearLineItemClaims: () => void;
    }
  >
>;

export type ActionOkResult = { ok: true };
export type ActionErrorResult<TFormKeys extends string = ''> = {
  ok: false;
  message?: string;
  form?: Partial<Record<TFormKeys, string>>;
};
export type ActionResult<TFormKeys extends string = ''> =
  | ActionOkResult
  | ActionErrorResult<TFormKeys>;

export type FormattedValue = { value: number; display: string };

export enum ExchangeMethod {
  standard = 'standard',
  instant = 'instant',
}

export type Order = CrewUi.GspOrder;

export type ProtectionClaim = CrewUi.ShippingClaim;
export type GspClaimCreate = CrewUi.ShippingClaimCreateCustomer;

export type GspState = Prettify<{
  order: Order | null;
  reason: `${GspClaimReason}` | null;
  itemIds: Order['lineItems'][number]['id'][] | null;
  meta: {
    noteFromCustomer?: ProtectionClaim['noteFromCustomer'];
    assets?: MediaAsset[];
  } | null;
}>;

export type GspContext = Prettify<
  GspState & {
    reset: () => void;
    setOrder: (
      order: NonNullable<GspState['order']>,
    ) => NonNullable<GspState['order']>;
    setReason: (
      reason: NonNullable<GspState['reason']>,
    ) => NonNullable<GspState['reason']>;
    setItemIds: (
      items: NonNullable<GspState['itemIds']>,
    ) => NonNullable<GspState['itemIds']>;
    setMeta: (
      meta: NonNullable<GspState['meta']>,
    ) => NonNullable<GspState['meta']>;
    clearOrder: () => void;
    clearMeta: () => void;
    clearReason: () => void;
    clearItemIds: () => void;
  }
>;
