import {
  createLoaderDataHook,
  crewLoader,
  error,
  json,
  withDraftClaim,
} from '~/utils/routing';

export type ShippingProtectionData = {
  reasonId: number;
  shippingProtectionUrl: string;
};

export default crewLoader(
  withDraftClaim(({ claim, context, params: { store, idFromPlatform } }) => {
    if (!context.order?.wasShippingProtected) {
      return error(new Error('Order does not have shipping protection'));
    }

    if (!claim.reason) {
      return error(new Error('Context Error: Missing claim reason'));
    }

    return json<ShippingProtectionData>({
      reasonId: claim.reason.id,
      shippingProtectionUrl: `/${store}/reorder/${idFromPlatform ?? ''}`,
    });
  }),
);

export const useShippingProtectionData =
  createLoaderDataHook<ShippingProtectionData>();
