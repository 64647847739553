import { z } from 'zod';
import {
  ClaimReasonEnum,
  ClaimResolutionMethodEnum,
  ClaimStatusEnum,
} from '../../enums/gsp.js';
import { frontClaimConversation } from '../../front.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';
import { claimAddress } from './address.js';
import { shippingClaimResolutionSummary } from './claim.js';

const storeOrderClaim = z.object({
  id: z.number(),
  idFromPlatform: z.string(),
  currencyCode: z.string(),
  createdOn: z.string(),
  customerCurrencyConversionRate: z.number().optional(),
  platformAdminUrl: z.string().optional(),
  orderNo: z.string(),
});

export const shippingClaimFinalize = z.object({
  resolutionMethod: enumToZodLiteralUnion(ClaimResolutionMethodEnum),
});

const newClaimSource = z.union([
  z.literal('Customer_App'),
  z.literal('Merchant_App'),
  z.literal('Retool'),
  z.literal('Gorgias_Ticket_Widget'),
  z.literal('Zowie_Inbox_Integration'),
  z.literal('Gladly_Hero_Integration'),
]);

export const shippingClaimMerchant = z.object({
  id: z.number(),
  createdOn: z.string(),
  noteFromCustomer: z.string().optional(),
  noteFromMerchant: z.string().optional(),
  linkToCustomerUi: z.string().optional(),
  resolutionSummary: shippingClaimResolutionSummary.optional(),
  emailConversation: frontClaimConversation.nullable(),
  resolvedOn: z.string().nullable(),

  resolvedIn: newClaimSource.optional(),
  source: newClaimSource.optional(),
  reason: enumToZodLiteralUnion(ClaimReasonEnum),
  status: enumToZodLiteralUnion(ClaimStatusEnum),
  resolutionMethod: enumToZodLiteralUnion(ClaimResolutionMethodEnum),
  shippingAddress: claimAddress.optional(),
  images: z.array(z.string()).optional(),

  // ? in the shipping claim sense this represents the original order and the claimed lineItems
  originalStoreOrder: storeOrderClaim,
  customerName: z.string().nullable(),
  customerEmail: z.string().nullable(),

  shippingClaimLineItems: z.array(
    z.object({
      id: z.number(),
      quantity: z.number(),
      originalStoreOrderLineItem: z.object({
        sku: z.string().nullable(),
        name: z.string().nullable(),
        imgUrl: z.string().nullish(),
        quantity: z.number().nullable(),
        optionsFromPlatform: z.array(
          z.object({ name: z.string(), value: z.string() }),
        ),
      }),
    }),
  ),
});

// todo: merge these types a little better
// shippingClaim used by customer UI (much less complicated than merchant UI)
export const shippingClaimCustomerUi = z.object({
  id: z.number(),
  createdOn: z.string(),
  noteFromCustomer: z.string().optional(),
  reason: enumToZodLiteralUnion(ClaimReasonEnum),
  status: enumToZodLiteralUnion(ClaimStatusEnum),
  shippingAddress: claimAddress.optional(),
  originalStoreOrder: z.object({
    id: z.number(),
    lineItems: z.array(
      z.object({
        id: z.number(),
        quantity: z.number(),
      }),
    ),
  }),
});

export const shippingClaimCreateCustomer = shippingClaimCustomerUi
  .pick({
    reason: true,
    noteFromCustomer: true,
    originalStoreOrder: true,
    shippingAddress: true,
  })
  .merge(
    z.object({
      images: z.array(z.string()).optional(),
    }),
  );

export const shippingClaimCreateMerchant = shippingClaimCustomerUi
  .pick({
    reason: true,
    noteFromCustomer: true,
    shippingAddress: true,
    originalStoreOrder: true,
  })
  .merge(
    z.object({
      resolutionMethod: enumToZodLiteralUnion(ClaimResolutionMethodEnum),
      notifyCustomer: z.boolean().optional(),
      noteFromMerchant: z.string().optional(),
    }),
  );

const shippingClaimListItem = shippingClaimMerchant
  .pick({
    id: true,
    status: true,
    reason: true,
    resolutionMethod: true,
    createdOn: true,
  })
  .merge(
    z.object({
      customerEmail: z.string().nullable(),
    }),
  );

export const shippingClaimList = z.array(shippingClaimListItem);
