import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { FieldMessage } from '~/components/field';
import Page from '~/components/page';
import RadioGroup, { ButtonOption } from '~/components/radio-group';
import { useReasonActionData } from './reason-action';
import { useReasonLoaderData } from './reason-loader';

export default function Reason() {
  const { reasons, selectedReason } = useReasonLoaderData();
  const actionResult = useReasonActionData();

  return (
    <Page
      headline="What Happened?"
      title="Tell us what happened to your package."
    >
      <Card>
        <Form method="POST" className="flex flex-col gap-6">
          <RadioGroup label="Reorder reasons" showLabel={false}>
            {reasons.map(({ value, label }) => (
              <ButtonOption
                key={value}
                value={value}
                name="reason"
                required
                defaultChecked={value === selectedReason}
              >
                {label}
              </ButtonOption>
            ))}
          </RadioGroup>

          {actionResult?.ok === false && (
            <FieldMessage error>{actionResult.message}</FieldMessage>
          )}
          <Submit>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
