import { z } from 'zod';
import { zfd } from 'zod-form-data';
import {
  createActionResultHook,
  error,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { ActionResult, GspClaimReason } from '~/utils/types';

const schema = zfd.formData({
  reason: zfd.text(z.nativeEnum(GspClaimReason)),
});

export default gspAction(({ context, formData }) => {
  if (!context.order) {
    return error(new Error('Context Error: Order not found'));
  }

  const result = schema.safeParse(formData);

  if (!result.success) {
    return json<ActionResult>({
      ok: false,
      message: 'Please choose one of the reasons.',
    });
  }

  context.setReason(result.data.reason);

  return redirect(`${result.data.reason.toLocaleLowerCase()}/items`);
});

export const useReasonActionData = createActionResultHook<ActionResult>();
