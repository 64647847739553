import { z } from 'zod';
import { InspectionGradeCode } from '../../enums/crew.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';

export const storeInspectionGrade = z.object({
  id: z.number(), // id on store inspection classification
  name: z.string(), // name on store inspection classification

  // code on the inspection classification
  inspectionGradeCode: enumToZodLiteralUnion(InspectionGradeCode),
});

export const storeInspectionGradeCreate = storeInspectionGrade.pick({
  id: true,
});
