import { getClaimReasons } from '~/utils/claim-line-items';
import {
  createLoaderDataHook,
  crewLoader,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import type { DraftLineItemClaim } from '~/utils/types';

export type ClaimReasonDetailData = {
  reasons: { id: number; name: string }[];
  claim: DraftLineItemClaim;
};

export default crewLoader(
  withDraftClaim(({ params, claim }) => {
    const reasonId = Number.parseInt(String(params.reasonId), 10);
    const reason = getClaimReasons(claim).find(({ id }) => id === reasonId);

    if (!reason) {
      return error(
        new Error(
          'Invalid reason ID: does not match any claim reasons for claim type',
          { cause: { params } },
        ),
      );
    }

    const reasons = reason.claimReasonDetails.map(({ id, name }) => ({
      name,
      id,
    }));

    // if there are no detail reasons, skip this step
    if (!reasons.length) {
      return redirect('../follow-up');
    }

    return json<ClaimReasonDetailData>({ reasons, claim });
  }),
);

export const useReasonDetailData =
  createLoaderDataHook<ClaimReasonDetailData>();
