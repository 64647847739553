import { z } from 'zod';
import { ClaimReasonEnum } from '../../enums/gsp.js';
import { orderAddress } from './address.js';
import { claim } from './claim.js';
import { claimReason } from './claimReasons.js';
import { storeOrderLineItem } from './lineItem.js';
import { Pegasus } from './pegasus.js';
import { shippingClaimCustomerUi } from './shippingClaim.js';
import { orderTracker } from './tracking.js';

// ----------- this file contains types related to two types of orders GSP + CREW, determined by what type of claim they will ultimately create ---------------

export const crewOrderLineItem = storeOrderLineItem.merge(
  z.object({
    return: z.object({
      claimReasons: z.array(claimReason),
      isFinalSale: z.boolean(),
      isExchangeOnly: z.boolean(),
      exchange: z.object({
        lastEligibleDate: z.coerce.date().nullish(),
        isEligible: z.boolean(),
      }),
      giftCard: z.object({
        lastEligibleDate: z.coerce.date().nullish(),
        isEligible: z.boolean(),
      }),
      refund: z.object({
        lastEligibleDate: z.coerce.date().nullish(),
        isEligible: z.boolean(),
      }),
    }),
    warranty: z.object({
      claimReasons: z.array(claimReason),
      isEligible: z.boolean(),
      lastEligibleDate: z.coerce.date().nullish(),
    }),
  }),
);

const trackerWithLineItems = orderTracker.merge(
  z.object({ lineItemsInShipment: crewOrderLineItem.array() }),
);

export const orderFulfillment = z.object({
  id: z.number(),
  isFulfilled: z.boolean(),
  destinationAddress: orderAddress.nullable(),
  tracker: trackerWithLineItems.optional(),
});

// order to create a GSP claim
export const storeOrder = z.object({
  storeId: z.number(),
  storeSlug: z.string(),
  canceledOn: z.string().optional(),
  storeName: z.string(),
  storefrontId: z.string(),
  id: z.number(),
  idFromPlatform: z.string(),
  orderNo: z.string(),
  email: z.string(),
  currencyCode: z.string(),
  lineItems: z.array(storeOrderLineItem).min(1),
  shippingAddress: orderAddress.nullable(),
  billingAddress: orderAddress.nullable(),
  wasShippingProtected: z.boolean(),
  isUnprotectedShippingClaimPermitted: z.boolean(),
  shippingProtectionImgIconUrl: z.string().optional(),
  claims: z.array(claim).optional(), // crewClaims
  shippingClaims: z.array(shippingClaimCustomerUi).optional(), // gspClaims
  shippingClaimReasons: z.array(z.nativeEnum(ClaimReasonEnum)),
  customerCurrencyConversionRate: z.number().optional(),
  platformAdminUrl: z.string().optional(),
  wasCreatedFromRegistration: z.boolean(),
  createdOn: z.string(),
});

// order to create a CREW claim
export const crewOrder = storeOrder.omit({ lineItems: true }).merge(
  z.object({
    returnUntil: z.string().optional(),
    warrantyUntil: z.string().optional(),
    isOrderFulfilled: z.boolean(),
    fulfillments: orderFulfillment.array(),
    lineItems: z.array(crewOrderLineItem).min(1),
    offerInstantExchange: Pegasus.eventOfferInstantExchangeParams.optional(),
    allowClaimTypeSelection: Pegasus.eventAllowClaimTypeSelection.optional(),
  }),
);
