import { z } from 'zod';
import { DbJsonSchema } from '../../../dbJsonSchemas.js';

export const stripeConfig = z.object({
  isExtendedAuthAllowed: z.boolean(),
  idFromPlatform: z.string(), // account_id
  id: z.number(),
});

export const settings = z.object({
  storeId: z.number(),
  storeUrl: z.string().optional(),
  storeName: z.string().optional(),
  storefrontId: z.string(),
  isActive: z.boolean(),
  exchangeOrderPrefix: z.string().optional(),
  isClaimImageFromCustomerRequired: z.boolean(),
  stripeConfig: stripeConfig.optional(),

  registrations: z.object({
    isEnabled: z.boolean(),
    shouldCollectProofOfPurchase: z.boolean(),
    shouldCollectAddress: z.boolean(),
    isStartWarrantyClaimEnabled: z.boolean(),
    storeSalesChannels: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    ),
  }),
  warranty: z.object({
    isEnabled: z.boolean(),
  }),
  return: z.object({
    isEnabled: z.boolean(),
    isGiftCardEnabled: z.boolean(),
    isExchangeEnabled: z.boolean(),
    isRefundEnabled: z.boolean(),
  }),

  theme: DbJsonSchema.StoreUiConfig.customerUiConfigSchema,
});

export const monetarySettingsEstimate = z.object({
  kind: z.literal('estimateIncentive'),
  giftCardIncentiveAmount: z.number().nullable(),
});

export const monetarySettingsBeforeClaimSubmissionReturn = z.object({
  kind: z.literal('beforeClaimSubmissionReturn'),
  fee: z
    .object({
      amount: z.number(),
      displayName: z.string(),
    })
    .nullable(),
  giftCardIncentiveAmount: z.number().nullable(),
  exchangeOrderShippingCharge: z.number().nullable(),
  returnLabelCharge: z.number().nullable(),
});

export const monetarySettingsBeforeClaimSubmissionWarranty = z.object({
  kind: z.literal('beforeClaimSubmissionWarranty'),
  fee: z
    .object({
      amount: z.number(),
      displayName: z.string(),
    })
    .nullable(),
});

export const monetarySettings = z.discriminatedUnion('kind', [
  monetarySettingsEstimate,
  monetarySettingsBeforeClaimSubmissionReturn,
  monetarySettingsBeforeClaimSubmissionWarranty,
]);
