import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import { CORSO_URL } from '~/utils/types';

export type OrderLookupLoaderData = {
  exchangeOrderPrefix: string | undefined;
  orderLookupDetailText: string | undefined;
};

export default crewLoader(({ context }) => {
  const {
    isActive,
    storeUrl = CORSO_URL,
    exchangeOrderPrefix,
    theme: { orderLookupDetailText },
  } = context.settings;
  if (!isActive) {
    return redirect(`inactive?redirect=${encodeURI(storeUrl)}`);
  }

  return json<OrderLookupLoaderData>({
    exchangeOrderPrefix,
    orderLookupDetailText,
  });
});

export const useOrderLookupData = createLoaderDataHook<OrderLookupLoaderData>();
