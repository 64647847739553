//* claim line item

import { z } from 'zod';
import {
  CrewClaimResolutionMethodEnum,
  CrewClaimStatusCode,
  CrewClaimStatusDetailCode,
} from '../../enums/crew.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';
import { customField } from '../merchant/schema/customField.js';
import {
  storeInspectionGrade,
  storeInspectionGradeCreate,
} from './inspectionGrade.js';
import { storeOrderLineItem } from './lineItem.js';

// ----------  Claim Line Item CustomField  ----------

export const claimLineItemCustomField = customField
  .omit({
    question: true,
    options: true,
  })
  .merge(z.object({ value: z.string() }));

export const claimLineItemCustomFieldCreate = claimLineItemCustomField.pick({
  id: true,
  value: true,
});

// ----------  Claim Line Item Inspection  ----------
export const inspectionImage = z.object({
  id: z.number(),
  url: z.string(),
  isVisibleToCustomer: z.boolean(),
});

export const inspectionImageCreate = inspectionImage
  .omit({
    id: true,
    url: true,
  })
  .merge(z.object({ filename: z.string() }));

export const claimLineItemInspection = z.object({
  id: z.number(),
  comment: z.string().optional(), // ? internal comment
  images: z.array(inspectionImage),
  storeInspectionGrade,
});

export const claimLineItemInspectionCreate = claimLineItemInspection
  .omit({
    id: true,
    images: true,
    storeInspectionGrade: true,
  })
  .merge(
    z.object({
      storeInspectionGrade: storeInspectionGradeCreate,
      images: z.array(inspectionImageCreate).optional(),
    }),
  );

// ----------  Claim Line Item  ----------

export const variantExchangeLineItem = z.object({
  //* idFromPlatform is the variantId of the new variant on Shopify
  idFromPlatform: z.string(),
  quantity: z.number(),
  name: z.string().nullable(),
  imgUrl: z.string().nullable(),
  sku: z.string().optional(),
  price: z.number().optional(),
  optionsFromPlatform: z
    .array(z.object({ name: z.string(), value: z.string() }))
    .optional(),
});

export const variantExchangeLineItemCreate = variantExchangeLineItem.pick({
  idFromPlatform: true,
  quantity: true,
});

export const claimLineItem = z.object({
  id: z.number(),
  returnLineItemIdFromPlatform: z.string().nullable(),
  // ! all line item claims
  noteToCustomer: z.string().optional(),
  errors: z.array(z.string()).optional(),
  requestedResolutionMethodEnum: enumToZodLiteralUnion(
    CrewClaimResolutionMethodEnum,
  ),
  resolutionMethodEnum: enumToZodLiteralUnion(
    CrewClaimResolutionMethodEnum,
  ).optional(),
  inspection: claimLineItemInspection.optional(),
  images: z.array(z.string()).optional(),
  comments: z.string().nullable(), // ? noteFromCustomer
  originalStoreOrderLineItemId: z.number(),
  originalStoreOrderLineItem: storeOrderLineItem,
  status: z.object({
    id: z.number(),
    name: z.string(),
    code: enumToZodLiteralUnion(CrewClaimStatusCode),
    detail: z.object({
      id: z.number(),
      name: z.string(),
      code: enumToZodLiteralUnion(CrewClaimStatusDetailCode),
    }),
  }),

  customFields: z.array(claimLineItemCustomField),

  reason: z.object({
    id: z.number(),
    name: z.string(),
    detail: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .optional(), // ! detail will always remain optional on the reason as it's not required
  }),

  updatedOn: z.string().optional(),
  createdOn: z.string(),

  // ! Variant Exchange Line Item Claims
  variantExchangeLineItem: variantExchangeLineItem.optional(),

  // ! Monetary Claims (Refund, Gift Card)
  quantity: z.number(),
  unitPrice: z.number(),
  unitTax: z.number(),
  amount: z.number().optional(), // issued monetary value of the claim line item applies to refunds and gift cards
});

export const claimLineItemCreate = claimLineItem
  .omit({
    updatedOn: true,
    createdOn: true,
    id: true,
    status: true,
    originalStoreOrderLineItem: true,
    inspection: true,
    resolutionMethodEnum: true,
    noteToCustomer: true,
    amount: true,
    returnLineItemIdFromPlatform: true,
    variantExchangeLineItem: true,
  })
  .merge(
    z.object({
      variantExchangeLineItem: variantExchangeLineItemCreate.optional(),
      customFields: z.array(claimLineItemCustomFieldCreate).optional(),
    }),
  );
