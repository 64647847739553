import { z } from 'zod';
import { helperSchema } from '../../../zodExtensions.js';

export enum CustomFieldValueType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Select = 'Select',
}

export const customField = z.object({
  id: z.number(),
  displayName: z.string(),
  question: z.string(),
  options: helperSchema.csv.optional(),
  valueType: z.union([
    z.literal(`${CustomFieldValueType.Text}`),
    z.literal(`${CustomFieldValueType.Number}`),
    z.literal(`${CustomFieldValueType.Date}`),
    z.literal(`${CustomFieldValueType.Select}`),
  ]),
});

export const customFieldCreate = customField.omit({ id: true });

export const customFieldUpdate = customField.omit({ valueType: true });

// ------- customer ui custom field schemas -------

export const customUiCustomFieldSelect = customField
  .pick({
    id: true,
    question: true,
  })
  .merge(
    z.object({
      valueType: z.literal(`${CustomFieldValueType.Select}`),
      options: z.string().array(),
    }),
  );

export const customUiCustomField = customField
  .pick({
    id: true,
    question: true,
  })
  .merge(
    z.object({
      valueType: z.union([
        z.literal(`${CustomFieldValueType.Text}`),
        z.literal(`${CustomFieldValueType.Number}`),
        z.literal(`${CustomFieldValueType.Date}`),
      ]),
    }),
  );

export const customerUiEventCollectCustomFieldsParams = z.object({
  isDefaultCommentFieldRequired: z.boolean().optional(),
  showDefaultCommentField: z.boolean(),
  isMediaUploadRequired: z.boolean(),
  mediaUploadInstructions: z.string().optional(),
  minimumMediaUploadAmount: z.number().optional(),
  customFields: z.array(
    z.union([customUiCustomField, customUiCustomFieldSelect]),
  ),
});

export const customerUiEventCustomField = z.object({
  type: z.literal('collectCustomFields'),
  params: customerUiEventCollectCustomFieldsParams,
});
