import { z } from 'zod';
import { CrewReturnShipmentTypeEnum } from '../../enums/crew.js';
import { TrackingStatusCode } from '../../enums/tracking.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';
import { locationAddress } from './address.js';
import { claimLineItem } from './claimLineItem.js';
import { rate, returnShipmentMethod } from './shipmentRate.js';
import { carrier } from './tracking.js';

// ? new shipment related types

export const shipmentCreate = z.object({
  claimId: z.number(),
  returnShipment: returnShipmentMethod,
});

export const returnShipmentTracker = z.object({
  latestStatus: enumToZodLiteralUnion(TrackingStatusCode),
  carrier,
});

export const shipment = z.object({
  claimId: z.number(),
  claimExternalId: z.string(),
  shipmentExpiresOn: z.string(),
  returnShipmentId: z.number(),
  returnLocationAddress: locationAddress.optional(),
  inTransitOn: z.string().optional(),
  qrCodeDetail: z
    .object({
      url: z.string(),
      returnToName: z.string(),
      carrier: z.string(),
      carrierIconImgUrl: z.string(),
    })
    .optional(),
  returnShipmentPdfAssetUrl: z.string().optional(),
  returnShipmentType: enumToZodLiteralUnion(CrewReturnShipmentTypeEnum),
  claimLineItems: z.array(claimLineItem),
  tracker: returnShipmentTracker.optional(),
  rmaId: z.string().optional(),
  rmaNumber: z.string().optional(),
  rmaProvider: z
    .union([z.literal('Ship_Bob'), z.literal('Ship_Hero')])
    .optional(),
});

// used for quoting "new shipping estimates" on a new order
export const shipmentQuote = z.object({
  id: z.string().nullable(),
  carrier: z.string().nullable(),
  cost: z.number().nullable(),
});

export const shipmentQuoteBody = z.object({
  fromCustomer: returnShipmentMethod.array(),
  toCustomer: rate.nullable(),
});

const extendedRate = rate.merge(z.object({ service: z.string() }));

export const adminShippingLabel = z.object({
  claimId: z.number(),
  returnLocationId: z.number(),
  returnShipmentType: z.literal(CrewReturnShipmentTypeEnum.label),
  rate: extendedRate,
  shipmentIdFromPlatform: z.string(),
});

export const adminPackingSlip = z.object({
  claimId: z.number(),
  returnLocationId: z.number(),
  returnShipmentType: z.literal(CrewReturnShipmentTypeEnum.packingSlip),
});

export const shipmentAdminCreate = z.discriminatedUnion('returnShipmentType', [
  adminPackingSlip,
  adminShippingLabel,
]);

export const shipmentAdminQuote = z.object({
  returnLocationId: z.number(),
  weightInOunces: z.number(),
});

export const shipmentAdminQuoteResponse = z.object({
  shipmentIdFromPlatform: z.string(),
  rates: z.array(extendedRate),
});
