import { z } from 'zod';
import { apiClientResp } from '../merchant/error.js';
import { Pegasus } from '../schema/pegasus.js';
import { CrewUi } from './schema.js';

//* URL params

const settingsParams = z.object({
  slug: z.string(),
});

const storefrontIdParams = z.object({
  storefrontId: z.string(),
});

const storeIdProductParams = z
  .object({
    idFromPlatform: z.string(),
  })
  .merge(storefrontIdParams);

const claimIdParams = z
  .object({ claimExternalId: z.coerce.string() })
  .merge(storefrontIdParams);

const gspClaimIdParams = z
  .object({ claimId: z.coerce.number() })
  .merge(storefrontIdParams);

const storeIdFromPlatformQuery = z.object({
  idFromPlatform: z.string(),
});

const storeIdOrderNoQuery = z.object({
  orderNo: z.string(),
});

const registrationExternalIdWithEmailQuery = z
  .object({
    email: z.string(),
  })
  .merge(z.object({ externalId: z.string() }));

//* URL queries
const storeIdOrderQueryWithEmail = z
  .object({
    email: z.string(),
  })
  .merge(storeIdOrderNoQuery);

const storeIdOrderQueryWithPostalCode = z
  .object({
    postalCode: z.string(),
  })
  .merge(storeIdOrderNoQuery);

const storeIdOrderUnionQuery = z.union([
  storeIdFromPlatformQuery,
  storeIdOrderQueryWithEmail,
  storeIdOrderQueryWithPostalCode,
  registrationExternalIdWithEmailQuery,
]);

const signedUrlQuery = z.object({
  filename: z.string(),
  contentType: z.string(),
  destination: z.union([
    z.literal('gspClaimImg'),
    z.literal('crewClaimImg'),
    z.literal('crewProofOfPurchase'),
  ]),
});

//* URL bodies
const shipmentQuoteBody = z.object({
  originalStoreOrderId: z.number(),
  toCustomerGrams: z.coerce.number(),
  fromCustomerGrams: z.coerce.number(),
  address: CrewUi.claimAddressSchema,
  requestedResolutionMethods: z.array(z.string()).optional(),
});

// ------- URL paths -----------------------------

const shippingOrderPath = `/protection/order`;
const settingsPath = '/settings/:slug';

// ------- everything below here is namespaced by the storefrontId and GSP or CREW where the need to disambiguate exists

const storefrontIdPath = '/:storefrontId';

const registrationPath = `${storefrontIdPath}/registration` as const;
const registrationProductPath = `${registrationPath}/product` as const;

const automationsPath = `${storefrontIdPath}/automations` as const;
const automationsAfterReasonSelectionPath =
  `${automationsPath}/after-reason-selection` as const;

const automationsBeforeClaimSubmissionPath =
  `${automationsPath}/before-claim-submission` as const;

const createPaymentIntentPath =
  `${storefrontIdPath}/create-payment-intent` as const;

const signedUrlPath = `${storefrontIdPath}/signed-url` as const;

const storeIdOrderPath = `${storefrontIdPath}/order` as const;

const storeIdProductPath =
  `${storefrontIdPath}/product/:idFromPlatform` as const;

const crewClaimPath = `${storefrontIdPath}/claim` as const;
const crewClaimIdPath = `${crewClaimPath}/:claimExternalId` as const;

const shippingClaimPath =
  `${storefrontIdPath}/shipping-protection/claim` as const;
const shippingClaimIdPath = `${shippingClaimPath}/:shippingClaimId` as const;

const claimReturnShipmentPath = `${storefrontIdPath}/return-shipment` as const;
const claimReturnShipmentWhPath = `${claimReturnShipmentPath}/wh` as const;

// ? quote paths
const claimReturnShipmentQuotePath =
  `${claimReturnShipmentPath}/quote` as const;

//! all endpoints exposed by the crewUiApi are at a base path of `/v1/shop/crew/`
//! storefrontId is returned in the call to settings, and all subsequent calls require it

/**
 * crewUiApi object containing the following:
 * 1. A list of all routes exposed for consumption by the crew-ui (all routes are prefixed with `/v1/shop/crew/`).
 * 2. All query params, path params, and request/response bodies for each route.
 */
export const crewCustomerApi = {
  // load for GSP flow
  [shippingOrderPath]: {
    path: shippingOrderPath,
    get: {
      request: {
        query: storeIdOrderUnionQuery,
      },
      response: { body: CrewUi.gspOrderSchema },
    },
  },
  // load for CREW flow
  [settingsPath]: {
    path: settingsPath,
    get: {
      request: { params: settingsParams },
      response: { body: CrewUi.settingsSchema },
    },
  },
  [automationsAfterReasonSelectionPath]: {
    path: automationsAfterReasonSelectionPath,
    get: {
      request: {
        params: storefrontIdParams,
        query: CrewUi.afterReasonSelectionQuerySchema,
      },
      response: {
        body: z.object({
          eventPolicyEnforcement: Pegasus.eventPolicyEnforcement.nullable(),
          eventCustomFields: CrewUi.collectCustomFieldEventSchema.nullable(),
        }),
      },
    },
    post: {
      request: {
        params: storefrontIdParams,
        query: CrewUi.afterReasonSelectionQuerySchema,
        body: z.object({
          event: Pegasus.eventPolicyEnforcement,
        }),
      },
      response: {
        body: apiClientResp,
      },
    },
  },
  [registrationPath]: {
    path: registrationPath,
    post: {
      request: {
        params: storefrontIdParams,
        body: CrewUi.registrationCreateSchema,
      },
      response: { body: CrewUi.registrationSchema },
    },
  },
  [registrationProductPath]: {
    path: registrationProductPath,
    get: {
      request: {
        params: storefrontIdParams,
        query: z.object({
          storeSalesChannelName: z.string(),
          estimatedPurchaseDate: z.string(),
          searchTerm: z.string(),
        }),
      },
      response: { body: z.array(CrewUi.registrationProductSchema) },
    },
  },
  // the monetary settings, and any other settings that need to be loaded before the claim is submitted
  [automationsBeforeClaimSubmissionPath]: {
    path: automationsBeforeClaimSubmissionPath,
    post: {
      request: {
        params: storefrontIdParams,
        body: CrewUi.beforeClaimSubmissionBodySchema,
      },
      response: { body: CrewUi.monetarySettingsSchema },
    },
  },
  [shippingClaimPath]: {
    path: shippingClaimPath,
    post: {
      request: {
        params: storefrontIdParams,
        body: CrewUi.shippingClaimCreateCustomerSchema,
      },
      response: { body: CrewUi.shippingClaimSchema },
    },
  },
  [crewClaimPath]: {
    path: crewClaimPath,
    post: {
      request: { params: storefrontIdParams, body: CrewUi.claimCreateSchema },
      response: { body: CrewUi.claimSchema },
    },
  },

  [storeIdOrderPath]: {
    path: storeIdOrderPath,
    get: {
      request: {
        params: storefrontIdParams,
        query: storeIdOrderUnionQuery,
      },
      response: { body: CrewUi.orderSchema },
    },
  },
  [crewClaimIdPath]: {
    path: crewClaimIdPath,
    get: {
      request: { params: claimIdParams },
      response: { body: CrewUi.claimSchema },
    },
  },
  [shippingClaimIdPath]: {
    path: shippingClaimIdPath,
    get: {
      request: { params: gspClaimIdParams },
      response: { body: CrewUi.shippingClaimSchema },
    },
  },

  [storeIdProductPath]: {
    path: storeIdProductPath,
    get: {
      request: { params: storeIdProductParams },
      response: { body: CrewUi.variantExchangeSchema },
    },
  },
  [claimReturnShipmentPath]: {
    path: claimReturnShipmentPath,
    post: {
      request: {
        params: storefrontIdParams,
        body: CrewUi.shipmentCreateSchema,
      },
      response: { body: CrewUi.shipmentSchema.nullable() },
    },
  },
  [claimReturnShipmentQuotePath]: {
    path: claimReturnShipmentQuotePath,
    post: {
      request: { params: storefrontIdParams, body: shipmentQuoteBody },
      response: { body: CrewUi.shipmentQuoteSchema },
    },
  },

  [claimReturnShipmentWhPath]: {
    path: claimReturnShipmentWhPath,
    // WH path for easy post updates
    post: { request: { params: storefrontIdParams } },
  },

  [signedUrlPath]: {
    path: signedUrlPath,
    get: {
      request: { params: storefrontIdParams, query: signedUrlQuery },
      response: { body: CrewUi.signedUrlSchema },
    },
  },

  [createPaymentIntentPath]: {
    path: createPaymentIntentPath,
    post: {
      request: {
        params: storefrontIdParams,
        body: CrewUi.paymentIntentCreateSchema,
      },
      response: { body: CrewUi.paymentIntentSchema },
    },
  },
} as const;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrewCustomerApi {
  type Api = typeof crewCustomerApi;
  export type ApiPath = keyof Api;
  export type StoreIdOrderQuery = z.infer<typeof storeIdOrderUnionQuery>;
  export type SignedUrlQuery = z.infer<typeof signedUrlQuery>;
  export type ShipmentQuoteBody = z.infer<typeof shipmentQuoteBody>;

  type HttpMethod = 'get' | 'post';
  export type MethodOfPath<Path extends ApiPath> = keyof Api[Path] & HttpMethod;

  // * helper request / response body types
  export type ResponseBody<
    Path extends ApiPath,
    Method extends MethodOfPath<Path>,
  > =
    Api[Path] extends Record<Method, { response: { body: z.ZodSchema } }> ?
      z.infer<Api[Path][Method]['response']['body']>
    : never;

  export type RequestBody<
    Path extends ApiPath,
    Method extends MethodOfPath<Path>,
  > =
    Api[Path] extends Record<Method, { request: { body: z.ZodSchema } }> ?
      z.infer<Api[Path][Method]['request']['body']>
    : never;

  export type RequestQuery<
    Path extends ApiPath,
    Method extends MethodOfPath<Path>,
  > =
    Api[Path][Method] extends (
      {
        request: { query: z.ZodSchema };
      }
    ) ?
      z.infer<Api[Path][Method]['request']['query']> // TODO find a nicer way to extract this, and other properties/types
    : never;

  export type RequestParams<
    Path extends ApiPath,
    Method extends MethodOfPath<Path>,
  > =
    Api[Path][Method] extends (
      {
        request: { params: z.ZodSchema };
      }
    ) ?
      z.infer<Api[Path][Method]['request']['params']>
    : never;
}
