import api from '~/utils/api';
import {
  createActionResultHook,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { ActionResult, OrderQuery } from '~/utils/types';

const getOrderQuery = (formData: FormData): OrderQuery => {
  const orderNo = String(formData.get('orderNo'));

  return formData.has('email') ?
      { orderNo, email: String(formData.get('email')) }
    : { orderNo, postalCode: String(formData.get('postalCode')) };
};

export default gspAction(({ formData, context }) => {
  if (
    (!formData.has('email') && !formData.has('postalCode')) ||
    !formData.has('orderNo')
  ) {
    return json<ActionResult>({
      ok: false,
      message: `Please check the entered information and try again.`,
    });
  }

  return api
    .fetchProtectionOrder({ query: getOrderQuery(formData) })
    .then((order) => {
      context.reset();
      context.setOrder(order);

      return redirect(`./reorder/${order.idFromPlatform}`);
    })
    .catch((error) =>
      json<ActionResult>({
        ok: false,
        message: error instanceof Error ? error.message : String(error),
      }),
    );
});

export const useOrderLookupActionResult =
  createActionResultHook<ActionResult>();
