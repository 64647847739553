import { z } from 'zod';
import { isTuple, StringEnum, Tuple, Unionize } from './typing.js';
/** Given a string enum, `enumReference`, creates a `union` of `literal`s with the values of the enum.  */
export function enumToZodLiteralUnion<
  T extends string,
  E extends StringEnum<T>,
>(enumReference: E) {
  const values = Object.values(enumReference);

  const minLength = 2;
  if (!isTuple(values, minLength)) {
    throw new Error('A Union Requires at Least Two Enum Values');
  }

  // * explicit as conversion to satisfy when `map` is called on `values` is still a tuple of the same size as validated above
  const literals = values.map((value) => z.literal(value.toString())) as Tuple<
    z.ZodLiteral<Unionize<E[keyof E]>>,
    typeof minLength
  >;

  return z.union(literals);
}

/**
 * Converts ALL [falsy values](https://developer.mozilla.org/en-US/docs/Glossary/Falsy) of `T` into `null` instead, and allows `null`.
 * For `string` values, it trims whitespace before checking for falsy values.
 */
export function nullify<T>(schema: z.Schema<T>) {
  return z
    .union([
      z.string().trim(), // always trim strings to be nullified, so they'll be empty for falsy checks
      z.unknown(),
    ])
    .transform((data) => data || null)
    .pipe(schema.nullable());
}

/**
 * Converts falsy and white-space only strings into `null` when parsed, while still accepting `null` values.
 * Validates for `string` or `null` values removing whitespace, and transforms empty strings to `null`.
 * Inadvertently this ensures that all `string` results are non-empty.
 */
const nullifiedString = nullify(z.string().trim());

const csvList = z
  .string()
  .trim()
  .transform((data) => data.split(',').filter(Boolean)) // could be a single email or a list of emails, separated by a comma and optional whitespace
  .transform((data) => data.join(', ')); // rejoin to a single string with comma and space

const emailList = z
  .string()
  .trim()
  .transform((data) => data.split(',').filter(Boolean)) // could be a single email or a list of emails, separated by a comma and optional whitespace
  .pipe(z.array(z.string().trim().email()))
  .transform((data) => data.join(', ')); // rejoin to a single string with comma and space

/**
 * Converts ALL [falsy values](https://developer.mozilla.org/en-US/docs/Glossary/Falsy) of `T` into `undefined` instead, making them optional.
 * For `string` values, it trims whitespace before checking for falsy values.
 */
export function optionalize<T>(schema: z.Schema<T>) {
  return z
    .union([
      z.string().trim(), // always trim strings to be optionalized, so they'll be empty for falsy checks
      z.unknown(),
    ])
    .transform((data) => data || undefined)
    .pipe(schema.optional());
}

const optionalizedString = optionalize(z.string().trim());

/** Predefined, reusable schemas with user-friendly error messages. */
// ? possibly rename, or restructure to be more similar to zod; i.e. `string.nullified` or `string.email.nullified`
export const helperSchema = {
  nonEmptyString: z.string().nonempty({ message: 'Must not be empty.' }),
  nullifiedString,
  nullifiedEmail: nullify(
    z.string().email({ message: 'Must be a valid email.' }),
  ),
  csv: csvList,
  nullifiedUrl: nullify(z.string().url({ message: 'Must be a valid URL.' })),
  nullifiedEmailList: nullify(emailList), // ? probably needs a nicer message
  emailList,
  nonNegativeNumber: z
    .number({
      required_error: 'Must provide a number.',
      invalid_type_error: 'Must be a number.',
    })
    .nonnegative({ message: 'Must be zero or a positive number.' }),

  optionalizedString,
  optionalizedDatetime: optionalize(z.string().datetime()),
};
