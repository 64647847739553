/* eslint-disable no-nested-ternary */
import { Form } from 'react-router-dom';
import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Field, FieldLabel, FieldMessage } from '~/components/field';
import FileUploader from '~/components/file-uploader';
import Input from '~/components/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/select';
import TextArea from '~/components/textarea';
import { useIsLoading } from '~/utils/routing';
import { FormKeys, useMetaActionResult } from './meta-action';
import { ClaimMetaData, useMetaData } from './meta-loader';

type FieldType = ClaimMetaData['customFields'][0]['valueType'];

const lookupInputType = (valueType: FieldType) => {
  switch (valueType) {
    case 'Number':
      return 'number';
    case 'Date':
      return 'date';
    default:
      return 'text';
  }
};

export default function ClaimMeta() {
  const isLoading = useIsLoading();

  const {
    claim,
    customFields,
    showDefaultCommentField,
    mediaUploadInstructions,
    requiredMediaCount,
    isDefaultCommentFieldRequired,
  } = useMetaData();

  const actionResult = useMetaActionResult();
  const hasErrors = actionResult && !actionResult.ok;
  const showAlert = hasErrors && actionResult.message;
  const showFieldMessage = hasErrors && !actionResult.message;

  const customFieldsExist = customFields.length > 0;

  return (
    <Card
      headline={
        customFieldsExist ?
          `Let's get some more information`
        : 'Anything else you can tell us?'
      }
    >
      <Form
        className="flex flex-col gap-6"
        method="post"
        encType="multipart/form-data"
        name="claimMeta"
      >
        {customFields.map((field) => (
          <Field key={field.id}>
            <FieldLabel htmlFor={`${field.id}`}>
              {field.question}
              <span aria-hidden>*</span>
            </FieldLabel>
            {field.valueType === 'Select' ?
              <Select required name={`${field.id}`}>
                <SelectTrigger id={`${field.id}`}>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {field.options.map((option) => (
                    <SelectItem key={option} value={option}>
                      {option}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            : <Input
                type={lookupInputType(field.valueType)}
                name={`${field.id}`}
                id={`${field.id}`}
                required
                defaultValue={claim.customFields[field.id]}
              />
            }
          </Field>
        ))}

        {showDefaultCommentField && (
          <Field>
            <FieldLabel htmlFor="comments">
              {customFieldsExist && (
                <span>
                  Anything else you can tell us?
                  {isDefaultCommentFieldRequired && <span aria-hidden>*</span>}
                </span>
              )}
            </FieldLabel>
            <TextArea
              id="comments"
              name={FormKeys.Comments}
              defaultValue={claim.comments ?? ''}
              required={Boolean(isDefaultCommentFieldRequired)}
            />
          </Field>
        )}

        <FileUploader
          name={FormKeys.Assets}
          value={claim.assets}
          requiredCount={0}
          description={mediaUploadInstructions}
        />

        {showFieldMessage && (
          <FieldMessage error>{actionResult.form?.assets}</FieldMessage>
        )}

        {showAlert && (
          <Alert variant="error" title={actionResult.message ?? ''}>
            <p>{actionResult.form?.assets}</p>
          </Alert>
        )}

        <input
          type="hidden"
          data-testid="required-asset-count"
          name={FormKeys.RequiredAssetCount}
          value={String(requiredMediaCount)}
        />

        <Submit loading={isLoading}>Next</Submit>
      </Form>
    </Card>
  );
}
