import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { FieldMessage } from '~/components/field';
import LinkButton from '~/components/link-button';
import { useIsLoading } from '~/utils/routing';
import { ACTION_KEY, useFollowUpActionResult } from './follow-up-action';
import { useFollowUpData } from './follow-up-loader';

const defaultMessage =
  "Sorry, but it looks like this item isn't eligible for return. If you have any questions, please contact us.";

export default function ClaimFollowUp() {
  const { eventPolicyEnforcement, orderId, store, claimType } =
    useFollowUpData();
  const actionResult = useFollowUpActionResult();

  const isLoading = useIsLoading();

  return (
    <Card>
      {!actionResult?.ok ?
        <Form
          className="flex flex-col gap-6"
          method="post"
          name="claimFollowUp"
        >
          <p className="text-xl font-medium text-gray-800">
            {eventPolicyEnforcement.params.question}
          </p>
          {actionResult?.ok === false && (
            <FieldMessage error>
              Sorry something went wrong. Please try again.
            </FieldMessage>
          )}
          <div className="flex flex-col gap-4 sm:flex-row">
            <LinkButton className="flex-1" to="../meta">
              No
            </LinkButton>
            <input
              data-testid={ACTION_KEY}
              type="hidden"
              name={ACTION_KEY}
              value={JSON.stringify({ event: eventPolicyEnforcement })}
            />
            <Submit className="flex-1" loading={isLoading}>
              Yes
            </Submit>
          </div>
        </Form>
      : <>
          <p className="text-xl font-medium text-gray-800">
            {eventPolicyEnforcement.params.onYesMessage ?? defaultMessage}
          </p>
          <LinkButton to={`/${store}/order/${orderId}/${claimType}`}>
            Back to Order
          </LinkButton>
        </>
      }
    </Card>
  );
}
