import {
  LabelHTMLAttributes,
  ParamHTMLAttributes,
  PropsWithChildren,
} from 'react';
import { twMerge } from 'tailwind-merge';

function Field({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={twMerge('flex flex-col gap-2', className)}>{children}</div>
  );
}

function FieldLabel({
  children,
  className,
  htmlFor,
  ...rest
}: LabelHTMLAttributes<HTMLLabelElement>) {
  return (
    <label
      {...rest}
      htmlFor={htmlFor}
      className={twMerge('text-sm font-medium text-gray-700', className)}
    >
      {children}
    </label>
  );
}

function FieldMessage({
  children,
  className,
  error,
  ...rest
}: ParamHTMLAttributes<HTMLParagraphElement> & { error?: boolean }) {
  return (
    <p
      {...rest}
      className={twMerge('text-sm', error && 'text-error', className)}
    >
      {children}
    </p>
  );
}

export { Field, FieldLabel, FieldMessage };
