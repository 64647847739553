import { useMemo } from 'react';
import { matchPath, useRouteLoaderData } from 'react-router-dom';
import { z } from 'zod';
import api from '~/utils/api';
import { computeCost, sumBy } from '~/utils/compute';
import { formatDate, toCurrency } from '~/utils/formatters';
import { gspLoader, json, redirect } from '~/utils/routing';
import { GspContext, Order } from '~/utils/types';

export type LoaderData = {
  store: string;
  orderNo: string;
  orderDate: string;
  price: string;
  postalCode?: string;
  wasShippingProtected: boolean;
};

export type ProtectionOrderData = {
  order: Order;
};

export const ID = 'protectionOrder';

const getLatestClaim = (claims: NonNullable<Order['shippingClaims']>) => {
  const sorted = claims.sort((a, b) => b.createdOn.localeCompare(a.createdOn));

  return sorted[0];
};

const getOrder = (context: GspContext, query: { idFromPlatform: string }) =>
  context.order?.idFromPlatform === query.idFromPlatform ?
    Promise.resolve(context.order)
  : api.fetchProtectionOrder({ query });

const checkOrderStore = (store: string) => (order: Order) =>
  store === '' || order.storeSlug === store ?
    Promise.resolve(order)
  : Promise.reject(new Error('Order not from store'));

const paramsSchema = z.object({
  idFromPlatform: z.string(),
  store: z.string().default(''),
});

export default gspLoader(({ params, context, request }) => {
  const { idFromPlatform, store } = paramsSchema.parse(params);

  return (
    getOrder(context, { idFromPlatform })
      // ! ensure that the order is from the store we are on if we are on a branded reorder path
      .then(checkOrderStore(store))
      .then((order) => {
        context.setOrder(order);
        const { pathname } = new URL(request.url);

        const onClaimPage = !!matchPath(
          { path: '/:store?/reorder/:idFromPlatform/claim/:id', end: false },
          pathname,
        );

        if (order.shippingClaims?.length && !onClaimPage) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked that claims is not null and has length
          const claim = getLatestClaim(order.shippingClaims)!;
          return redirect(`claim/${claim.id}/existing`);
        }

        const allowShippingClaimCreate =
          order.wasShippingProtected ||
          order.isUnprotectedShippingClaimPermitted;

        const onNotProtectedPage = !!matchPath(
          {
            path: '/:store?/reorder/:idFromPlatform/not-protected',
            end: false,
          },
          pathname,
        );

        if (!allowShippingClaimCreate && !onNotProtectedPage) {
          return redirect('not-protected');
        }

        return json<ProtectionOrderData>({
          order,
        });
      })
      .catch(() => redirect(`/${store}`))
  );
});

/**
 * This hook uses the `useRouteLoaderData` hook to provide
 * the order data any ancestor or descendant components
 * matching the `Reorder` route. In particular, it provides
 * the order data to the AppShell rather than having the AppShell query
 * the order data itself.
 */
export const useProtectionOrderData = () =>
  useRouteLoaderData(ID) as ProtectionOrderData | undefined;

/**
 * This hook provides the data needed by the OrderOverview component that is a child of this loader.
 * It uses the `useProtectionOrderData` hook to get the order data and
 * then transforms it into the shape needed by the OrderOverview component.
 */
export const useOrderOverviewLoaderData = () => {
  const orderData = useProtectionOrderData();

  if (!orderData) {
    throw new Error('Order data not found');
  }

  const { order } = orderData;

  return useMemo(
    () =>
      ({
        store: order.storeName,
        orderNo: order.orderNo,
        orderDate: formatDate(order.createdOn),
        price: toCurrency(order.currencyCode)(
          sumBy(computeCost)(order.lineItems),
        ),

        postalCode: order.shippingAddress?.postalCode,
        wasShippingProtected: order.wasShippingProtected,
      }) satisfies LoaderData,
    [order],
  );
};
