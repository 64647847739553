// ! UTILITY ENUMS TO ASSIST WITH DB
// ! DO NOT CHANGE THESE ENUMS, UNLESS YOU KNOW WHAT THE IMPACT WILL BE
// ! DO NOT RENAME OR MOVE THIS FILE WITHOUT UPDATING ./github/workflows/deploy-stage.yml (the DB should be deployed if these enums change to upsert them correctly)

export enum PlatformEnumId {
  shopify = 1,
  bigCommerce = 2,
  custom = 3,
}

export enum InvoiceSectionName {
  protectedOrders = 'Protected Orders',
  reorders = 'Reorders',
  refunds = 'Refunds',
  adjustments = 'Adjustments',
  revShare = 'Revenue Share',
  crewSubscriptionCharge = 'Crew Subscription Charge',
}

export enum ClickhouseDataset {
  storeOrders = 'store_orders',
  crewClaimLineItems = 'crew_claim_line_items',
  shipProtectClaims = 'ship_protect_claims',
  storeProductVariants = 'store_product_variants',
}

// ! POSTGRES ENUM (DO NOT CHANGE)
export enum ShipmentApiProviders {
  easyPost = 'Easy_Post',
  vesyl = 'Vesyl',
}

export enum PlatformCode {
  shopify = 'Shopify',
  bigCommerce = 'BigCommerce',
  custom = 'Custom',
}

export enum BillingProviderEnum {
  stripe = 'Stripe',
  shopifySubscription = 'Shopify_Subscription',
}

// DEFAULT ENUMS USED TO SEED DATABASE ENUMS -- CHANGE WITH CAUTION
export enum AddressTypeEnum {
  billing = 'Billing',
  fulfillment = 'Fulfillment',
  shipping = 'Shipping',
  return = 'Return',
}

export enum AppTypeEnum {
  gsp = 'GSP',
  crew = 'CREW',
}

export enum InvoiceStatusEnum {
  generated = 'Generated',
  submitted = 'Submitted',
  paid = 'Paid',
  partiallyPaid = 'Partially_Paid',
  uncollectable = 'Uncollectable',
  zeroDollar = 'Zero_Dollar',
}

export enum CommissionPayeeTypeEnum {
  partner = 'Partner',
  accountExecutive = 'Account_Executive',
  merchant = 'Merchant',
}

export enum ContactTypeEnum {
  billing = 'Billing',
  support = 'Support',
}

export enum InvoiceAdjustmentTypeEnum {
  invoiceCreditCreate = 'Invoice_Credit_Create',
  credit = 'Credit',
  debit = 'Debit',
  invoiceCreditApply = 'Invoice_Credit_Apply',
}

export enum RefundMethodEnum {
  paypal = 'Paypal',
  venmo = 'Venmo',
  shopifyAdmin = 'Shopify_Admin',
  shopifyApi = 'Shopify_API',
}

export enum ProtectSetCoverageEnum {
  /** Our default value for GSP `ship_protect_set.coverage`. */
  customerPaysPaidCovered = 'Customer_Pays_Paid_Covered',
  /** Customer pays for GSP. All orders covered regardless of GSP purchase. (Bylt will utilize this in the future.) */
  customerPaysAllCovered = 'Customer_Pays_All_Covered',
  /** Merchant pays for GSP, and all orders are covered. */
  merchantPaysAllCovered = 'Merchant_Pays_All_Covered',
}

//* these aren't true enums, but they are used like enums *//
//* used to populate a unique name on relationship tables claim_status, claim_status_detail, claim_category *//
//* allows us to reference a unique name instead of an id *//

export enum PermissionsCode {
  readOrders = 'read:orders',
  writeOrders = 'write:orders',
  readStores = 'read:stores',
}

export const seedPermissionsObject = [
  {
    where: { code: PermissionsCode.readOrders },
    create: {
      code: PermissionsCode.readOrders,
      name: 'Read Orders',
      description: 'Read orders permission',
    },
    update: { name: 'Read Orders', description: 'Read orders permission' },
  },
  {
    where: { code: PermissionsCode.writeOrders },
    create: {
      code: PermissionsCode.writeOrders,
      name: 'Write Orders',
      description: 'Write orders permission',
    },
    update: { name: 'Write Orders', description: 'Write orders permission' },
  },

  {
    where: { code: PermissionsCode.readStores },
    create: {
      code: PermissionsCode.readStores,
      name: 'Read stores',
      description: 'Read stores permission',
    },
    update: { name: 'Read stores', description: 'Read stores permission' },
  },
];

export enum RoleCode {
  corsoAdmin = 'Corso_Admin',
  admin = 'Admin',
  staff = 'Staff',
  commerceApiAdmin = 'Commerce_API_Admin',
}

export const seedRolesObject = [
  {
    where: { code: RoleCode.commerceApiAdmin },
    create: {
      code: RoleCode.commerceApiAdmin,
      name: 'Commerce API Admin',
      description: 'Admin User for programmatic access of Commerce API',
    },
    update: {
      name: 'Commerce API Admin',
      description: 'Admin User for programmatic access of Commerce API',
    },
  },
  {
    where: { code: RoleCode.admin },
    create: { code: RoleCode.admin, name: 'Admin', description: 'Admin User' },
    update: { name: 'Admin', description: 'Admin User' },
  },
  {
    where: { code: RoleCode.corsoAdmin },
    create: {
      code: RoleCode.corsoAdmin,
      name: 'Corso Admin',
      description: 'Corso Admin User for managing an installed CREW store',
    },
    update: {
      name: 'Corso Admin',
      description: 'Corso Admin User for managing an installed CREW store',
    },
  },

  {
    where: { code: RoleCode.staff },
    create: { code: RoleCode.staff, name: 'Staff', description: 'Staff User' },
    update: { name: 'Staff', description: 'Staff User' },
  },
];

export enum BulkOperationType {
  product = 'Product',
  order = 'Order',
  variant = 'Variant',
}
