import { forwardRef, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  emptyIsInvalid?: boolean;
};

const baseStyles =
  'w-full rounded-md border-outline px-4 py-2 sm:text-sm focus:border-primary focus:ring-primary';
const numberStyles =
  'text-right [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none';
const disabledStyles =
  'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200';
const emptyInvalidStyles =
  'empty:invalid:border-outline empty:invalid:focus:border-primary empty:invalid:focus:ring-primary empty:invalid:placeholder:text-gray-500';
const invalidStyles =
  'invalid:border-error invalid:focus:border-error invalid:focus:ring-error invalid:placeholder:text-error';

export default forwardRef<HTMLInputElement, Props>(
  ({ className, type = 'text', emptyIsInvalid = false, ...rest }, ref) => (
    <input
      {...rest}
      type={type}
      className={twMerge(
        baseStyles,
        type === 'number' && numberStyles,
        disabledStyles,
        invalidStyles,
        !emptyIsInvalid && emptyInvalidStyles,
        className,
      )}
      ref={ref}
    />
  ),
);
