import api from '~/utils/api';
import { isCreditClaim, sumPrice, sumTax } from '~/utils/compute';
import { toCurrency } from '~/utils/formatters';
import {
  createLoaderDataHook,
  crewLoader,
  error,
  json,
  redirect,
} from '~/utils/routing';

export type RefundOptionsData = {
  isGiftCardEnabled: boolean;
  isRefundEnabled: boolean;
  hasIncentive: boolean;
  incentive: string;
  refund: string;
  tax: string;
  total: string;
};

export default crewLoader(async ({ params: { store }, context }) => {
  const {
    isRefundEnabled: isSettingsRefundEnabled,
    isGiftCardEnabled: isSettingsGiftCardEnabled,
  } = context.settings.return;
  const { storefrontId } = context.settings;

  if (!context.order) {
    return redirect(`/${store}`);
  }

  const { currencyCode } = context.order;

  const claims = context.lineItemClaims.filter(isCreditClaim);

  // * no monetary claims so skip to address
  if (claims.length === 0) {
    return redirect(`../address`);
  }

  // *  currently the claim doesn't support mixed monetary resolution methods
  // * so need to check that all line item claims support one method
  const isAllClaimsRefundable = claims.every(
    (claim) => claim.lineItem.return.refund.isEligible,
  );
  const isAllClaimsGiftCards = claims.every(
    (claim) => claim.lineItem.return.giftCard.isEligible,
  );

  const isRefundEnabled = isSettingsRefundEnabled && isAllClaimsRefundable;
  const isGiftCardEnabled = isSettingsGiftCardEnabled && isAllClaimsGiftCards;

  // * have monetary claims but fails to be eligible for refund either by settings or variation in the line item eligibility -- dead end at the moment
  // ? potential change when mixed monetary resolution methods are supported by the claim
  if (!isRefundEnabled && !isGiftCardEnabled) {
    return error(
      // ! tightly coupled to the refund options error boundary - changes here should be reflected there
      new Error(
        `RefundOptionsErrorBoundary: 
     User has monetary claims but fails to be eligible for credit either by settings or variation in each claim line item eligibility.`,
        {
          cause: { orderIdFromPlatform: context.order.idFromPlatform },
        },
      ),
    );
  }

  const refundSubtotal = sumPrice(claims);
  const taxRefundValue = sumTax(claims);
  const lineItems = context.lineItemClaims.map((claim) => ({
    claimType: claim.claimType,
    lineItemId: claim.lineItem.id,
    reasonId: claim.reason.id,
    reasonCategoryCode: claim.reason.category,
    reasonDetailId: claim.reason.detail?.id,
    lineItemTotal: (claim.unitPrice + claim.unitTax) * claim.quantity,
    requestedResolutionMethod: claim.requestedResolutionMethodEnum,
    customFields: Object.entries(claim.customFields).map(([key, value]) => ({
      id: Number(key),
      value,
    })),
  }));

  const { giftCardIncentiveAmount } = await api.checkBeforeClaimSubmission({
    params: { storefrontId },
    body: {
      kind: 'estimateIncentive' as const,
      lineItems,
    },
  });

  const incentiveValue =
    isGiftCardEnabled && giftCardIncentiveAmount ? giftCardIncentiveAmount : 0;
  const formatCurrency = toCurrency(currencyCode);

  return json<RefundOptionsData>({
    isRefundEnabled,
    isGiftCardEnabled,
    hasIncentive: Boolean(giftCardIncentiveAmount),
    incentive: formatCurrency(incentiveValue),
    refund: formatCurrency(refundSubtotal + taxRefundValue),
    tax: formatCurrency(taxRefundValue),
    total: formatCurrency(refundSubtotal + taxRefundValue + incentiveValue),
  });
});

export const useRefundOptionsData = createLoaderDataHook<RefundOptionsData>();
