import { z } from 'zod';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import { toConjunctiveList } from '~/utils/formatters';
import {
  createActionResultHook,
  error,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { mediaAssetSchema, stringToJSONSchema } from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

const schema = zfd.formData({
  noteFromCustomer: zfd.text(z.string().optional()),
  assets: zfd.repeatableOfType(stringToJSONSchema.pipe(mediaAssetSchema)),
  files: zfd
    .repeatable(z.array(z.instanceof(File)).optional())
    .transform((files) => files?.filter((file) => file.size > 0) ?? []),
});

export default gspAction(({ context, formData }) => {
  if (!context.order) {
    return error(new Error('Context Error: Order not found'));
  }

  const { storefrontId } = context.order;
  const results = schema.safeParse(formData);

  if (!results.success) {
    return error(
      new Error('Malformed form data', {
        cause: {
          error: results.error,
          entries: Object.entries(formData),
        },
      }),
    );
  }

  const { noteFromCustomer, assets, files } = results.data;

  const uploads =
    files.length ?
      api.uploadFiles({
        params: { storefrontId },
        query: { destination: 'gspClaimImg' },
        body: files,
      })
    : Promise.resolve({ assets: [], errors: [] });

  return uploads.then(({ assets: newAssets, errors }) => {
    context.setMeta({
      noteFromCustomer,
      assets: [...assets, ...newAssets],
    });

    return errors.length ?
        json<ActionResult>({
          ok: false,
          message: `Some files failed to upload: ${toConjunctiveList(errors)}`,
        })
      : redirect('../../address');
  });
});

export const useMetaActionResult = createActionResultHook<ActionResult>();
