import { InputHTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { FieldLabel } from './field';

type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'children' | 'hidden'
>;

export function ButtonOption({
  children,
  className,
  ...inputProps
}: PropsWithChildren<InputProps>) {
  return (
    <FieldLabel
      className={twMerge(
        '-mb-[1px] flex cursor-pointer items-center gap-3 border border-gray-200 bg-white p-4 last:mb-0 last:rounded-b-md focus:outline-none [&:nth-child(2)]:rounded-t-md',
        'has-[:checked]:border-outline has-[:checked]:bg-surface',
        className,
      )}
    >
      <input
        {...inputProps}
        type="radio"
        className="text-primary focus:ring-primary"
      />
      {children}
    </FieldLabel>
  );
}

export function CardOption({
  children,
  className,
  ...inputProps
}: PropsWithChildren<InputProps>) {
  return (
    <FieldLabel
      className={twMerge(
        'cursor-pointer rounded-lg border border-outline p-6 shadow-sm focus:outline-primary',
        'has-[:checked]:border-primary has-[:checked]:ring-2 has-[:checked]:ring-primary',
        'has-[:focus]:ring-offset-2', // focus or keyboard up/down on the input also checks it so inherits the checked styles too
        className,
      )}
    >
      <input {...inputProps} type="radio" className="sr-only" />
      {children}
    </FieldLabel>
  );
}

export default function RadioGroup({
  className = '',
  label,
  showLabel = true,
  children,
  disabled,
}: PropsWithChildren<{
  className?: string;
  label: string;
  showLabel?: boolean;
  disabled?: boolean;
}>) {
  return (
    <fieldset disabled={disabled} className={className}>
      <legend
        className={twMerge(
          'text-sm font-medium text-gray-700',
          !showLabel && 'sr-only',
        )}
      >
        {label}
      </legend>
      {children}
    </fieldset>
  );
}
