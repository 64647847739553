import {
  giftCardClaimSchema,
  refundClaimSchema,
  variantExchangeClaimSchema,
} from './schemas';
import {
  GiftCardClaim,
  LineItemClaim,
  RefundClaim,
  VariantExchangeClaim,
} from './types';

type Predicate<P extends unknown[]> = (...args: P) => boolean;
export const not =
  <P extends unknown[]>(fn: Predicate<P>) =>
  (...args: Parameters<typeof fn>): boolean =>
    !fn(...args);
export const sum = (numbers: (number | undefined)[]) =>
  numbers.reduce<number>((acc, num) => acc + (num ?? 0), 0);
export const sumBy =
  <T>(fn: (item: T) => number | undefined) =>
  (items: T[]) =>
    sum(items.map(fn));

export const prop =
  <T, K extends keyof T>(key: K) =>
  (obj: T) =>
    obj[key];

export const computeCost = ({
  unitPrice,
  quantity,
}: {
  unitPrice: number;
  quantity: number;
}) => unitPrice * quantity;

const computeTax = ({
  unitTax,
  quantity,
}: {
  unitTax: number;
  quantity: number;
}) => unitTax * quantity;

const computeWeight = ({
  grams,
  quantity,
}: {
  grams: number;
  quantity: number;
}) => grams * quantity;

export const sumGrams = sumBy(computeWeight);
export const sumPrice = sumBy(computeCost);
export const sumTax = sumBy(computeTax);

export const isRefundClaim = (claim: LineItemClaim): claim is RefundClaim =>
  refundClaimSchema.safeParse(claim).success;
export const isGiftCardClaim = (claim: LineItemClaim): claim is GiftCardClaim =>
  giftCardClaimSchema.safeParse(claim).success;
export const isCreditClaim = (
  claim: LineItemClaim,
): claim is GiftCardClaim | RefundClaim =>
  isGiftCardClaim(claim) || isRefundClaim(claim);
export const isExchangeClaim = (
  claim: LineItemClaim,
): claim is VariantExchangeClaim =>
  variantExchangeClaimSchema.safeParse(claim).success;
