import { StatusCodes } from 'http-status-codes';
import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { ClaimType } from '~/utils/types';

export type QuantityData = {
  remainingReturnableQuantity: number;
  quantity: number;
  claimType: ClaimType;
};

export default crewLoader(
  withDraftClaim(({ claim }) => {
    const {
      quantity,
      claimType,
      lineItem: { remainingReturnableQuantity },
    } = claim;

    if (remainingReturnableQuantity === 1) {
      return redirect('../reason', StatusCodes.MOVED_TEMPORARILY);
    }

    return json<QuantityData>({
      remainingReturnableQuantity,
      quantity,
      claimType,
    });
  }),
);

export const useQuantityData = createLoaderDataHook<QuantityData>();
