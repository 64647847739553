import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { baseStyles, Variant, variantStyles } from './button';

type Props = LinkProps & {
  children?: ReactNode;
  className?: string;
  variant?: Variant;
};

export default function LinkButton({
  children,
  className,
  variant = 'outlined',
  ...linkProps
}: Props) {
  return (
    <Link
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...linkProps}
      className={twMerge([baseStyles, variantStyles[variant], className])}
    >
      {children}
    </Link>
  );
}
