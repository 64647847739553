import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { FieldMessage } from '~/components/field';
import Input from '~/components/input';
import { useIsLoading } from '~/utils/routing';
import { INPUT_KEY, useQuantityActionResult } from './quantity-action';
import { useQuantityData } from './quantity-loader';

export default function ClaimQuantity() {
  const { remainingReturnableQuantity, quantity, claimType } =
    useQuantityData();
  const actionResult = useQuantityActionResult();
  const isLoading = useIsLoading();

  return (
    <Card
      headline={`${
        claimType === 'Return' ? 'How many do you need to return?' : ''
      } ${
        claimType === 'Warranty' ?
          'How many of this product do you need help with?'
        : ''
      }(${remainingReturnableQuantity} available)`}
    >
      <Form method="post" className="flex flex-col" name="claimQuantity">
        <Input
          // * hiding the spin buttons (arrows) on number input
          className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none"
          aria-describedby="quantity"
          required
          placeholder="Enter quantity"
          id="quantity"
          name={INPUT_KEY}
          type="number"
          defaultValue={quantity}
          min="1"
          max={remainingReturnableQuantity}
        />
        {actionResult?.ok === false && (
          <FieldMessage error>{actionResult.form?.quantity}</FieldMessage>
        )}
        <Submit className="mt-6" loading={isLoading}>
          Next
        </Submit>
      </Form>
    </Card>
  );
}
