import api from '~/utils/api';
import { crewStateSchema, stringToJSONSchema } from '~/utils/schemas';
import {
  Address,
  Claim,
  CrewContext,
  CrewOrder,
  CrewState,
  DraftLineItemClaim,
  LineItemClaim,
  Settings,
  ShipmentMethod,
  ShipmentRate,
} from '~/utils/types';

let state: CrewState | null = null;
let settingsRequest: Promise<Settings> | null = null;

const STORAGE_KEY = 'corso-crew-state';

const notifyChange = (newState: CrewState) => {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newState));
};

const createInitialState = (settings: Settings): CrewState => ({
  settings,
  claim: null,
  draftClaim: null,
  lineItemClaims: [],
  order: null,
  address: null,
  returnMethod: null,
  toCustomerRate: null,
});

const getter = <T extends keyof CrewState>(key: T) => {
  if (!state) {
    throw new Error('State not initialized');
  }

  return state[key];
};

const assign = <K extends keyof CrewState, V extends CrewState[K]>(
  key: K,
  value: V,
) => {
  if (!state) {
    throw new Error('State not initialized');
  }

  state[key] = value;
  notifyChange(state);

  return value;
};

export const CrewStore: CrewContext = {
  get claim() {
    return getter('claim');
  },

  get draftClaim() {
    return getter('draftClaim');
  },

  get lineItemClaims() {
    return getter('lineItemClaims');
  },

  get order() {
    return getter('order');
  },

  get settings() {
    return getter('settings');
  },

  get address() {
    return getter('address');
  },

  get returnMethod() {
    return getter('returnMethod');
  },

  get toCustomerRate() {
    return getter('toCustomerRate');
  },

  setOrder: (order: CrewOrder | null) => assign('order', order),

  setAddress: (address: Address) => assign('address', address),

  setClaim: (claim: Claim) => assign('claim', claim),

  setDraftClaim: (draft: DraftLineItemClaim) => assign('draftClaim', draft),

  clearDraftClaim: () => assign('draftClaim', null),

  upsertLineItemClaim: (lineItemClaim: LineItemClaim) => {
    const lineItemClaims = getter('lineItemClaims');

    const index = lineItemClaims.findIndex(
      (claim) => claim.id === lineItemClaim.id,
    );

    if (index !== -1) {
      lineItemClaims.splice(index, 1, lineItemClaim);
    } else {
      lineItemClaims.push(lineItemClaim);
    }

    assign('lineItemClaims', [...lineItemClaims]);

    return lineItemClaim;
  },

  getLineItemClaim: (id: LineItemClaim['id']) => {
    const lineItemClaims = getter('lineItemClaims');

    return lineItemClaims.find((claim) => claim.id === id);
  },

  removeLineItemClaim: (id: LineItemClaim['id']) => {
    const lineItemClaims = getter('lineItemClaims');
    const index = lineItemClaims.findIndex((claim) => claim.id === id);

    if (index === -1) {
      return false;
    }
    lineItemClaims.splice(index, 1);

    assign('lineItemClaims', [...lineItemClaims]);

    return true;
  },

  setReturnMethod: (shipmentMethod: ShipmentMethod) =>
    assign('returnMethod', shipmentMethod),

  setToCustomerRate: (rate: ShipmentRate) => assign('toCustomerRate', rate),

  init: (settings: Settings) => {
    // state is already initialized just update settings -- really for testing
    if (state) {
      state.settings = settings;
      return;
    }

    try {
      // restore from session storage
      const parsed = stringToJSONSchema
        .pipe(crewStateSchema)
        .parse(sessionStorage.getItem(STORAGE_KEY) ?? '');

      state = {
        ...parsed,
        settings,
      };
    } catch {
      // no state in session storage -- create new
      state = createInitialState(settings);
    }

    notifyChange(state);
  },

  initAsync: async (store: string) => {
    // state is already initialized
    if (state) {
      return;
    }
    // prevent multiple requests such as in parallel loaders initializing the store
    const request =
      settingsRequest ?? api.fetchSettings({ params: { slug: store } });
    settingsRequest = request;

    const settings = await request;

    CrewStore.init(settings);
  },
  reset: () => {
    state = createInitialState(CrewStore.settings);
    notifyChange(state);
  },

  //! This is a dangerous method that should only be used in testing as it clears EVERYTHING
  dangerouslyReset: () => {
    state = null;
    sessionStorage.removeItem(STORAGE_KEY);
  },

  clearLineItemClaims: () => {
    state = {
      ...createInitialState(CrewStore.settings),
      order: CrewStore.order,
    };

    notifyChange(state);
  },
} as const;
