import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import { Address } from '~/utils/types';

export type AddressData = {
  address: Partial<Address>;
};

export default crewLoader(({ context, params: { store = '' } }) => {
  if (!context.order) {
    return redirect(`/${store}`);
  }

  const address = context.address ?? context.order.shippingAddress;

  if (address) {
    return json<AddressData>({
      address,
    });
  }

  return redirect('./edit');
});

export const useAddressData = createLoaderDataHook<AddressData>();
