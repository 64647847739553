import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import { ClaimType } from '~/utils/types';
import { checkIfPastReturnWindow } from '~/app/[:store]/order/[:idFromPlatform]/order-loader-utils';

type NewRequestLoaderData = {
  claimTypeSelectionDetailText: string;
};

export default crewLoader(({ context: { order, settings }, params }) => {
  if (!order) {
    return redirect(`/${params.store}`);
  }

  if (order.allowClaimTypeSelection) {
    const { claimTypeSelectionDetailText } =
      order.allowClaimTypeSelection.params;

    return json<NewRequestLoaderData>({
      claimTypeSelectionDetailText,
    });
  }

  const areReturnsEnabled = settings.return.isEnabled;

  const canWarranty =
    settings.warranty.isEnabled &&
    order.lineItems.every(checkIfPastReturnWindow(areReturnsEnabled));

  return redirect(`../${canWarranty ? ClaimType.warranty : ClaimType.return}`);
});

export const useNewRequestLoaderData =
  createLoaderDataHook<NewRequestLoaderData>();
