import { getAddressFields } from '~/utils/address';
import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';

export type AddressData = {
  hasAddress: boolean;
  fields: ReturnType<typeof getAddressFields>;
};

export default crewLoader(({ context, params: { store = '' } }) => {
  if (!context.order) {
    return redirect(`/${store}`);
  }

  const address = context.address ?? context.order.shippingAddress;

  return json<AddressData>({
    hasAddress: Boolean(address),
    fields: getAddressFields(address),
  });
});

export const useAddressData = createLoaderDataHook<AddressData>();
