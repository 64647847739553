import { ZodSchema, z } from 'zod';
import { zfd } from 'zod-form-data';
import { Address, OrderAddress } from './types';

type AddressField = {
  name: keyof Address;
  label: string;
  defaultValue: string;
  required: boolean;
  type: 'text' | 'tel';
  autoComplete: string;
  placeholder?: string;
  pattern?: string;
  invalidMessage?: string;
};

export const getAddressFields = (
  address?: OrderAddress | Address | null,
): Record<keyof Address, AddressField> => ({
  firstName: {
    name: 'firstName',
    label: 'First Name',
    defaultValue: address?.firstName ?? '',
    required: true,
    type: 'text',
    autoComplete: 'given-name',
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name',
    defaultValue: address?.lastName ?? '',
    required: true,
    type: 'text',
    autoComplete: 'family-name',
  },
  line1: {
    name: 'line1',
    label: 'Address',
    defaultValue: address?.line1 ?? '',
    required: true,
    type: 'text',
    autoComplete: 'address-line1',
  },
  line2: {
    name: 'line2',
    label: 'Apt, suite, etc.',
    defaultValue: address?.line2 ?? '',
    required: false,
    type: 'text',
    autoComplete: 'address-line2',
  },
  city: {
    name: 'city',
    label: 'City',
    defaultValue: address?.city ?? '',
    required: true,
    type: 'text',
    autoComplete: 'address-level2',
  },
  stateOrProvinceCode: {
    name: 'stateOrProvinceCode',
    label: 'State or Province Code (2 or 3 letter code)',
    defaultValue: address?.stateOrProvinceCode ?? '',
    required: false, // ! should this be required??
    type: 'text',
    autoComplete: 'address-level1',
    placeholder: 'ON, NY, NSW etc.',
    pattern: '[A-Za-z]{2,3}',
    invalidMessage:
      'Please enter a valid two or three character state or province code (e.g. ON, NY, NSW etc.)',
  },
  postalCode: {
    name: 'postalCode',
    label: 'Postal Code',
    defaultValue: address?.postalCode ?? '',
    required: true,
    type: 'text',
    autoComplete: 'postal-code',
  },
  countryCode: {
    name: 'countryCode',
    label: 'Country (2 letter code)',
    defaultValue: address?.countryCode ?? '',
    required: true,
    type: 'text',
    autoComplete: 'country',
    placeholder: 'CA, US, etc.',
    pattern: '[A-Za-z]{2}',
    invalidMessage:
      'Please enter a valid two character country code (e.g. CA, US, etc.)',
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    defaultValue: address?.phone ?? '',
    required: false,
    type: 'tel',
    autoComplete: 'tel',
  },
});

const schema = zfd.formData({
  firstName: zfd.text(),
  lastName: zfd.text(),
  line1: zfd.text(),
  line2: zfd.text(z.string().optional()),
  city: zfd.text(),
  stateOrProvinceCode: zfd.text(z.string().min(2).max(3).optional()),
  postalCode: zfd.text(),
  countryCode: zfd.text(z.string().length(2)),
  phone: zfd.text(z.string().optional()),
} satisfies Record<keyof Address, ZodSchema>);

export type ValidationError = {
  ok: false;
  errors: Partial<Record<keyof Address, string>>;
};
type ValidationSuccess = { ok: true; data: z.infer<typeof schema> };
type ValidationResult = ValidationSuccess | ValidationError;

export const validateAddressData = (data: FormData): ValidationResult => {
  const fields = getAddressFields();
  const result = schema.safeParse(data, {
    errorMap: (issue, ctx) => {
      const { label } =
        issue.path[0] ? fields[issue.path[0] as keyof Address] : { label: '' };
      if (
        issue.code === z.ZodIssueCode.invalid_type &&
        ctx.data === undefined
      ) {
        return {
          message: `${label} is required and missing`,
        };
      }
      return { message: ctx.defaultError };
    },
  });

  if (result.success) {
    return { ok: true, data: result.data };
  }

  const errors = Object.entries(result.error.flatten().fieldErrors).reduce(
    (acc, [key, error]) => ({
      ...acc,
      [key]: error.join('; '),
    }),
    {},
  );
  return { ok: false, errors };
};
