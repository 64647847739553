import { useEffect, useState } from 'react';
import { MediaAsset as MediaAssetType } from '~/utils/types';

export default function MediaAsset({
  src,
  alt = 'Inspection Media Image',
  type,
}: {
  src: string;
  alt?: string;
  type?: MediaAssetType['type'];
}) {
  const [mediaType, setMediaType] = useState<
    MediaAssetType['type'] | undefined
  >(type);

  useEffect(() => {
    if (type) return;

    fetch(src, { method: 'HEAD' })
      .then((resp) => {
        setMediaType(() =>
          resp.headers.get('content-type')?.startsWith('video') ?
            'video'
          : 'image',
        );
      })
      .catch(() => setMediaType('image'));
  }, [src, type, setMediaType]);

  const styles = 'w-full h-full aspect-square object-contain bg-black rounded';

  if (!mediaType) return null;

  return mediaType === 'video' ?
      //  eslint-disable-next-line jsx-a11y/media-has-caption
      <video data-testid="video-player" controls src={src} className={styles} />
    : <a href={src} className="inline-block" target="_blank" rel="noreferrer">
        <img className={styles} src={src} alt={alt} />
      </a>;
}
