import { crewCustomerApi } from 'corso-types/crew/customer/api';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  withDraftClaim,
} from '~/utils/routing';
import { ActionErrorResult, ActionOkResult, ActionResult } from '~/utils/types';

export const ACTION_KEY = 'event';
const schema = zfd.formData({
  [ACTION_KEY]: zfd.json(
    crewCustomerApi['/:storefrontId/automations/after-reason-selection'].post
      .request.body,
  ),
});

export default crewAction(
  withDraftClaim(({ formData, claim, context }) => {
    const result = schema.safeParse(formData);

    if (!result.success) {
      return error(
        new Error('Malformed form data', {
          cause: {
            error: result.error,
            entries: Array.from(Object.entries(formData)),
          },
        }),
      );
    }

    const item = context.order?.lineItems.find(
      (lineItem) => lineItem.id === claim.lineItem.id,
    );
    if (!item) {
      return error(new Error('Line item not found on order.'));
    }

    if (!claim.reason) {
      return error(new Error('Missing claim reason.'));
    }

    return api
      .confirmAfterReasonSelection({
        params: { storefrontId: context.settings.storefrontId },
        body: result.data.event,
        query: {
          lineItemId: claim.lineItem.id,
          reasonId: claim.reason.id,
          claimType: claim.claimType,
          reasonCategoryCode: claim.reason.category,
          ...(claim.reason.detail && {
            reasonDetailId: claim.reason.detail.id,
          }),
        },
      })
      .then(() => {
        // mark the line item as final sale so they can't submit claim for it

        // item not eligible for return
        // ? what about quantity  where they want to return some but not all
        // ? what happens if they navigate back and see the prompt again?
        item.return.isFinalSale = true;
        context.setOrder(context.order); // persist the change to session storage
        context.clearDraftClaim();
        context.removeLineItemClaim(claim.id);

        return json<ActionOkResult>({
          ok: true,
        });
      })
      .catch(() => json<ActionErrorResult>({ ok: false }));
  }),
);

export const useFollowUpActionResult = createActionResultHook<ActionResult>();
