import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { FieldMessage } from '~/components/field';
import RadioGroup, { ButtonOption } from '~/components/radio-group';
import { useIsLoading } from '~/utils/routing';
import { INPUT_NAME, useReasonActionResult } from './reason-action';
import { useReasonData } from './reason-loader';

export default function ClaimReason() {
  const isLoading = useIsLoading();
  const {
    reasons,
    claim: { reason: selectedReason, claimType },
  } = useReasonData();
  const actionResult = useReasonActionResult();

  return (
    <Card
      headline={`Why are you submitting this item for ${claimType.toLocaleLowerCase()}?`}
    >
      <Form method="post" className="flex flex-col" name="claimReason">
        <RadioGroup className="mt-2" label="Select a reason" showLabel={false}>
          {reasons.map((reason) => (
            <ButtonOption
              key={reason.id}
              value={reason.id}
              name={INPUT_NAME}
              defaultChecked={reason.id === selectedReason?.id}
              required
            >
              {reason.name}
            </ButtonOption>
          ))}
        </RadioGroup>
        {actionResult?.ok === false && (
          <FieldMessage error>{actionResult.form?.[INPUT_NAME]}</FieldMessage>
        )}
        <Submit className="mt-6" loading={isLoading}>
          Next
        </Submit>
      </Form>
    </Card>
  );
}
