import { registrationStateSchema, stringToJSONSchema } from '~/utils/schemas';
import {
  DraftRegistration,
  Registration,
  RegistrationContext,
  RegistrationState,
  Settings,
} from '~/utils/types';

let state: RegistrationState | null = null;

const STORAGE_KEY = 'corso-registration-state';

const notifyChange = (newState: RegistrationState) => {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newState));
};

const getter = <T extends keyof RegistrationState>(key: T) => {
  if (!state) {
    throw new Error('State not initialized');
  }

  return state[key];
};

const createInitialState = (settings: Settings): RegistrationState => ({
  settings,
  draftRegistration: null,
  registration: null,
});

const assign = <
  K extends keyof RegistrationState,
  V extends RegistrationState[K],
>(
  key: K,
  value: V,
) => {
  if (!state) {
    throw new Error('State not initialized');
  }

  state[key] = value;
  notifyChange(state);

  return value;
};

export const RegistrationStore: RegistrationContext = {
  get settings() {
    return getter('settings');
  },

  get registration() {
    return getter('registration');
  },

  get draftRegistration() {
    return getter('draftRegistration');
  },

  setRegistration: (registration: Registration) =>
    assign('registration', registration),

  setDraftRegistration: (draft: DraftRegistration) =>
    assign('draftRegistration', draft),

  clearDraftRegistration: () => {
    assign('draftRegistration', null);
  },

  init: (settings: Settings) => {
    // state is already initialized just update settings -- really for testing
    if (state) {
      state.settings = settings;
      return;
    }

    try {
      // restore from session storage
      const parsed = stringToJSONSchema
        .pipe(registrationStateSchema)
        .parse(sessionStorage.getItem(STORAGE_KEY) ?? '');

      state = {
        draftRegistration: parsed.draftRegistration,
        registration: null,
        settings,
      };
    } catch {
      // no state in session storage -- create new
      state = createInitialState(settings);
    }

    notifyChange(state);
  },
} as const;
